import styled from "@emotion/styled";
import { styled as MaterialStyled } from "@mui/material/styles";
import { Box } from "@mui/material";

interface DailyUserNameProps {
  isStreamingView?: boolean
}

interface DailyMixerMuteIconProps {
  isInsideAvatar?: boolean
}

interface DailyCallWrapperProps {
  isStreamingView: boolean
}

export const DailyUserName = styled.div<DailyUserNameProps>`
  background: rgba(0, 0, 0, 0.35);
  color: #fff;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-size: 14px;
  white-space: nowrap;
  margin-bottom: 8px;
  ${({ isStreamingView }) => isStreamingView && `
    position: absolute;
    z-index:2;
    margin:0;
  `}
`;

export const VideoWrapper = styled.div`
  flex: 1;
  video{
    border-radius: 6px;
  }
`;

export const UserAvatarWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100%;
`;
export const ParticipantVolumeSliderWrapper = styled.div`
  display: flex;
  position: absolute;
  left: 0.625rem; 
  bottom: 0;
  align-items: flex-end;
  margin-bottom: 8px;
  z-index: 2 ;
`;

export const MixerSliderMuteIconWrapper = styled.div<DailyMixerMuteIconProps>`
  position: absolute;
  top: 6px;
  right: 6px;
  border-radius: 50%;
  
  svg {
    width: 25px;
    height: 25px;
    filter: drop-shadow(2px 4px 6px black);
  }

  ${({ isInsideAvatar }) =>
    isInsideAvatar &&`
      display: flex;
      width: 100%;
      justify-content: center;
      z-index: 9;
      top: auto;
      bottom: -5px;
    `}
  
`;

export const VolumeBox = MaterialStyled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: "0.9375rem",
  justifyContent: "center",
  alignItems: "center",
  paddingTop: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  borderRadius: "6px",
  width: "2.125rem",
  height: "7.5rem",
  backgroundColor: "rgba(0, 0, 0, 0.35)",
}));

export const LiveStreamParticipantsWrapper = styled.div`
  background-color: rgb(244, 244, 244);
  display: flex;
  padding: 8px;
  flex: 1;
  // max-width: 54rem;
  flex-wrap: wrap;
  border-radius: 4px;
  justify-content: flex-start;
  align-content: center;
  
`;

export const FixedDailyWrapper = styled.div<DailyCallWrapperProps>`
  position: fixed;
  top: ${({ isStreamingView }) => (isStreamingView ? 0 : "72px")};
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #000;
`;

export const CenteredDailyWrapper = styled.div<DailyCallWrapperProps>`
  position: fixed;
  top: ${({ isStreamingView }) => (isStreamingView ? 0 : "72px")};
  left: 0;
  right: 0;
  z-index: 99;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: #000;
`;