import React, { useCallback } from "react";
import { IconButton, Tooltip, Popper, ButtonGroup, Grow, Paper, ClickAwayListener, MenuList, MenuItem } from "@mui/material";
import VideoOn from "../../../images/video.svg";
import VideoOff from "../../../images/video-slash.svg";
import ArrowDown from '../../../images/arrow-down.svg'
import useNativeDevices from "../../../hooks/useDevices/useDevices";
import i18n from "../../../l10n/strings.json";
import { useDaily, useVideoTrack, useLocalSessionId, useDevices } from '@daily-co/daily-react';

interface Camera {
	device: {
		deviceId: string;
		label: string;
	};
}

export default function ToggleVideoButton(props: {
	disabled?: boolean;
	className?: string;
}) {
	const { hasVideoInputDevices } = useNativeDevices();
	const { currentCam, cameras, setCamera } = useDevices();
	const callObject = useDaily();
	const localSessionId = useLocalSessionId();
	const localVideo = useVideoTrack(localSessionId);
	const mutedVideo = localVideo?.isOff ?? true;
	const anchorRef = React.useRef<HTMLDivElement>(null);
	const [open, setOpen] = React.useState(false);
	const [selectedCamera, setSelectedCamera] = React.useState(currentCam?.device.deviceId);

	const handleToggle = () => {
		setOpen(true);
	};
	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLLIElement, MouseEvent>,
		camId: string,
	) => {
		setSelectedCamera(camId);
		setCamera(camId);
		setOpen(false);
	};

	const handleClose = (event: Event) => {
		if (
			anchorRef.current &&
			anchorRef.current.contains(event.target as HTMLElement)
		) {
			return;
		}
		setOpen(false);
	};

	const toggleVideo = useCallback(() => {
		callObject?.setLocalVideo(mutedVideo); // Check if callObject is not null
	}, [callObject, mutedVideo]);

	return (
		<>
			<Tooltip
				title={
					mutedVideo
						? i18n.jamSession.startVideoTooltip
						:
						i18n.jamSession.stopVideoTooltip
				}
			>
				<ButtonGroup
					variant="contained"
					ref={anchorRef}
					sx={{ boxShadow: "none" }}
				>
					<IconButton
						className={props.className}
						onClick={toggleVideo}
						disabled={!hasVideoInputDevices || props.disabled}
					// startIcon={isVideoEnabled ? <VideoOnIcon /> : <VideoOffIcon />}
					>
						{mutedVideo ? (
							<img src={VideoOff} alt="video-off" />
						) : (
							<img src={VideoOn} alt="video-on" />
						)}
					</IconButton>
					{
						cameras.length &&
						<IconButton
							size="small"
							aria-controls={open ? 'split-button-menu' : undefined}
							aria-expanded={open ? 'true' : undefined}
							aria-label="select merge strategy"
							aria-haspopup="menu"
							onClick={handleToggle}
						>
							<img src={ArrowDown} alt="Down Arrow" style={{ marginLeft: "-10px" }} />
						</IconButton>
					}
				</ButtonGroup>

			</Tooltip>
			<Popper
				sx={{ zIndex: 90 }}
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={handleClose}>
								<MenuList id="split-button-menu" autoFocusItem>
									{cameras.map((camera: Camera, index: number) => (
										<MenuItem
											key={`cam-${camera.device.deviceId}`}
											disabled={index === 2}
											selected={camera.device.deviceId === selectedCamera}
											onClick={(event) => handleMenuItemClick(event, camera.device.deviceId)}
										>
											{camera.device.label}
										</MenuItem>
									))}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</>
	);
}
