import React from 'react';
import { Chip } from '@mui/material';
import { styled } from '@mui/system';

interface CustomChipProps {
  label: string;
  icon?: React.ReactElement;
  backgroundColor?: string;
  borderColor?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
  iconSpacing?: string;
  fontSize?: string;
  fontWeight?: number | string;
  labelColor?: string;
}

const CustomChip = styled(Chip)<CustomChipProps>(
  ({ theme, backgroundColor, borderColor, width, height, borderRadius, iconSpacing, fontSize, fontWeight, labelColor }) => ({
    backgroundColor: backgroundColor || theme.palette.background.default,
    border: borderColor === 'none' ? 'none' : `1px solid ${borderColor || theme.palette.divider}`,
    width: width || 'auto',
    height: height || 'auto',
    borderRadius: borderRadius || theme.shape.borderRadius,
    padding: '0 8px',
    display: 'flex',
    alignItems: 'center',

    '& .MuiChip-label': {
      fontSize: fontSize || 'inherit',
      fontWeight: fontWeight || 'normal',
      color: labelColor || 'inherit',
    },

    '& .MuiChip-icon': {
      marginRight: iconSpacing || '4px',
    },
  })
);

const CustomChipComponent: React.FC<CustomChipProps> = ({
  label,
  icon,
  backgroundColor,
  borderColor,
  width,
  height,
  borderRadius,
  iconSpacing,
  fontSize,
  fontWeight,
  labelColor,
}) => {
  return (
    <CustomChip
      icon={icon}
      label={label}
      backgroundColor={backgroundColor}
      borderColor={borderColor}
      width={width}
      height={height}
      borderRadius={borderRadius}
      iconSpacing={iconSpacing}
      fontSize={fontSize}
      fontWeight={fontWeight}
      labelColor={labelColor}
      variant="outlined"
    />
  );
};

export default CustomChipComponent;
