import styled from "styled-components";

export const PeopleWrapper = styled.li`
  display: flex;
  justify-content: space-between;
  color: #fff;
  padding: 8px 0;
  align-items: center;
`;

export const PeopleDetails = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0273E6;
  padding: 6px 8px;
  border-radius: 14px;

  svg{
    width: 20px;
    height: 20px;
  }
`;


export const PeopleTitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #d7d7d7;
  margin: 0;
  margin-left: 6px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
`;

export const PeopleActions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PeopleActionItem = styled.div`
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    svg{
      width: 30px;
    }
`;
