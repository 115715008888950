import React from "react";
import { VideoProvider } from "./components/VideoProvider";
import { ParticipantProvider } from "./components/ParticipantProvider";
import { ChatProvider } from "./components/ChatProvider";
import { PeopleProvider } from "./components/PeopleProvider";
import { useAppState } from "./state";
import useConnectionOptions from "./utils/useConnectionOptions/useConnectionOptions";
import { FullscreenModeProvider } from "./context/FullscreenModeContext";
import { BroadcastingStatusProvider } from "./context/BroadcastingStatusContext";
import { MusicianFilterProvider } from "./components/Musicians/MusicProvider/useMusicianFilter";
import { ManagementFilterProvider } from "./components/AdminDashboard/ManageUserDashBoard/ManagementProvider/useManageFilter";
import { UserManagementFilterProvider } from "./components/AdminDashboard/ManageUserDashBoard/UserManagementProvider/useUserManageFilter";
import { DeviceManagementFilterProvider } from "./components/AdminDashboard/ManageUserDashBoard/DeviceManagementProvider/useDeviceManageFilter";
import { UserModerationFilterProvider } from "./components/AdminDashboard/Moderation/ModerationManagementProvider/useUserModerationFilter";
import { MusicianCountProvider } from "./components/Musicians/MusicProvider/useMusicianCounts";
import { ContentModerationFilterProvider } from "./components/AdminDashboard/Moderation/ContentModerationManagementProvider/useContentModerationFilter";
import { UserActiveSubscriptionProvider } from "./components/Subscription/SubscriptionProvider/useUserSubscription";
import { EventsFilterProvider } from "./components/Events/useEventsFilter";
import { UserDetailsProvider } from "./components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";
import { AdminDeviceManagementProvider } from "./components/AdminDashboard/AdminDeviceManagement/useAdminDeviceManagement";
import { DailyAppRoomProvider } from "./components/Daily/useDailyGlobalRoom";

interface Props {
  children: React.ReactNode;
}


const provider = (provider: any, props = {}) => [provider, props];
const ProviderComposer = ({ providers, children }: any) => {
  for (let i = providers.length - 1; i >= 0; --i) {
    const [Provider, props] = providers[i];
    children = <Provider {...props}>{children}</Provider>
  }
  return children;
}


const ContainerProvider: React.FC<Props>  = ({ children }) => {
  const { error, setError } = useAppState();
	const connectionOptions = useConnectionOptions();

  return (
    <ProviderComposer
      providers={[
        provider(ContentModerationFilterProvider),
        provider(UserModerationFilterProvider),
        provider(DeviceManagementFilterProvider),
        provider(EventsFilterProvider),
        provider(UserActiveSubscriptionProvider),
        provider(UserManagementFilterProvider),
        provider(UserDetailsProvider),
        provider(MusicianCountProvider),
        provider(MusicianFilterProvider),
        provider(ManagementFilterProvider),
        provider(FullscreenModeProvider),
        provider(BroadcastingStatusProvider),
        provider(AdminDeviceManagementProvider),
        provider(DailyAppRoomProvider)
      ]}
    >
      {children}
    </ProviderComposer>
  )
}

export default ContainerProvider;