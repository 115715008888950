import { memo } from "react";
import { Typography } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import MixerSlider from "../MixerSlider/MixerSlider";
import { useFullscreenModeContext } from "../../context/FullscreenModeContext";
import { useParticipantProperty } from "@daily-co/daily-react";
import { useDailyUtils } from "../Daily/useDailyUtils";
import Pagination from '../Daily/Pagination/Pagination';

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		innerContainer: {
			background: "#093969",
			border: "1px solid #014D78",
			borderRadius: "12px",
			minWidth: "180px",
		},
		pagination: {
			"& .MuiPaginationItem-root": {
				color: "white",
				background: "grey",
				borderRadius: "20px",
			},
			"& :hover": {
				"& .Mui-selected": {
					color: "black",
					background: "white",
				},
			},
			"& .MuiPaginationItem-previousNext": {
				background: "black",
			},
		},
	})
);

const Mixer = (props: any) => {
	const classes = useStyles();
	const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
	const { currentPageData, getUserByEmail, totalData } = useDailyUtils();
	const participantsCount = totalData?.length || 0;
	// const { speakerViewParticipants } = useParticipantsContext();
	// const { paginatedParticipants, setCurrentPage, currentPage, totalPages } =
	// 	usePagination([room!.localParticipant, ...speakerViewParticipants]);

	// const [paginatedParticipants, setPaginatedParticipants] = useState<any[]>([]);

	// const { currentPageData } = useDailyUtils();
	// const callObject = useDaily();

		// useEffect(() => {
		// 	const storedMap = JSON.parse(localStorage.getItem("userCheckedMap") ?? "{}");
		// 	const participantIds = new Set(paginatedParticipants.map(p => p.email));
		// 	const filteredMap = Object.fromEntries(
		// 		Object.entries(storedMap).filter(([userId]) => participantIds.has(userId))
		// 	);
		// 	localStorage.setItem("userCheckedMap", JSON.stringify(filteredMap));
		// }, [paginatedParticipants]);

		// useEffect(() => {
		// 	const allParticipants = callObject?.participants();
		// 	if (!allParticipants) return;
			
		// 	const participantVolumeDetails = JSON.parse(
		// 	  localStorage.getItem("participantVolumeDetails") ?? "[]"
		// 	);
			
		// 	const paginatedParticipants = currentPageData
		// 	  .map((sessionId: any) => allParticipants[sessionId]) 
		// 	  .filter((participant: any) => !!participant) 
		// 	  .map((participant: any) => {
		// 		const volumeDetail = participantVolumeDetails.find(
		// 		  (detail: any) => detail.email === participant.user_name
		// 		);

		// 		return volumeDetail
		// 		  ? { ...volumeDetail, sessionId: participant.session_id } 
		// 		  : { email: participant.user_name, audio: "unmuted", sessionId: participant.session_id };
		// 	  });
			  
		// 	setPaginatedParticipants(paginatedParticipants);
		//   }, [currentPageData, callObject]);
		
		// useEffect(() => {
		//     // Create dummy participants
		//     const dummyParticipants = Array.from({ length: 10 }, (_, index) => ({
		//         email: `user${index + 1}@example.com`,
		//         user_name: `DummyUser${index + 1}`,
		//         audio: "unmuted",
		//         sessionId: `dummy-session-${index + 1}`
		//     }));
		
		//     // Optionally simulate paginated data
		//     const paginatedDummyParticipants = dummyParticipants.slice(0, 5); // Mocking pagination with 5 participants per page
		
		//     setPaginatedParticipants(paginatedDummyParticipants);
		// }, []);
        
		  
	function RenderMixerSlider(props: any) {
		const { participantId } = props;
		const participant = getUserByEmail(useParticipantProperty(participantId, 'user_name'), false);
		return (
			<div className={classes.innerContainer} key={participant?.email}>
				<MixerSlider participant={participant} participantId={participantId}/>
			</div>
		)
	}

	return (
		<div
			style={{
				display: props.mixer ? "flex" : "none",
				flexDirection: "column",
				width: "100%",
				height:
					isFullscreenMode || isTabHidden
						? `calc(100% - 70px)`
						: `calc(100% - 140px)`,
				top: isFullscreenMode || isTabHidden ? "70px" : "140px",
				zIndex: "10",
				left: 0,
				position: "fixed",
				backgroundColor: "#000000",
			}}
		>
			<div className="mixer-container">
				{currentPageData.map((participantId: string) => (
						<RenderMixerSlider key={participantId} participantId={participantId} />
					// <Grid container columns={{ xs: 4, sm: 8, md: 12 }}  className={classes.innerContainer} key={participant.sid}>
					// <MixerSlider participant={participant} />
					// </Grid> 
				))}
			</div>
			<div className="mixer-footer">
				<Pagination participantsCount={participantsCount}/>
				{/* <Typography
					style={{
						color: "white",
						position: "absolute",
						left: "2%",
						bottom: "1%",
					}}
				>
					{currentPageData.length} Online Users {JSON.stringify(totalData)}
				</Typography> */}

				{/* {totalPages > 1 && (
					<Pagination
						page={currentPage}
						count={totalPages}
						className={classes.pagination}
						variant="outlined"
						onChange={(_, value) => setCurrentPage(value)}
						renderItem={(item) => (
							<PaginationItem
								slots={{ previous: ArrowBack, next: ArrowForward }}
								{...item}
							/>
						)}
					/>
				)} */}
			</div>
		</div>
	);
};

export default memo(Mixer);
