import { Typography, Button, Grid } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import WatchLiveStream from "../../images/watch-livestream.svg";
import Calendar from "../../images/calendar1.svg";
import { isAfter } from "date-fns";
import { useEffect, useState } from "react";

type LiveStreamProps = {
	sessionId?: string;
	session: any;
};

export default function LiveStreamInfo({
	sessionId,
	session,
}: LiveStreamProps) {
	let [searchParams] = useSearchParams();
	const [scheduledLiveStream, setScheduledLiveStream] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isAfter(session?.startTime, new Date())) {
			setScheduledLiveStream(true);
		} else {
			setScheduledLiveStream(false);
		}
	}, []);

	const handleStreamToken = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/generate-stream-token`,
			{
				method: "POST",
				headers: {
					"Content-type": "application/json",
				},
				body: JSON.stringify(session)
			}
		)
			.then((response: any) => {
				if (response.status >= 400) {
					console.log("error");
				} else {
					return response.json();
				}
			})
			.then((result: any) => {
				if (result.token) {
					navigate(`/broadcast?id=` +
						session?.dailyRoomName, {
						state: {
							session:{
								...session,
								token : result.token
							}
						},
					});
				}
			});
	};

	return (
		<>
			{scheduledLiveStream ? (
				<Grid container xs={12} md={12} sx={{ mt: 3 }} justifyContent="flex-end">
					<Grid item xs={5} container display="flex" justifyContent="space-between" alignItems="center">
						<Typography variant="body3" component="p" sx={{ fontSize: "16px", color: "#FF702A", fontWeight: "500" }}>
							2 days to go live
						</Typography>
						<Button
							sx={{
								width: "253px",
								height: "48px",
								//margin: "0.5em 0 !important",
								background:
									"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
								borderRadius: "24px !important",
							}}
							variant="contained"
							className="submit-button"
							startIcon={
								<img src={Calendar} alt="add to my calendar" />
							}
						>
							<Typography>Add to my calendar</Typography>
						</Button>
					</Grid>

				</Grid>
			) : (
				<Grid container xs={12} alignItems="center" display="flex" sx={{ p: 1 }}>
					<Grid item xs={2}>
						<Typography variant="body3" sx={{ color: "#5B5B65", fontSize: "16px", fontWeight: "500" }}>
							{session?.dailyLiveStreamingLikeCount} Likes
						</Typography>
					</Grid>
					<Grid item xs={2.5} sx={{ color: "#5B5B65", fontSize: "16px", fontWeight: "500" }}>
						{/* 0 watching now */}
					</Grid>
					<Grid item xs={7.5} display="flex" justifyContent="flex-end">
						<Button
							sx={{
								width: "253px",
								height: "48px",
								//margin: "0.5em 0 !important",
								background:
									"linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
								borderRadius: "24px !important",
							}}
							onClick={handleStreamToken}
							variant="contained"
							// onClick={() => navigate(`/mymusic`)}
							className="submit-button"
							startIcon={
								<img src={WatchLiveStream} alt="Watch Live Stream" />
							}
						>
							<Typography>Watch Live Stream</Typography>
						</Button>

					</Grid>
				</Grid>
			)}
		</>
	);
}
