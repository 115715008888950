import { Button } from "@mui/material";
import styled from "styled-components";


export const TrayWrapper = styled.div`
  background: #424242;
  border-radius: 6px;
  flex-direction: column;
  display: flex;
  justify-content: center;
  padding: 6px;
  margin: 14px;
  max-height: 48px;
  box-sizing: border-box;
  max-width: 98vw;
`;

export const TrayContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const MeetingInfo = styled.div`
  display: flex;
  padding: 8px;
  color: #f0f0f0;
  font-size: 16px;
`;

export const MeetingControls = styled.div`
  display: flex;
`;
export const EndCallButton = styled(Button)`
  background: #E11E1E !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 6px;
  
  &:hover {
    background: #E11E1E !important;
  }
`;

export const DailyActionsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > div{
    min-height: 44px;
    box-sizing: border-box;
    display: flex;
  }
  & > button{
    min-height: 44px;
    box-sizing: border-box;
    display: flex;
  }
`;
