import WebsocketClientUSerStatus from "./WebsocketClientUSerStatus";

type Callback = (payload: any) => void;

/**
 * Adds a WebSocket event listener and handles the message received.
 * @param eventName The name of the WebSocket event to listen for.
 * @param callback The callback function to handle the received message.
 */
export const addWebSocketListener = (
  eventName: string, 
  callback: Callback
) => {
  WebsocketClientUSerStatus.on(eventName, callback);

  // Optionally, return a function to remove the listener
  return () => WebsocketClientUSerStatus.off(eventName, callback);
};