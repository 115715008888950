const drawerWidth = 320;


// State constants
const STATE_IDLE = 'STATE_IDLE';
const STATE_JOINING = 'STATE_JOINING';
const STATE_JOINED = 'STATE_JOINED';
const STATE_LEAVING = 'STATE_LEAVING';
const STATE_ERROR = 'STATE_ERROR';
const STATE_LEFT = 'STATE_LEFT';

const EMOJI_MAP: { [key: string]: string } = {
  fire: '🔥',
  squid: '🦑',
  laugh: '🤣',
  clap:"👏",
  heart: "❤️",
  smiling: "😍",
  wow: "😮",
  thumsup: "👍"
};

export {
  STATE_IDLE,
  STATE_JOINING,
  STATE_JOINED,
  STATE_LEAVING,
  STATE_ERROR,
  STATE_LEFT,
  drawerWidth,
  EMOJI_MAP
}