import React, { useEffect, useRef, useCallback } from 'react';
import { useDaily } from '@daily-co/daily-react';
import { EMOJI_MAP } from '../Constants';
import { EmojisWrapper } from './EmojiStyled';

export const EmojisOverlay = () => {
  const callObject = useDaily();
  const overlayRef = useRef<HTMLDivElement | null>(null);

  const createNode = (emoji: string) => {
    const node = document.createElement('div');
    node.appendChild(document.createTextNode(EMOJI_MAP[emoji]));
    node.className =
      Math.random() * 1 > 0.5 ? 'emoji wiggle-1' : 'emoji wiggle-2';
    node.style.transform = `rotate(${-30 + Math.random() * 60}deg)`;
    node.style.position = 'absolute'; // Ensure positioning
    node.style.left = `${Math.random() * 75}%`;
    return node;
  };

  const removeEmoji = useCallback((node: HTMLElement) => {
    if (!overlayRef.current || !node) return;
    overlayRef.current.removeChild(node);
  }, []);

  const displayEmoji = useCallback(
    (emoji: string) => {
      if(!EMOJI_MAP[emoji]){
        return false;
      }
      if (!overlayRef.current) {
        return;
      }
      const node = createNode(emoji);
      overlayRef.current.appendChild(node);

      // Add event listener for animationend to remove the emoji
      const handleAnimationEnd = (e: AnimationEvent) => {
        const target = e.target as HTMLElement;
        removeEmoji(target);
      };

      node.addEventListener('animationend', handleAnimationEnd);

      // Cleanup on component unmount or when the node is removed
      return () => {
        node.removeEventListener('animationend', handleAnimationEnd); 
      };
    },
    [removeEmoji]
  );


  const receiveEmoji = useCallback(
    (e: { data: { message: string } }) => {
      if (!overlayRef.current) {
        return;
      }
      displayEmoji(e.data.message);
    },
    [displayEmoji]
  );


  const sendEmoji = useCallback((e: CustomEvent<{ emoji: string }>) => {
    const { emoji } = e.detail;
    if (emoji) {
      callObject?.sendAppMessage({ message: `${emoji}` }, '*');
      displayEmoji(emoji);
    }
  },[displayEmoji, callObject]);

  
  // -- Effects

  // Listen for new app messages and show new emojis
  useEffect(() => {
    if (!callObject) {
      return;
    }
    callObject.on('app-message', receiveEmoji);
    // Cleanup function
    return () => {
      callObject.off('app-message', receiveEmoji);
    };
  }, [callObject, receiveEmoji]);

  // Listen to window events to show local user emojis and send the emoji to all participants on the call
  useEffect(() => {
    if (!callObject) {
      return;
    }
    window.addEventListener('reaction_added', sendEmoji as EventListener);
    return () =>
      window.removeEventListener('reaction_added', sendEmoji as EventListener);
  }, [callObject, displayEmoji, sendEmoji]);

  // Remove all event listeners on unmount
  useEffect(() => {
    return () => {
      overlayRef.current?.childNodes.forEach((node) => {
        node.removeEventListener('animationend', (event) => removeEmoji(event.target as HTMLElement));
      });
    };
  }, [removeEmoji]);

  return (
    <EmojisWrapper ref={overlayRef} />
  );
};

export default EmojisOverlay;
