import { DailyUserName } from '../DailyStyled';
import { useParticipantProperty } from '@daily-co/daily-react';
import { DailyUserNameType } from '../Types/dailyTypes';
import { useDailyUtils } from '../useDailyUtils';

export default function Username({ id, isLocal, isStreamingView }: DailyUserNameType) {

  const {getUserByEmail} = useDailyUtils();
  const username = getUserByEmail(useParticipantProperty(id, 'user_name'), true);
  const maskedId = username?.split('@')[0].replace('.', ' ') || id;

  return (
    <DailyUserName isStreamingView={isStreamingView}> {username || isStreamingView ? maskedId :  id} {isLocal && '(you)'} </DailyUserName>
  );
}
