import styled from '@emotion/styled';

interface DailyDrawerWrapperProps {
  isFullscreenMode?: boolean;
}

export const DailyDrawerWrapper = styled.aside<DailyDrawerWrapperProps>`
  position: absolute;
  top: ${({isFullscreenMode}) => isFullscreenMode ? '6.25rem' : '10.25rem' };
  bottom: 1rem;
  right: 1rem;
  overflow-y: auto;
  width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-left: 1px solid grey;
  z-index: 9;
  background: #000;
  border: 2px solid #4D486B;
  border-radius: 8px;
  @media only screen and (max-width: 600px) {
    width: 100%;
    z-index: 3;
  }
`;

export const DailyDrawerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  background: #093969;
  align-items: center;
  padding: 12px;
`;
export const DailyDrawerTitle = styled.div`
  font-size: 16px;
  color: #fff;
`;

export const AddMessage = styled.div`
  padding: 1rem;
`;

export const DrawerContent = styled.ul`
  padding: 1rem;
  margin: 0;
  height: 100%;
  overflow-y: auto;
`;

export const ChatMessage = styled.li`
  list-style-type: none;
  margin-bottom: 1rem;
  color: #fff;
`;

export const ChatMessageAuthor = styled.span`
  font-weight: bold;
  font-size: 14px;
`;

export const ChatMessageBody = styled.p`
  text-align: left;
  margin: 0;
  display: inline;
  font-size: 12px;
`;

export const ChatForm = styled.form`
  display: flex;
  justify-content: space-between;
  padding-bottom: 2px;
  background: #5C5C5C;
  border-radius: 15px;
  overflow: hidden;
`;

export const ChatInput = styled.input`
  background: transparent;
  border: 0;
  flex-grow: 1;
  padding: 0.5rem;
  background: #5C5C5C;
  color: #fff;
  outline: none;
`;

export const ChatSubmitButton = styled.button`
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const DailyDrawerCloseButton = styled.button`
  align-self: end;
  font-weight: normal;
  cursor: pointer;
  background: transparent;
  border: none;
  path{
    fill: #fff;
  }
`;

export const MessageBubble = styled.div`
  max-width: 60%;
  padding: 8px 12px;
  border-radius: 10px;
  margin-bottom: 10px;
  color: white;
  position: relative;
  word-wrap: break-word;
`;

export const SenderBubble = styled(MessageBubble)`
  background-color: #4A4A4A;
  align-self: flex-start;
  color: white;

  &::after {
    content: '';
    position: absolute;
    left: -12px;
    top: 0px;
    width: 20px;
    height: 20px;
    background-color: #4A4A4A;
    border-bottom-right-radius: 50% 50%;
    -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
    clip-path: polygon(0% 0%, 100% 0%, 100% 100%);
  }
`;

export const ReceiverBubble = styled(MessageBubble)`
  background-color: #A5A5A5;
  align-self: flex-end;
  color: #fff;
  // In case we need same ui for own messages
  // &::after {
  //   content: '';
  //   position: absolute;
  //   right: -12px;
  //   top: 0;
  //   width: 20px;
  //   height: 20px;
  //   background-color: #A5A5A5;
  //   border-bottom-left-radius: 50% 50%;
  //   clip-path: polygon(0% 0%, 100% 0%, 0% 100%);
  // }
`;

export const SenderName = styled.span`
  font-weight: bold;
  display: block;
  font-size: 12px;
  line-height: 14px;
`;

export const Avatar = styled.img`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 10px;
`;

export const SenderMessageWrapper = styled.div`
  display: flex;
`;

export const ReceiverMessageWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const NewMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  max-width: 240px;
`
export const NewMessageText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  margin-top: 4px;
`;