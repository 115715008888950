import { useCallback, useMemo } from "react";

import AccordionG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Accordian.svg";
import GuitarG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Acoustic Guitar.svg";
import BanjoG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Banjo.svg";
import BassGuitarG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Bass Guitar.svg";
import DrumsG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Drums.svg";
import FluteG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Flute.svg";
import KeyboardG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Keyboard.svg";
import BagpipesG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Bagpipes.svg";
import PianoG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Piano.svg";
import TrumpetG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Trumpet.svg";
import ViolinG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Violin.svg";
import Alto_SaxophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Alto Sax.svg";
import BaritoneEG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Baritone_Euphonium.svg";
import BaritoneSG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Baritone Sax.svg";
import BClarinetG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Bass Clarinet.svg";
import BassoonG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Bassoon.svg";
import BellsG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Bells.svg";
import CelloG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Cello.svg";
import ClarinetG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Clarinet.svg";
import ContrabassoonG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Contrabassoon.svg";
import CornetG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Cornet.svg";
import DulcimerG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Dulcimer.svg";
import EGuitarG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Electric Guitar.svg";
import English_HornG from "../../icons/ColoredInJamSession/SVG/BlackOutline_English Horn.svg";
import FlugelhornG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Flugelhorn.svg";
import French_HornG from "../../icons/ColoredInJamSession/SVG/BlackOutline_French Horn.svg";
import HarmonicaG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Harmonica.svg";
import HarpG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Harp.svg";
import MandolinG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Mandolin.svg";
import OboeG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Oboe.svg";
import OrganG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Organ.svg";
import PercussionG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Percussion.svg";
import PiccoloG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Piccolo.svg";
import Piccolo_TrumpetG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Piccolo Trumpet.svg";
import RecorderG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Recorder.svg";
import SitarG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Sitar.svg";
import Soprano_SaxophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Soprano Sax.svg";
import SousaphoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Sousaphone.svg";
import SynthesizerG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Synthesizer.svg";
import Tenor_SaxophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Tenor Sax.svg";
import TromboneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Trombone.svg";
import TubaG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Tuba.svg";
import UkuleleG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Ukulele.svg";
import Upright_BassG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Upright Bass.svg";
import ViolaG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Viola.svg";
import VoiceG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Voice.svg";
import XylophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Xylophone_Marimba.svg";
import SaxophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Soprano Sax.svg";
import MicrophoneG from "../../icons/ColoredInJamSession/SVG/BlackOutline_Alto Sax.svg";
import InstrumentImg from "../../images/Instrument placeholderMixer.svg";

type Instrument = {
	name: string;
	icon: any;
};

const useInstrumentsAndIcons = () => {
	const ListForInstruments = useMemo<Instrument[]>(
		() => [
			{ name: "Accordion", icon: AccordionG },
			{ name: "Acoustic Guitar", icon: GuitarG },
			{ name: "Alto Saxophone", icon: Alto_SaxophoneG },
			{ name: "Bagpipes", icon: BagpipesG },
			{ name: "Banjo", icon: BanjoG },
			{ name: "Baritone Saxophone", icon: BaritoneSG },
			{ name: "Baritone/Euphonium", icon: BaritoneEG },
			{ name: "Bass Clarinet", icon: BClarinetG },
			{ name: "Bass Guitar", icon: BassGuitarG },
			{ name: "Bassoon", icon: BassoonG },
			{ name: "Bells", icon: BellsG },
			{ name: "Cello", icon: CelloG },
			{ name: "Clarinet", icon: ClarinetG },
			{ name: "Contrabassoon", icon: ContrabassoonG },
			{ name: "Cornet", icon: CornetG },
			{ name: "Drums", icon: DrumsG },
			{ name: "Dulcimer", icon: DulcimerG },
			{ name: "Electric Guitar", icon: EGuitarG },
			{ name: "English Horn", icon: English_HornG },
			{ name: "Flugelhorn", icon: FlugelhornG },
			{ name: "Flute", icon: FluteG },
			{ name: "French Horn", icon: French_HornG },
			{ name: "Guitar", icon: GuitarG },
			{ name: "Harmonica", icon: HarmonicaG },
			{ name: "Harp", icon: HarpG },
			{ name: "Keyboard", icon: KeyboardG },
			{ name: "Mandolin", icon: MandolinG },
			{ name: "Microphone", icon: MicrophoneG },
			{ name: "Oboe", icon: OboeG },
			{ name: "Organ", icon: OrganG },
			{ name: "Percussion", icon: PercussionG },
			{ name: "Piano", icon: PianoG },
			{ name: "Piccolo", icon: PiccoloG },
			{ name: "Piccolo Trumpet", icon: Piccolo_TrumpetG },
			{ name: "Recorder", icon: RecorderG },
			{ name: "Saxophone", icon: SaxophoneG },
			{ name: "Sitar", icon: SitarG },
			{ name: "Soprano Saxophone", icon: Soprano_SaxophoneG },
			{ name: "Sousaphone", icon: SousaphoneG },
			{ name: "Synthesizer", icon: SynthesizerG },
			{ name: "Tenor Saxophone", icon: Tenor_SaxophoneG },
			{ name: "Trombone", icon: TromboneG },
			{ name: "Trumpet", icon: TrumpetG },
			{ name: "Tuba", icon: TubaG },
			{ name: "Ukulele", icon: UkuleleG },
			{ name: "Upright Bass", icon: Upright_BassG },
			{ name: "Viola", icon: ViolaG },
			{ name: "Violin", icon: ViolinG },
			{ name: "Voice", icon: VoiceG },
			{ name: "Xylophone/Marimba", icon: XylophoneG },
		
		],
		[]
	);

	const findInstrumentByName = useCallback(
		(name: string): Instrument => {
			const instrument = ListForInstruments.find((inst) => inst.name === name);
			return instrument || { name, icon: InstrumentImg };
		},
		[ListForInstruments]
	);

	return { findInstrumentByName, ListForInstruments };
};

export default useInstrumentsAndIcons;
