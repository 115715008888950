import { useEffect } from "react";
import { Theme } from "@mui/material/styles";
import { createStyles, makeStyles } from "@mui/styles";
import { IconButton } from "@mui/material";
import Screenshare from "../../../images/screenshare.svg";
import ScreenshareActive from "../../../images/screenshareactive.svg";
import {Tooltip} from "@mui/material";
import { useScreenShare } from '@daily-co/daily-react';

export const SCREEN_SHARE_TEXT = "Share Screen";
export const STOP_SCREEN_SHARE_TEXT = "Stop Sharing Screen";
export const SHARE_IN_PROGRESS_TEXT =
	"Cannot share screen when another user is sharing";
export const SHARE_NOT_SUPPORTED_TEXT =
	"Screen sharing is not supported with this browser";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		button: {
			"&[disabled]": {
				color: "#bbb",
				"& svg *": {
					fill: "#bbb",
				},
			},
		},
	})
);

export default function ToggleScreenShareButton(props: { disabled?: boolean, setIsScreenSharing?: any }) {
	const classes = useStyles();
	
	const { isSharingScreen, screens, startScreenShare, stopScreenShare } = useScreenShare();
	const isRemoteUserSharingScreen = screens.some(item => !item.local);
	const disableScreenShareButton = isRemoteUserSharingScreen && !isSharingScreen;
	const isScreenShareSupported =
		navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
	const isDisabled =
		props.disabled  || !isScreenShareSupported || disableScreenShareButton;

	let tooltipMessage = SCREEN_SHARE_TEXT;

	if (disableScreenShareButton) {
		tooltipMessage = SHARE_IN_PROGRESS_TEXT;
	}

	if (isSharingScreen) {
		tooltipMessage = STOP_SCREEN_SHARE_TEXT;
	}

	if (!isScreenShareSupported) {
		tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
	}

	const toggleScreenShare = () => {
		if(disableScreenShareButton || isDisabled){
			return false;
		}
		if (isSharingScreen) {
		  stopScreenShare();
			props.setIsScreenSharing && props.setIsScreenSharing(false);
		} else {
		  startScreenShare();
			props.setIsScreenSharing && props.setIsScreenSharing(true);
		}
	};

	return (
		<Tooltip
			title={tooltipMessage}
			placement="top"
			PopperProps={{ disablePortal: true }}
			style={{ cursor: isDisabled ? "not-allowed" : "pointer" }}
		>
			<span>
				{/* The span element is needed because a disabled button will not emit hover events and we want to display
          a tooltip when screen sharing is disabled */}
				<IconButton
					className={classes.button}
					onClick={toggleScreenShare}
					disabled={isDisabled}
				>
					{(isSharingScreen) ?
						<img src={ScreenshareActive} alt="Screenshare" /> :
						<img src={Screenshare} alt="Stop Screenshare" />
					}
				</IconButton>
			</span>
		</Tooltip>
	);
}
