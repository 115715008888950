import {
	Dialog,
	DialogTitle,
	Box,
	DialogActions,
	Typography,
	Button,
	DialogContent,
	FormControl,
	MenuItem,
	Select,
	InputBase,
	Grid,
	Autocomplete,
} from "@mui/material";
import i18n from "../../l10n/strings.json";
import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import InstrumentImg from "../../images/Instrument placeholderMixer.svg";
import useInstrumentsAndIcons from "../../hooks/useGenresAndInstruments/useInstrumentsAndIcons";
import { useAlertStatus } from "../../context/AlertStatusContext";

const BootstrapInput = styled(InputBase)(({ theme }) => ({
	"label + &": {
		marginTop: theme.spacing(3),
	},
	"& .MuiInputBase-input": {
		borderRadius: 4,
		position: "relative",
		backgroundColor: theme.palette.background.paper,
		border: "1px solid #ced4da",
		fontSize: 16,
		padding: "10px 26px 10px 12px",
		transition: theme.transitions.create(["border-color", "box-shadow"]),
		fontFamily: ["Lexend"].join(","),
		"&:focus": {
			borderRadius: 4,
			borderColor: "#80bdff",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
	},
}));

const MixerSliderEdit = (props: any) => {
	const { findInstrumentByName, ListForInstruments } = useInstrumentsAndIcons();
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
	const { updateAlertStatus } = useAlertStatus();
	const [isChannel1NameExceed, setIsChannel1NameExceed] = useState(false);
	const [isChannel2NameExceed, setIsChannel2NameExceed] = useState(false);
	// Handle channel 1
	const [channel1, setChannel1] = useState({
		name: props?.setVolumeText?.channel1VolumeText,
		icon: findInstrumentByName(props?.setVolumeText?.channel1VolumeText)?.icon || InstrumentImg,
	});

	const [channel2, setChannel2] = useState({
		name: props?.setVolumeText?.channel2VolumeText,
		icon: findInstrumentByName(props?.setVolumeText?.channel2VolumeText)?.icon || InstrumentImg,
	});

	useEffect(() => {
		const selectedInstrument1 = findInstrumentByName(
			props?.setVolumeText?.channel1VolumeText
		);
		if (selectedInstrument1) {
			setChannel1({
				name: selectedInstrument1.name,
				icon: selectedInstrument1.icon,
			});
		}
		const selectedInstrument2 = findInstrumentByName(
			props?.setVolumeText?.channel2VolumeText
		);
		if (selectedInstrument2) {
			setChannel2({
				name: selectedInstrument2.name,
				icon: selectedInstrument2.icon,
			});
		}
	}, [props?.setVolumeText?.channel1VolumeText, props?.setVolumeText?.channel2VolumeText]);


	useEffect(() => {
		const isCustomInput1 = !ListForInstruments.some((inst) => inst.name === channel1?.name);
		const isCustomInput2 = !ListForInstruments.some((inst) => inst.name === channel2?.name);
	
	  
		const shouldDisableSave =
	
		  (isCustomInput1 && channel1?.name?.length > 15) ||
		  (isCustomInput2 && channel2?.name?.length > 15);
	  
		setIsSaveDisabled(shouldDisableSave);
	  }, [channel1, channel2, ListForInstruments]);
	  

	
	const handleChangeChannel1 = (event: { target: { value: string } }) => {
		const selectedInstrument = findInstrumentByName(event.target.value);
		if (selectedInstrument) {
			setChannel1({
				name: selectedInstrument.name,
				icon: selectedInstrument ? selectedInstrument.icon : InstrumentImg,
			});
		} else {
			setChannel1({ name: event.target.value, icon: InstrumentImg });
		}
	};
	const handleChangeNameChannel1 = (event: { target: { value: string } }) => {
		const value = event.target.value;
		const selectedInstrument = findInstrumentByName(event.target.value);
		if (selectedInstrument) {
			setChannel1({
				name: selectedInstrument.name,
				icon: selectedInstrument ? selectedInstrument.icon : InstrumentImg,
			});
		} else {
			setChannel1({ name: event.target.value, icon: InstrumentImg });
		}

	};

	const handleChangeChannel2 = (event: { target: { value: string } }) => {
		const selectedInstrument = findInstrumentByName(event.target.value);
		if (selectedInstrument) {
			setChannel2({
				name: selectedInstrument.name,
				icon: selectedInstrument ? selectedInstrument.icon : InstrumentImg,
			});
		} else {
			setChannel2({ name: event.target.value, icon: InstrumentImg });
		}
	};
	const handleChangeNameChannel2 = (event: { target: { value: string } }) => {
		const selectedInstrument = findInstrumentByName(event.target.value);
		if (selectedInstrument) {
			setChannel2({
				name: selectedInstrument.name,
				icon: selectedInstrument ? selectedInstrument.icon : InstrumentImg,
			});
		} else {
			setChannel2({ name: event.target.value, icon: InstrumentImg });
		}
	};

	const [micIcon, setMicIcon] = useState("");
	const [micText, setMicText] = useState("");
	const handleChangeMic = (event: { target: { value: string } }) => {
		setMicIcon(event.target.value);
		setMicText(event.target.value);
	};

	const handleClose = () => {
		props.handleClose(false);
		setChannel1({
			name: props?.setVolumeText?.channel1VolumeText,
			icon: findInstrumentByName(props?.setVolumeText?.channel1VolumeText)
				?.icon || InstrumentImg,
		});
		setChannel2({
			name: props?.setVolumeText?.channel2VolumeText,
			icon: findInstrumentByName(props?.setVolumeText?.channel2VolumeText)
				?.icon || InstrumentImg,
		});
	};
	const handleSubmit = (event: any) => {
		event.preventDefault();
		props.setVolumeText.setChannel1VolumeText(channel1?.name);
		props.setVolumeText.setChannel2VolumeText(channel2?.name);
		props.setVolumeText.setMicVolumeText(micText);
		handleClose();
	};

	const token = localStorage.getItem("sessionId");

	const changeChannelNames = () => {
		const preferredNames = JSON.stringify({
			ch1: channel1.name,
			ch2: channel2.name,
		});

		props.handleMixerChannelName(preferredNames);
	};

	return (
		<Dialog
			sx={{
				border: "1px solid #888888",
				boxShadow: "customColor.dropShadow",
				borderRadius: "8px",
			}}
			fullWidth
			maxWidth="xs"
			open={props.editSlider}
		>
			<DialogTitle
				className="edit-heading-name"
				sx={{
					display: "flex",
					flexDirection: "column",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
					}}
				>
					{/* <IconButton
                        aria-label="close dialogbox"
                        onClick={handleClose}
                        onMouseDown={(
                            event: React.MouseEvent<HTMLButtonElement>
                        ) => event.preventDefault()}
                        edge="end"
                        sx={{ marginLeft: "90%" }}>
                        <img
                            src={CloseIcon}
                            alt="close"
                        />
                    </IconButton> */}
				</Box>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "center",
					}}
				>
					<Typography
						className="dialog-title-custom-size"
						sx={{ fontWeight: 500, color: "#000000", fontSize: "1.5rem" }}
					>
						{/* Edit: {props.participantName} */}
						Edit Channel Name
					</Typography>
				</Box>
			</DialogTitle>
			<Typography
				variant="h6"
				sx={{ fontSize: "1rem", fontWeight: 400, alignSelf: "center" }}
				gutterBottom
			>
				{i18n.jamSession.mixerSlider.text}
			</Typography>
			<form onSubmit={(e) => handleSubmit(e)}>
				<DialogContent sx={{ mx: 3 }}>
					<Grid container>
						<Grid item xs={7}>
							<FormControl sx={{ m: 1 }} variant="standard">
								<Typography className="sliderText">
									{i18n.jamSession.mixerSlider.channel1}
								</Typography>
								<Autocomplete
									freeSolo
									value={channel1?.name}
									onInputChange={(event, newValue) => {
										const isCustomInput = !ListForInstruments.some(
											(inst) => inst.name === newValue
										);
										if (isCustomInput && newValue.length > 15) {
											setIsChannel1NameExceed(true);
											setIsSaveDisabled(true);
										} else {
											setIsChannel1NameExceed(false);
											setIsSaveDisabled(false);
										}
										handleChangeNameChannel1({ target: { value: newValue } });
									}}
									onChange={(event, newValue) => {
										if (typeof newValue === "string") {
											handleChangeNameChannel1({ target: { value: newValue } });
										}
									}}
									options={ListForInstruments.map((inst) => inst.name)}
									renderInput={(params) => (
										<BootstrapInput
											{...params.InputProps}
											inputProps={{
												...params.inputProps,
												placeholder: "Select an instrument",
										
											}}
										/>
									)}
									className="sliderInput"
									sx={{
										"& .MuiInputBase-input": {
											padding: "7.7px 3px 7.7px 3px !important",
											width: "164px !important",
										},
										"& .MuiAutocomplete-paper": {
											width: "164px",
										},
									}}	
								/>
								{isChannel1NameExceed ? (
									<p style={{ color: "red", fontSize: "10px" }}>
										Max character length is 15
									</p>
								) : null}
							</FormControl>
						</Grid>
						<Grid item xs={5}>
							<FormControl sx={{ m: 1, minWidth: 130 }} variant="standard">
								<Typography className="sliderText">
									{i18n.jamSession.mixerSlider.icon}
								</Typography>
								<Select
									labelId="channel1-Icon"
									id="channel1-Icon"
									value={channel1?.name}
									onChange={handleChangeChannel1}
									input={<BootstrapInput />}
									className="sliderInput"
									sx={{
										"& .MuiInputBase-input": {
											padding: "7.7px 3px 7.7px 3px !important",
											width: "125.4px !important",
										},
										textAlign: "left",
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												maxHeight: 380,
												overflowY: "auto",
												minWidth: "130px !important",
												// top:"405px !important"
											},
										},
										anchorOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "top",
											horizontal: "left",
										},
									}}
								>
									<MenuItem value="">
										<em></em>
									</MenuItem>
									{ListForInstruments.map((instrument, index) => (
										<MenuItem
											key={index}
											value={instrument.name}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
											}}
										>
											<img
												src={instrument.icon}
												alt={instrument?.name}
												style={{
													alignItems: "center",
													justifyContent: "space-between",
													filter: "grayscale(100%) brightness(60%)",
												}}
											/>
										</MenuItem>
									))}

									{channel1.name &&
										!ListForInstruments.some(
											(inst) => inst.name === channel1.name
										) && (
											<MenuItem
												value={channel1.name}
												sx={{
													display: "flex",
													alignItems: "center",
													gap: "8px",
												}}
											>
												<img
													src={InstrumentImg}
													alt="Default Icon"
													width="20"
													style={{
														filter: "grayscale(100%) brightness(60%)",
													}}
												/>{" "}
											</MenuItem>
										)}
								</Select>
							</FormControl>
						</Grid>

						<Grid item xs={7}>
							<FormControl sx={{ m: 1 }}>
								<Typography className="sliderText">
									{i18n.jamSession.mixerSlider.channel2}
								</Typography>
								<Autocomplete
									freeSolo
									value={channel2?.name}
									onInputChange={(event, newValue) => {
										const isCustomInput = !ListForInstruments.some(
											(inst) => inst.name === newValue
										);
										if (isCustomInput && newValue.length > 15) {
											setIsChannel2NameExceed(true);
											setIsSaveDisabled(true);
										} else {
											setIsChannel2NameExceed(false);
											setIsSaveDisabled(false);
										}
										handleChangeNameChannel2({ target: { value: newValue } });
									}}
									onChange={(event, newValue) => {
										if (typeof newValue === "string") {
											handleChangeNameChannel2({ target: { value: newValue } });
										}
									}}
									options={ListForInstruments.map((inst) => inst.name)}
									renderInput={(params) => (
										<BootstrapInput
											{...params.InputProps}
											inputProps={{
												...params.inputProps,
												placeholder: "Select an instrument",
												
											}}
										/>
									)}
									className="sliderInput"
									sx={{
										"& .MuiInputBase-input": {
											padding: "7.7px 3px 7.7px 3px !important",
											width: "164px !important",
										},
										"& .MuiAutocomplete-paper": {
											width: "164px",
										},
									}}
								/>
								{isChannel2NameExceed ? (
									<p style={{ color: "red", fontSize: "10px" }}>
										{" "}
										Max character length is 15
									</p>
								) : null}
							</FormControl>
						</Grid>
						<Grid item xs={5}>
							<FormControl sx={{ m: 1, minWidth: 130 }}>
								<Typography className="sliderText">
									{i18n.jamSession.mixerSlider.icon}
								</Typography>
								<Select
									labelId="channel2-Icon"
									id="channel2-Icon"
									value={channel2?.name}
									onChange={handleChangeChannel2}
									input={<BootstrapInput />}
									className="sliderInput"
									sx={{
										"& .MuiInputBase-input": {
											padding: "7.7px 3px 7.7px 3px !important",
											width: "125.4px !important",
										},
										textAlign: "left",
									}}
									MenuProps={{
										PaperProps: {
											sx: {
												maxHeight: 380,
												overflowY: "auto",
												minWidth: "130px !important",
											},
										},
										anchorOrigin: {
											vertical: "top",
											horizontal: "left",
										},
										transformOrigin: {
											vertical: "bottom",
											horizontal: "left",
										},
									}}
								>
									<MenuItem value="">
										<em></em>
									</MenuItem>
									{ListForInstruments.map((instrument, index) => (
										<MenuItem
											key={index}
											value={instrument.name}
											sx={{
												display: "flex",
												alignItems: "center",
												justifyContent: "center",
												filter: "grayscale(100%) brightness(60%)",
											}}
										>
											<img src={instrument?.icon} alt={instrument?.name} />
										</MenuItem>
									))}

									{channel2.name &&
										!ListForInstruments.some(
											(inst) => inst.name === channel2.name
										) && (
											<MenuItem value={channel2.name}>
												<img
													src={InstrumentImg}
													alt="Default Icon"
													width="20"
													style={{
														filter: "grayscale(100%) brightness(60%)",
													}}
												/>{" "}
											</MenuItem>
										)}
								</Select>
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Grid
						container
						columnSpacing={1}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "center",
						}}
					>
			

						<Grid item={true}>
							<Button
								className="cancel-edit-button"
								size="large"
								variant="outlined"
								onClick={handleClose}
							>
								{i18n.jamSession.mixerSlider.cancel}
							</Button>
						</Grid>

						<Grid item={true}>
							<Button
								className="edit-button"
								size="large"
								variant="contained"
								type="submit"
								onClick={changeChannelNames}
								disabled={isSaveDisabled}
							>
								{i18n.jamSession.mixerSlider.save}
							</Button>
						</Grid>
					</Grid>
				</DialogActions>
			</form>
		</Dialog>
	);
};

export default MixerSliderEdit;
