import React, { memo, useEffect } from 'react'
import { useDailyUtils } from '../useDailyUtils';
import { Card, ShareScreenViewCardWrapper, ShareScreenViewWrapper, Wrapper } from './GalleryStyled';
import Tile from '../Tile/Tile';
import FloatingTile from './FloatingTile';
import { useFullscreenModeContext } from '../../../context/FullscreenModeContext';
import { useDailyAppRoom } from '../useDailyGlobalRoom';

type DailyGalleryLayout = {
  screens: any[],
  isDrawerOpen: boolean,
  localSessionId: string,
  isAlone: boolean
};

function GalleryLayout({ screens, isDrawerOpen, localSessionId, isAlone }: DailyGalleryLayout) {
  const { currentPageData } = useDailyUtils();
  const { isFullscreenMode, isTabHidden } = useFullscreenModeContext();
  const {isStreamingView, isScreenSharing, setIsScreenSharing} = useDailyAppRoom();
  const participantCount = currentPageData.length;

  useEffect(()=> {
    if(screens.length === 0 && isScreenSharing) {
      setIsScreenSharing(false)
    }
  }, [screens])

  return (
    screens.length > 0 ? (
      <ShareScreenViewWrapper>
        <Wrapper participantCount={screens.length} isDrawerOpen={isDrawerOpen} isFullscreenMode={isFullscreenMode|| isStreamingView || isTabHidden}>
          {screens.map((screen: any) => (
            <Card key={screen.screenId} isScreenShare>
              <Tile id={screen.session_id} isScreenShare />
            </Card>
          ))}
          
        </Wrapper>
        <ShareScreenViewCardWrapper participantCount={participantCount}>
          {currentPageData.map((id) => (
            <Card key={id}>
              <Tile id={id} isLocal={id === localSessionId} isAlone={Boolean(localSessionId && isAlone)} hideVolumeSlider/>
            </Card>
          ))}
          </ShareScreenViewCardWrapper>
      </ShareScreenViewWrapper>
    ) : (
      <Wrapper participantCount={participantCount} isDrawerOpen={isDrawerOpen} isFullscreenMode={isStreamingView || isFullscreenMode || isTabHidden }>
        {currentPageData.map((id) => {
          const isLocal = id === localSessionId;
          if (isLocal && participantCount === 2) {
            return (<>
              <FloatingTile isDrawerOpen={isDrawerOpen}>
                <Card key={id} isFloating>
                  <Tile id={id} isLocal={id === localSessionId} isAlone={Boolean(localSessionId && isAlone)} />
                </Card>
              </FloatingTile>
            </>)
          }
          return (
            <Card key={id}>
              <Tile id={id} isLocal={id === localSessionId} isAlone={Boolean(localSessionId && isAlone)} />
            </Card>
          )
        })}
      </Wrapper>
    )
  )
}

export default  memo(GalleryLayout);