import styled, { keyframes } from 'styled-components';

// Keyframes for animations
const emerge = keyframes`
  to {
    bottom: 85%;
    opacity: 0;
  }
`;

const wiggle1 = keyframes`
  from {
    margin-left: -50px;
  }
  to {
    margin-left: 50px;
  }
`;

const wiggle2 = keyframes`
  from {
    margin-left: 50px;
  }
  to {
    margin-left: -50px;
  }
`;

// Styled component for the flying emojis overlay
export const EmojisWrapper = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  pointer-events: none;
  user-select: none;
  z-index: 99;

  .emoji {
    position: absolute;
    bottom: 0;
    left: 10%;
    font-size: 48px;
    line-height: 1;
    width: 48px;
    height: 48px;

    &.wiggle-1 {
      animation: ${emerge} 3s forwards,
        ${wiggle1} 1s ease-in-out infinite alternate;
    }

    &.wiggle-2 {
      animation: ${emerge} 3s forwards,
        ${wiggle2} 1s ease-in-out infinite alternate;
    }
  }
`;