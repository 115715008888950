import { memo, useState, useEffect, useRef, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { styled } from "@mui/styles";
import {
	Alert,
	AlertTitle,
	Button,
	Grid,
	IconButton,
	Snackbar,
	Typography,
} from "@mui/material";
import WebsocketClientUSerStatus from "../../services/WebsocketClientUSerStatus";
import getErrorInfo from "../../types/errorTypes";
import DOMPurify from "dompurify";
import CloseIcon from "../../icons/CloseIcon";
import ErrorMark from "../../images/Alert-filled.svg";

const DeviceError = (props: any) => {
	const {deviceError, errorCode, errorMessage, handleClose} = props;
	// const [deviceError, setDeviceError] = useState(false);
	// const [errorCode, setErrorCode] = useState("");
	// const [deviceId, setDeviceId] = useState("");
	// const [errorMessage, setErrorMessage] = useState("");

	// useEffect(() => {
	// 	WebsocketClientUSerStatus.on("errorCode", onErrorReceived);
	// 	return () => {
	// 		WebsocketClientUSerStatus.off("errorCode", onErrorReceived);
	// 	};
	// }, []);

	// const handleClose = () => {
	// 	setDeviceError(false);
	// };

	// const linkifyMessage = (errorMessage: string) => {
	// 	const contactUsText = "contact us";
	// 	const contactUsLink = `<a href="${process.env.REACT_APP_BASE_URL_FRONTEND}/helpAndSupport" target="_blank" rel="noopener noreferrer" style="color: blue;">contact us</a>`; // Update with your contact link

	// 	if (errorMessage.includes(contactUsText)) {
	// 		return errorMessage.replace(contactUsText, contactUsLink);
	// 	}

	// 	return errorMessage;
	// };

	// const onErrorReceived = (payLoad: any) => {
	// 	setErrorCode(payLoad?.metadata?.error_code);
	// 	setDeviceId(payLoad?.metadata?.created_by);
	// 	const message = payLoad?.metadata?.error;
	// 	setErrorMessage(linkifyMessage(message));
	// 	setDeviceError(true);
	// };

	const errorInfo = errorCode !== "102" ? getErrorInfo(errorCode) : null;
	return (
		<Snackbar
			open={deviceError}
			onClose={handleClose}
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			sx={{
				marginTop: "60px",
				borderRadius: "8px",
				padding: "9px 10px !important",
				backgroundColor: "#ffffff",
			}}
		>
			<Alert
				icon={false}
				action={
					<IconButton
						size="small"
						color="inherit"
						onClick={handleClose}
						sx={{ marginTop: "-70px" }}
					>
						<CloseIcon />
					</IconButton>
				}
				sx={{ width: "100%", backgroundColor: "#ffffff" }}
			>
				<Grid container spacing={2} alignItems="center">
					<Grid item sx={{ display: "flex" }}>
						<img
							src={ErrorMark}
							alt="Error Mark"
							style={{ height: "24px", width: "24px" }}
						/>
					</Grid>
					<Grid item justifyContent={"center"} alignItems="center">
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontWeight: 500,
								fontSize: "18px",
								lineHeight: "22.5px",
							}}
						>
							{errorCode !== "102"
								? errorInfo?.title
								: "Connection to peer lost unexpectedly!"}
						</Typography>
					</Grid>
				</Grid>
				<Grid container alignItems="center">
					<Grid
						item
						justifyContent={"center"}
						alignItems={"center"}
						sx={{ marginLeft: "2.5rem" }}
					>
						<Typography
							sx={{
								fontFamily: "Lexend",
								fontSize: "16px",
								fontWeight: 500,
								lineHeight: "20px",
								color: "#7A7985",
							}}
							dangerouslySetInnerHTML={{ __html: errorMessage }}
						></Typography>
					</Grid>
				</Grid>
				<Grid
					display={"flex"}
					justifyContent={"right"}
					sx={{ marginTop: "10px" }}
				>
					<Typography
						sx={{
							fontFamily: "Lexend",
							fontWeight: 400,
							fontSize: "16px",
							lineHeight: "20px",
							color: "#7a7985",
						}}
					>
						Error Code: {errorCode}
					</Typography>
				</Grid>
			</Alert>
		</Snackbar>
	);
};

export default memo(DeviceError);
