// Callback to handle "audioControl" event
export const onMessageReceived = (
    payload: any,
    participantIdentity: any,
    setShowVolumeBar?: (value: boolean) => void,
    setIsAudioMute?: (value: boolean) => void,
    setVolume?: (value: number) => void,
    setPrevVolume?: (value: number) => void
) => {
    let participantDetailList = JSON.parse(localStorage.getItem("participantVolumeDetails") ?? "[]");

    // Default no-op function that accepts any arguments
    const noOp = (...args: any[]) => {};
    
    // Use no-op if the function is undefined
    const showVolumeBar = setShowVolumeBar || noOp;
    const isAudioMute = setIsAudioMute || noOp;
    const volumeSetter = setVolume || noOp;
    const prevVolumeSetter = setPrevVolume || noOp;
    
    if (payload.metadata.created_by === participantIdentity) {
        showVolumeBar(true);
        setTimeout(() => {
            showVolumeBar(false);
        }, 3000);
        if (payload.metadata.audio.master.audio_status === "mute") {
            isAudioMute(true);
            volumeSetter(0);
        } else {
            isAudioMute(false);
            //console.log( payload.metadata.audio.master.audio_level);
            volumeSetter(payload.metadata.audio.master.audio_level);
            prevVolumeSetter(payload.metadata.audio.master.audio_level);
        }
    }
    if (payload.metadata.audio.master.audio_status === "mute") {
        participantDetailList = [
            ...participantDetailList.map((participant: any) => {
                if (
                    participant.email !== undefined &&
                    participant.email === payload.metadata.created_by
                ) {
                    return {
                        ...participant,
                        audio: {
                            ...participant.audio,
                            master: {
                                ...participant.audio.master,
                                audio_status: "mute",
                                audio_level: 0,
                            },
                            channel_1: {
                                ...participant.audio.channel_1,
                                audio_status: "mute",
                                audio_level: payload.metadata.audio.channel_1.audio_level,
                            },
                            channel_2: {
                                ...participant.audio.channel_2,
                                audio_status: "mute",
                                audio_level: payload.metadata.audio.channel_2.audio_level,
                            },
                            mic: {
                                ...participant.audio.mic,
                                audio_status: "mute",
                                audio_level: payload.metadata.audio.mic.audio_level,
                            },
                        },
                    };
                } else {
                    return participant;
                }
            }),
        ];
    } else {
        participantDetailList = [
            ...participantDetailList.map((participant: any) => {
                if (
                    participant.email !== undefined &&
                    participant.email === payload.metadata.created_by
                ) {
                    return {
                        ...participant,
                        audio: {
                            ...participant.audio,
                            master: {
                                ...participant.audio.master,
                                audio_status: "unmute",
                                audio_level: payload.metadata.audio.master.audio_level,
                            },
                            channel_1: {
                                ...participant.audio.channel_1,
                                audio_status: "unmute",
                                audio_level: payload.metadata.audio.channel_1.audio_level,
                            },
                            channel_2: {
                                ...participant.audio.channel_2,
                                audio_status: "unmute",
                                audio_level: payload.metadata.audio.channel_2.audio_level,
                            },
                            mic: {
                                ...participant.audio.mic,
                                audio_status: "unmute",
                                audio_level: payload.metadata.audio.mic.audio_level,
                            },
                        },
                    };
                } else {
                    return participant;
                }
            }),
        ];
    }
    localStorage.setItem(
        "participantVolumeDetails",
        JSON.stringify(participantDetailList)
    );
};