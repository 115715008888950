import {
	Box,
	Button,
	Card,
	CardMedia,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	IconButton,
	Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import DeleteIcon from "../../images/deleteProfile.svg";
import EditIcon from "../../images/editProfile.svg";
import UploadIcon from "../../images/uploadProfilePhoto.svg";
import ImageCropper from "../../pages/ImageCropper";
import Close from "../../images/deleteCover.svg";
import CloseIcon from "../../images/close.svg";
import { useRef } from "react";
import { useAlertStatus } from "../../context/AlertStatusContext";
import SettingsAlert from "../../pages/SettingsAlert";

const BannerImage = (props: any) => {
	
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [bannerImgPreviewUrl, setbannerImgPreviewUrl] = useState<any>(
		props.bannerPreviewUrl
	);
	const [bannerImgFile, setBannerImgFile] = useState<any>("");
	const [bannerResult, setBannerResult] = useState("");
	const [bannerOpen, setBannerOpen] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const { updateAlertStatus } = useAlertStatus();

	const defaultBannerImage =
		props.type === "event"
			? process.env.REACT_APP_PICTURE_URL + "/defaultimages/event-banner.png"
			: process.env.REACT_APP_PICTURE_URL + "/defaultimages/gig-banner.png";

	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		// Check if the file is an image
		if (!file.type.startsWith("image/")) {
			updateAlertStatus(
				true,
				false,
				"Invalid file format. Please upload an image."
			);
			return;
		}

		 const maxSizeInMB = 4;
		 e.target.value = null;
		 if (file.size > maxSizeInMB * 1024 * 1024) {
			 updateAlertStatus(
				 true,
				 false,
				 `File size exceeds ${maxSizeInMB}MB. Please upload a smaller file.`
			 );
			 return;
		 }

		setBannerResult(URL.createObjectURL(file));
		setBannerImgFile(file);
		setBannerOpen(true);

		const newInput = document.createElement("input");
		newInput.type = "file";
		newInput.accept = "image/*";
		newInput.style.display = "none";
		newInput.addEventListener("change", handleOpenEditor);

		const currentInput = fileInputRef.current;
		if (currentInput) {
			const parent = currentInput.parentNode;
			if (parent) {
				parent.replaceChild(newInput, currentInput);
				fileInputRef.current = newInput;
			}
		}
	};
	const deleteUpload = () => {
		setbannerImgPreviewUrl("");
		setBannerImgFile('')
    // props.imageDeleted(true);
		setOpenDeleteDialog(false);
		if(props.updateBannerUrl) {
			props.updateBannerUrl();
		}
	};
	const imgUpload = (destFile: any) => {
		// e.preventDefault();
		const reader = new FileReader();
		const file = destFile;
		reader.onloadend = () => {
			setBannerImgFile(file);
			props.imgFile(file);
			setbannerImgPreviewUrl(reader.result as string);
		};
		reader.readAsDataURL(file);
	};
	const handleimgUploadClose = () => {
		setBannerOpen(false);
	};
	const handleCloseDeleteDialog = () => {
		setOpenDeleteDialog(false);
	};

	useEffect(() => {
		if (props.bannerPreviewUrl === `${process.env.REACT_APP_PICTURE_URL}/defaultimages/gig-banner.png` || props.bannerPreviewUrl === `${process.env.REACT_APP_PICTURE_URL}`){
      setbannerImgPreviewUrl("");
    }else{
      setbannerImgPreviewUrl(props.bannerPreviewUrl);
    }
	}, [props.bannerPreviewUrl]);




	return (
		<>
			<Card
				sx={{
					minWidth: 300,
					flexGrow: 1,
					//height: "181px",
					height: "98.5%",
					position: "relative",
					border: ((props.type === "event") && ((bannerImgPreviewUrl === null || bannerImgPreviewUrl === "" ))) ? "2px solid #999999" : "none",
					

					boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
					borderRadius: "10px",
				}}
			>
				<SettingsAlert />
				<label
					id="photo-upload"
					title="Upload Photo"
					style={{
						position: "absolute",
						top: "85px",
						left: "250px",
						cursor: "pointer",
						zIndex: 2,
						display: "flex",
						// width: "210px !importent",
						// height: "36px",
						padding: " 5px 30px",
						backgroundColor: "#fff",
						border: "1.5px solid #0273E6",
						color: "#0273E6",
						borderRadius: "18px",
					}}
				>
					{props.type === "gig" ? "Upload Gig Banner" : "Upload Event Banner"}
					{/* <img
               
                style={{ height: '32px', width: '32px', backgroundColor: 'black' }}
                src={EditIcon}
                alt="Upload"
            /> */}
					<input
						ref={fileInputRef}
						id="photo-upload"
						type="file"
						accept =".jpg, .jpeg, .png, .gif"
						style={{ display: "none" }}
						onChange={(e) => handleOpenEditor(e)}
					/>
				</label>
				{bannerImgPreviewUrl && 
                 (
					<IconButton
						onClick={() => setOpenDeleteDialog(true)}
						sx={{
							position: "absolute",
							top: "5px",
							right: "5px",
							cursor: "pointer",
							zIndex: 2,
						}}
					>
						<img
							src={Close}
							alt="Close"
							style={{
								height: "32px",
								width: "32px",
								backgroundColor: "black",
							}}
						/>
					</IconButton>
        )}
				<CardMedia
					sx={{ height: "181px", backgroundColor: "#727272", 
					//border: ((props.type === "event") && (bannerImgPreviewUrl === null || bannerImgPreviewUrl === "" )) ? "2px solid #FF702A" : "none"
				}}
					image={bannerImgPreviewUrl === null || bannerImgPreviewUrl === "" ? defaultBannerImage: bannerImgPreviewUrl}
				/>
			</Card>

			{openDeleteDialog && (
				<Dialog
					fullWidth
					maxWidth="sm"
					open={openDeleteDialog}
					onClose={() => handleCloseDeleteDialog()}
				>
					<DialogTitle
						className="edit-heading-name"
						sx={{
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end",
							}}
						>
							<IconButton
								aria-label="close dialogbox"
								onClick={() => handleCloseDeleteDialog()}
								onMouseDown={(event: React.MouseEvent<HTMLButtonElement>) =>
									event.preventDefault()
								}
								edge="end"
								sx={{ marginLeft: "90%", position: "absolute", top: "8px" }}
							>
								<img src={CloseIcon} alt="close" />
							</IconButton>
						</Box>
						<Typography>Delete Banner Image</Typography>
					</DialogTitle>
					<DialogContent>
						<DialogContentText
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
							}}
						>
							Are you sure?
						</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button
							variant="outlined"
							onClick={() => handleCloseDeleteDialog()}
						>
							Cancel
						</Button>
						<Button variant="contained" onClick={() => deleteUpload()}>
							Confirm
						</Button>
					</DialogActions>
				</Dialog>
			)}

			<ImageCropper
				open={bannerOpen}
				onClose={handleimgUploadClose}
				imagePreviewUrl={bannerResult}
				setImagePreviewUrl={setbannerImgPreviewUrl}
				setResult={setBannerImgFile}
				post={false}
				photoUpload={imgUpload}
				cover={true}
			/>
		</>
	);
};

export default BannerImage;
