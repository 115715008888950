import {
	Autocomplete,
	Button,
	Chip,
	Dialog,
	DialogContent,
	DialogTitle,
	Divider,
	FormHelperText,
	Grid,
	IconButton,
	Input,
	TextField,
	Typography,
} from "@mui/material";
import Close from "../../../images/Posts/close.svg";
import Picture from "../../../images/Posts/picture-upload-blue.svg";
import Music from "../../../images/Posts/music-upload-blue.svg";
import { useEffect, useRef, useState } from "react";
import { useUser } from "../../../context/UserContext";
import { makeStyles } from "@mui/styles";
import Send from "../../../images/Posts/send.svg";
import { Client } from "@twilio/conversations";
import { SetParticipantsType, SetSidType } from "../../../types";
import { ReduxConversation } from "../../../store/reducers/convoReducer";
import { useAlertStatus } from "../../../context/AlertStatusContext";
import {
	addConversation,
	checkUserByUniqueName,
	onlyCreateConversation,
} from "../../Messages/api";
import { bindActionCreators } from "redux";
import { AppState, actionCreators } from "../../../store";
import { useDispatch, useSelector } from "react-redux";
import { getSdkConversationObject } from "../../Messages/conversations-objects";
import Profile from "../../../images/profile-circle-auto.svg";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import CancelIcon from "../../../images/Group 4282.svg";
import CustomAudioPlayer from "../../../pages/CustomAudioPlayer";
import ImageCropper from "../../../pages/ImageCropper";

const useStyles = makeStyles((theme: any) => ({
	root: {
		color: "white",
		"& .MuiAutocomplete-tag": {
			backgroundColor: "#0273E6",
			color: "white",
			"&:hover": {
				backgroundColor: "#0273E6",
				color: "white",
			},
		},
		"& .MuiSvgIcon-root": {
			color: "white",
			"&:hover": {
				color: "white",
			},
		},
	},
}));

const ShareProfile = (props: any) => {
	const user = useUser();
	const classes = useStyles();
	const theme = useTheme();
	const [userDetails, setUserDetails] = useState<any>([]);
	const [sending, setSending] = useState(false);
	const [messageText, setMessageText] = useState<any>("");
	const { updateAlertStatus } = useAlertStatus();
	const [openSendMessage, setOpenSendMessage] = useState(false);
	const [userNames, setUserNames] = useState([]);
	const [loading, setLoading] = useState(false);
	const inputFileRef = useRef<any>(null);
	const [file, setFile] = useState<any>("");
	const [imagePreviewUrl, setImagePreviewUrl] = useState("");
	const [result, setResult] = useState<any>("");
	const [music, setMusic] = useState<any>("");
	const [open, setOpen] = useState(false);
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
	);

	const closeShareProfileDialog = (success?: boolean | false) => {
		setFile("");
		setResult("");
		setMusic("");
		props.menuClose();
		props.handleClose(success);
	};

	const dispatch = useDispatch();
	const { updateCurrentConversation, addNotifications, updateParticipants } =
		bindActionCreators(actionCreators, dispatch);

	const token = localStorage.getItem("sessionId");

	useEffect(() => {
		if (props.open) {
			setLoading(true);
			fetch(
				// `${process.env.REACT_APP_BASE_URL}/api/active-users`,
				`${process.env.REACT_APP_BASE_URL}/api/user-connections/message/user-details`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			)
				.then((res) => {
					if (res.status > 400) {
						updateAlertStatus(true, false, "Something went wrong");
						throw new Error("api_error");
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data !== null) {
						let filteredList: any = [];
						filteredList = data.filter(
							(item: any) =>
								item.userPrivacySettingsDTO.messagePermission ===
									"Anyone can message me without being in connection" ||
								(item.userPrivacySettingsDTO.messagePermission ===
									"Only connections can send me message" &&
									item.isConnected === true)
						);
						// .map((auser:any) => {
						// 	let comboUniqueName = [user.email, auser.user.email].sort().join('-');
						// })
						setUserDetails(
							filteredList.sort((a: any, b: any) =>
								(a.user.firstName + " " + a.user.lastName).localeCompare(
									b.user.firstName + " " + b.user.lastName
								)
							)
						);
						setLoading(false);
					}
				})
				.catch((err) => {
					updateAlertStatus(true, false, "Something went wrong");
					setLoading(false);
				});
		}
	}, [props.open]);

	useEffect(() => {
		setMessageText(props.profileLink);
	}, [props]);

	// const handleAutocompleteChange = async (
	// 	event: any,
	// 	item: any
	// ) => {
	// if (item.length < 1) {
	//   return;
	// }

	// setError("");
	// setSelectedUser(item);
	// };

	// const handleGetProfileLink = () => {

	//     const generateLink = `${process.env.REACT_APP_BASE_URL_FRONTEND}/userProfile/${user?.id}`;
	//     navigator.clipboard.writeText(generateLink)
	//     .then(() => {
	//         setProfileLink(generateLink);
	//     })
	//     .catch((error) =>{
	//     });
	// };
	const isEnable = () => {
		var messageLength = messageText.trim().length;
		if ((messageLength > 0 || file !== "") && userNames.length > 0) {
			return false;
		}
		return true;
	};

	const sendTextMessage = async (conversationDetails: any) => {
		conversationDetails
			.sendMessage(messageText)
			.then(async (convo: any) => {
				props.menuClose();
				updateAlertStatus(true, true, "Message sent successfully");
				setMessageText("");
				setSending(false);
				setOpenSendMessage(false);
				closeShareProfileDialog(false);
				setOpenSendMessage(false);
			})
			.catch((error: any) => {
				console.error("Error sending message:", error);
				updateAlertStatus(true, false, "Something went wrong");
			});
	};

	const sendFileMessage = async (conversationDetails: any) => {
		const formData = new FormData();
		formData.append("file", file);
		conversationDetails
			.sendMessage(messageText)
			.then(async (convo: any) => {
				return conversationDetails.sendMessage(formData);
			})
			.then(async (convo: any) => {
				props.menuClose();
				updateAlertStatus(true, true, "Message sent successfully");
				setMessageText("");
				setSending(false);
				setOpenSendMessage(false);
				closeShareProfileDialog(false);
				setOpenSendMessage(false);
			})
			.catch((error: any) => {
				console.error("Error sending message:", error);
				updateAlertStatus(true, false, "Something went wrong");
			});
	};

	const sendMessageText = async () => {
		setSending(true);
		const token = localStorage.getItem("conversationToken");
		const client = new Client(token as string);

		if (userNames.length > 0) {
			userNames.forEach(async (selectedUserName: any) => {
				let title = user?.firstName + " " + user?.lastName;
				let uniqueName = [selectedUserName.user.email].sort().join("-");
				let uniqueNameForCheck = [selectedUserName.user.email, user?.email]
					.sort()
					.join("-");

				try {
					client.on("initialized", async () => {
						client
							.getConversationByUniqueName(uniqueNameForCheck)
							.then((conversationDetails) => {
								if (file) {
									sendFileMessage(conversationDetails);
								} else {
									sendTextMessage(conversationDetails);
								}
							})
							.catch(async (error: any) => {
								if (error.message === "Not Found") {
									const convo = await addConversation(
										title,
										uniqueName,
										updateParticipants,
										client,
										addNotifications
									);

									if (convo.sid) {
										const conversationDetails =
											await client.getConversationBySid(convo.sid);
										if (file) {
											sendFileMessage(conversationDetails);
										} else {
											sendTextMessage(conversationDetails);
										}
										
									}
								} else {
									updateAlertStatus(
										true,
										false,
										"Something went wrong, Please try again"
									);
									
									setSending(true);
								}
							});
					});
				} catch (error) {
					console.error("Error initializing the client:", error);
					updateAlertStatus(true, false, "An unexpected error occurred.");
				} finally {
					// Ensure that the sending state is updated regardless of the outcome
					setSending(false);
				}
			});
			
		}
	};

	const handlePictureChange = () => {
		setImagePreviewUrl("");
		setResult("");
		setFile("");
	};
	const handleMusicChange = () => {
		setMusic("");
		setFile("");
	};
	const handleClose = () => {
		setOpen(false);
		setResult('');
	};
	const handleOpenEditor = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];
		if (file.type.startsWith("image/")) {
			setResult(URL.createObjectURL(file));
			setFile(file);
			setOpen(true);
		} else {
			updateAlertStatus(true, false, "Please select valid picture");
		}
		if (inputFileRef.current) {
			inputFileRef.current.value = null; // Clear the input value
			const newInput = document.createElement("input");
			newInput.style.display = "none";
			newInput.type = "file";
			newInput.accept = "image/*";
			newInput.addEventListener("change", handleOpenEditor);
			inputFileRef.current.parentNode.replaceChild(
				newInput,
				inputFileRef.current
			);
			inputFileRef.current = newInput;
		}
	};

	const handleOpenMusic = (e: any) => {
		e.preventDefault();
		const file = e.target.files[0];

		//Convert bytes to MB & Round to the nearest integer
		var roundedFileSizeInMB = Math.round(file.size / (1024 * 1024));
		// Convert MB back to bytes
		const roundedFileSizeInBytes = roundedFileSizeInMB * (1024 * 1024);

		if (file.type.startsWith("audio/")) {
			// if (roundedFileSizeInBytes > 10 * 1024 * 1024) {
			// 	updateAlertStatus(
			// 		true,
			// 		false,
			// 		"Music size exceeds the maximum allowed limit of 10Mb. Please try again."
			// 	);
			// } else {
			setMusic(URL.createObjectURL(file));
			setFile(file);
			//}
			if (inputFileRef.current) {
				inputFileRef.current.value = null; // Clear the input value
				const newInput = document.createElement("input");
				newInput.type = "file";
				newInput.style.display = "none";
				newInput.accept = "audio/*,.mp3,.wav,.mpeg";
				newInput.addEventListener("change", handleOpenEditor);
				inputFileRef.current.parentNode.replaceChild(
					newInput,
					inputFileRef.current
				);
				inputFileRef.current = newInput;
			}
		} else {
			updateAlertStatus(true, false, "Invalid file type");
		}
	};

	useEffect(() => {
		if (file && file.type.startsWith("image/")) {
			const objectUrl = URL.createObjectURL(file);
			setResult(objectUrl);

			// Cleanup function to revoke the object URL when the component unmounts or when file changes
			return () => {
				URL.revokeObjectURL(objectUrl);
			};
		}
	}, [file]);
	return (
		<>
			<Dialog
				sx={{
					border: "1px solid #888888",
					boxShadow: "customColor.dropShadow",
					borderRadius: "8px",
				}}
				fullWidth
				maxWidth="sm"
				open={props.open}
			>
				<DialogTitle>
					<Typography>Share Profile in message</Typography>
				</DialogTitle>
				<IconButton
					aria-label="close"
					onClick={() => closeShareProfileDialog(false)}
					sx={{
						position: "absolute",
						right: 8,
						top: 8,
						color: "black",
					}}
				>
					<img src={Close} alt="close" />
				</IconButton>
				<Divider variant="middle" sx={{ mb: 1 }} />
				<DialogContent>
					<Grid container xs={12}>
						<Grid item xs={12} sx={{}}>
							<Autocomplete
								multiple
								id="usernames"
								// limitTags={4}
								classes={classes}
								options={userDetails}
								sx={{ borderRadius: "8px", borderColor: "#C5C5C5" }}
								getOptionLabel={(option: any) =>
									option?.user?.firstName + " " + option?.user?.lastName
								}
								isOptionEqualToValue={(option, value) =>
									option?.user?.firstName + " " + option?.user?.lastName ===
									value?.user?.firstName + " " + value?.user?.lastName
								}
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										{option.user.firstName + " " + option.user.lastName}
									</li>
								)}
								renderTags={(values, getTagProps) =>
									userNames.map((chip: any, index: any) => {
										const { key, className, ...tagProps } = getTagProps({
											index: index,
										});
										return (
											<Chip
												key={key}
												avatar={
													<img
														src={
															chip?.profilePhotoUrl
																? `${process.env.REACT_APP_PICTURE_URL}${chip?.profilePhotoUrl}`
																: Profile
														}
														alt="profile"
														style={{
															borderRadius: "50%",
															objectFit: "cover",
														}}
													/>
												}
												className={`${className} label-chip`}
												label={`${chip.user.firstName} ${chip.user.lastName}`}
												sx={{
													backgroundColor: "customColor.blue",
													fontStyle: theme.typography.subtitle2.fontStyle,
													fontSize: theme.typography.subtitle2.fontSize,
													lineHeight: theme.typography.subtitle2.lineHeight,
													fontWeight: theme.typography.subtitle2.fontWeight,
													color: "secondary.main",
												}}
												{...tagProps}
											/>
										);
									})
								}
								renderInput={(params) => (
									<TextField {...params} label="" placeholder="Select user" />
								)}
								onChange={(userNames, newValue) => {
									setUserNames(newValue as any);
								}}
								loading={loading}
							/>
						</Grid>
						<Grid xs={12} sx={{ mt: 2 }}>
							<OutlinedInput
								id="title"
								rows={5}
								className="social-textField"
								sx={{
									borderColor: "#F0F0F0",
									pl: "20px",
									pr: "40px",
									flexDirection: "column",
									minHeight: "50px",
									fontSize: "16px !important",
									fontWeight: 400,
									"& .MuiOutlinedInput-input": {
										fontWeight: "400 !important",
									},
									"& .MuiOutlinedInput-notchedOutline": {
										borderColor: "#F0F0F0",
									},
									backgroundColor: "#F0F0F0",
									border: "none",
									borderRadius: "12px",
									resize: "none",
								}}
								value={messageText}
								onChange={(e: any) => setMessageText(e.target.value)}
								placeholder="Comment on this"
								disabled={authorities?.includes("ROLE_ADMIN")}
								required
								multiline
								endAdornment={
									<>
										{result || music ? (
											<Grid container>
												{result && (
													<>
														<Grid item xs={7}>
															<img
																src={result}
																alt="Caption this"
																style={{ height: "200px", width: "100%" }}
															/>
														</Grid>
														<Grid item xs={5} sx={{ alignSelf: "center" }}>
															<IconButton
																sx={{ alignSelf: "baseline" }}
																onClick={() => handlePictureChange()}
															>
																<img
																	src={CancelIcon}
																	style={{ height: "20px", width: "20px" }}
																	alt=""
																/>
															</IconButton>
														</Grid>
													</>
												)}
												{music && (
													<>
														<Grid item xs={7}>
															<audio
																crossOrigin="anonymous"
																controls
																controlsList="nodownload noremoteplayback noplaybackrate"
															>
																<source src={music} type="audio/mpeg" />
															</audio>
														</Grid>
														<Grid item xs={5} sx={{ alignSelf: "center" }}>
															<IconButton
																sx={{ alignSelf: "baseline" }}
																onClick={() => handleMusicChange()}
															>
																<img
																	src={CancelIcon}
																	style={{ height: "20px", width: "20px" }}
																	alt=""
																/>
															</IconButton>
														</Grid>
													</>
												)}
											</Grid>
										) : (
											""
										)}
										{/* <InputAdornment position="end" sx={{ alignSelf: 'self-end', py: 1, marginTop: '-20px', mr: '-7%' }}>
											{adding ?
												<IconButton
													aria-label="comment"
													// onClick={() => sendComment()}
													edge="end"
													disabled
												>
													<img src={CreateCommentDisabled} alt='' />
												</IconButton>
												:
												<IconButton
													aria-label="comment"
													onClick={() => sendComment()}
													edge="end"
													disabled={isEnable()}
												>
													<img src={(commentText.trim().length > 0 && commentText.trim().length <= 600) || file !== '' || music !== '' ? CommentPostIcon : CreateCommentDisabled} alt='' />
												</IconButton>
											}
										</InputAdornment> */}
									</>
								}
							/>
							{/* <textarea
								// multiline
								rows={10}
								value={messageText}
								onChange={(e) => setMessageText(e.target.value)}
								// fullWidth
								style={{
									// height: "319px",
									width: "97%",
									backgroundColor: "#F0F0F0",
									border: "none",
									borderRadius: "12px",
									resize: "none",
									paddingTop: 30,
									paddingLeft: 10,
									paddingRight: 10,
									paddingBottom: 30,
									// borderBottom : 'none',
									fontWeight: "500px",
									fontSize: "16px",
								}}
							/> */}
						</Grid>
					</Grid>
					<Grid container sx={{ pl: 2, pt: 2 }}>
						{result !== "" || music !== "" ? (
							<Grid item xs={6}></Grid>
						) : (
							<>
								<Grid item xs={3} sx={{ pl: 1 }}>
									<Button
										sx={{
											color: "black",
											fontSize: "14px",
											pt: "12px",
											height: "32px",
										}}
									>
										<label
											style={{
												cursor: "pointer",
												color: "black",
												fontSize: "14px",
											}}
										>
											<Grid container>
												<img
													src={Picture}
													alt=""
													style={{ height: "24px", width: "24px" }}
												/>
												&nbsp;
												<input
													id="photo-upload"
													type="file"
													accept=".jpg, .jpeg, .png, .gif"
													style={{ display: "none" }}
													onChange={(e) => handleOpenEditor(e)}
												/>
												<Typography sx={{ color: "black", fontSize: "14px" }}>
													Photo
												</Typography>
											</Grid>
										</label>
									</Button>
								</Grid>
								<Grid item xs={3}>
									<Button
										sx={{
											color: "black",
											fontSize: "14px",
											pt: "12px",
											height: "32px",
										}}
									>
										<label
											style={{
												cursor: "pointer",
												color: "black",
												fontSize: "14px",
											}}
										>
											<Grid container>
												<img
													src={Music}
													alt=""
													style={{ height: "24px", width: "24px" }}
												/>
												&nbsp;
												<input
													id="photo-upload"
													// ref={inputFileRef}
													type="file"
													accept="audio/*,.mp3,.wav,.mpeg"
													style={{ display: "none" }}
													onChange={(e) => handleOpenMusic(e)}
												/>
												<Typography sx={{ color: "black", fontSize: "14px" }}>
													Audio
												</Typography>
											</Grid>
										</label>
									</Button>
								</Grid>
							</>
						)}
						<Grid
							item
							xs={6}
							sx={{ textAlignLast: "right", alignSelf: "center" }}
						>
							{sending ? (
								<Button
									variant="contained"
									sx={{
										borderRadius: "17px",
										background: "#FF702A",
										width: "40%",
									}}
									disabled={true}
								>
									{" "}
									Sending...
								</Button>
							) : (
								<Button
									variant="contained"
									sx={{ borderRadius: "17px", background: "#FF702A" }}
									onClick={() => sendMessageText()}
								>
									{" "}
									Send &nbsp;
								</Button>
							)}
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
			<ImageCropper
				open={open}
				onClose={handleClose}
				imagePreviewUrl={result}
				setImagePreviewUrl={setImagePreviewUrl}
				setResult={setFile}
				post={true}
				photoUpload={handleClose}
				cover={false}
				comment={true}
			/>
		</>
	);
};

export default ShareProfile;
