import React from 'react';
import { Button, Typography } from '@mui/material';
import ActionDialog from '../reusable-components/ActionDialog';


const refreshPage = () => {
  console.log(
    "make sure to allow access to your microphone and camera in your browser's permissions",
  );
  window.location.reload();
};

export default function UserMediaError() {
  return (
    <ActionDialog
            open={true}
            handleClose={() => null}
            dialogWidth="30rem"
            title="Browser permission required"
            showCloseIcon={false}
            titleStyle={{
                color: "#000",
                fontSize: "1.375rem",
                fontWeight: "500",
            }}
            titleAlignment="left"
            buttonsAlignment="right"
            actionButtons={
                <>
                    <Button
                        sx={{
                            width: "16.375rem",
                            height: "2.25rem",
                            borderRadius: "18px",
                        }}
                        className="submit-button"
                        size="large"
                        variant="contained"
                        onClick={refreshPage}
                    >
                        Try Agin
                    </Button>
                </>
            }
        >
            <Typography
                variant="body1"
                component="p"
                sx={{ mb: 0, fontSize: "1rem", color: "#000", fontWeight: "400" }}
            >
            Your camera is currently blocked or permission is denied. Please enable it and try again to continue.
            </Typography>

        </ActionDialog>
  );
}
