import { Button, Typography, IconButton, InputAdornment, OutlinedInput, FormHelperText, Grid } from "@mui/material";
import ActionDialog from "../../reusable-components/ActionDialog";
import { useState } from "react";
import i18n from "../../../l10n/strings.json";
import CloseIcon from "../../../images/close.svg";
import CopyIcon from "../../../images/copy.svg";


type StartLiveStreamProps = {
    twilioSessionId: string;
    startLiveStream: () => void;
    openStartLiveStreamDialog: boolean;
    handleCloseStartLiveStreamDialog: () => void;
}

const StartLiveStream: React.FC<StartLiveStreamProps> = (props) => {
    const [isCopied, setIsCopied] = useState(false);

    const url = `${process.env.REACT_APP_BASE_URL_FRONTEND}/liveStreamDetails?id=` + props.twilioSessionId;

    async function copyTextToClipboard() {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(url);
        } else {
            return document.execCommand("copy", true, url);
        }
    }

    // onClick handler function for the copy button
    const handleCopyClick = () => {
        // Asynchronously call copyTextToClipboard
        copyTextToClipboard()
            .then(() => {
                // If successful, update the isCopied state value
                setIsCopied(true);
                setTimeout(() => {
                    setIsCopied(false);
                }, 10000);
            })
            .catch((err) => {
            });
    };


    return (
        <ActionDialog
            open={props.openStartLiveStreamDialog}
            handleClose={props.handleCloseStartLiveStreamDialog}
            dialogWidth="37.5rem"
            title="Start Live Streaming"
            showCloseIcon={false}
            titleStyle={{
                color: "#000",
                fontSize: "1.375rem",
                fontWeight: "500",
            }}
            titleAlignment="left"
            buttonsAlignment="right"
            actionButtons={
                <>
                    <Button
                        sx={{
                            width: "7.812rem",
                            height: "2.25rem",
                            borderRadius: "18px",
                        }}
                        className="cancel-button"
                        size="large"
                        variant="outlined"
                        onClick={props.handleCloseStartLiveStreamDialog}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{
                            width: "16.375rem",
                            height: "2.25rem",
                            borderRadius: "18px",
                        }}
                        className="submit-button"
                        size="large"
                        variant="contained"
                        onClick={() => {
                            props.startLiveStream();
                            props.handleCloseStartLiveStreamDialog();
                        }}
                    >
                        Start Live Streaming
                    </Button>
                </>
            }
        >
            <Typography
                variant="body1"
                component="p"
                sx={{ mb: 3, fontSize: "1rem", color: "#000", fontWeight: "400" }}
            >
                {i18n.liveStream.dialog.liveStreamLinkDescription}
            </Typography>

            <Grid
                container
                style={{
                    display: "flex",
                    flexDirection: "row",
                }}
            >
                <FormHelperText
                    sx={{
                        fontSize: "1rem",
                        color: "#7A7985",
                    }}
                    id="outlined-weight-helper-text"
                >
                    {i18n.dialog.copyUrl}
                </FormHelperText>
                <OutlinedInput
                    type={"text"}
                    sx={{ background: "#F8F8F8", }}
                    fullWidth
                    disabled
                    defaultValue={url}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleCopyClick}
                                edge="end"
                            >
                                <img src={CopyIcon} alt="Eye Slash" />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                {isCopied && (
                    <FormHelperText
                        sx={{
                            color: "#7A7985",
                            textAlign: "right",
                            fontSize: "14px",
                        }}
                    >
                        Copied!!
                    </FormHelperText>
                )}
            </Grid>
        </ActionDialog>
    );
}

export default StartLiveStream;