import { useState, useContext, createContext } from "react";
// types
import PropTypes from "prop-types";
import type { Dispatch, ReactNode, SetStateAction } from "react";
type BroadcastingStatusContextType = {
	isBroadcastingStarted: boolean;
	setIsBroadcastingStarted: (isBroadcastingStarted: boolean) => void;
	isLiveStreaming: boolean; 
    setIsLiveStreaming: (isLiveStreaming: boolean) => void;
	liveStreamingStartedBy?: number | null;
	setLiveStreamingStartedBy?: (startedBy: number | null) => void;
	recordingDetails: RecordingDetails;
	setRecordingDetails: Dispatch<SetStateAction<RecordingDetails>>; 
};
const BroadcastingStatusContext = createContext<BroadcastingStatusContextType>(
	null!
);

export const useBroadcastingStatusContext = () =>
	useContext(BroadcastingStatusContext);

type Props = {
	children: ReactNode;
};

type RecordingDetails = {
	recordingStarted: boolean;
	recordingStartedBy: number | string;
	recordingType: string;
}

export const BroadcastingStatusProvider = (props: Props) => {
	const [isBroadcastingStarted, setIsBroadcastingStarted] =useState<boolean>(false);
	let storedUserSession = localStorage.getItem("userSession");
	if (storedUserSession === "undefined" || storedUserSession === null) {
		storedUserSession = "{}";
	}
	const userSession = storedUserSession ? JSON.parse(storedUserSession) : null;
	const [isLiveStreaming, setIsLiveStreaming] = useState<boolean>(userSession?.dailyLiveStreamStatus ?? false);
	const [liveStreamingStartedBy, setLiveStreamingStartedBy] = useState<number | null>(null);
	const [recordingDetails, setRecordingDetails] = useState<RecordingDetails>({
		recordingStarted: false,
		recordingStartedBy: "",
		recordingType: ""
	});

	return (
		<BroadcastingStatusContext.Provider
			value={{
				isBroadcastingStarted,
				setIsBroadcastingStarted,
				isLiveStreaming,
				setIsLiveStreaming,
				liveStreamingStartedBy,
				setLiveStreamingStartedBy,
				recordingDetails,
				setRecordingDetails
			}}
		>
			{props.children}
		</BroadcastingStatusContext.Provider>
	);
};

BroadcastingStatusProvider.propTypes = {
	children: PropTypes.node.isRequired,
};
