import { isPlainObject } from "is-plain-object";
import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";
// import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";
import { Constants } from "../services/constants";

export const isMobile = (() => {
  if (typeof navigator === "undefined" || !navigator.userAgent) {
    return false;
  }

  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "iphone",
    "ipod",
    "android",
    "blackberry",
    "windows phone",
    "opera mini",
    "webos",
    "mobile",
    "tablet",
    "kindle",
    "silk"
  ];

  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
})();

// Recursively removes any object keys with a value of undefined
export function removeUndefineds<T>(obj: T): T {
	if (!isPlainObject(obj)) return obj;

	const target: { [name: string]: any } = {};

	for (const key in obj) {
		const val = obj[key];
		if (typeof val !== "undefined") {
			target[key] = removeUndefineds(val);
		}
	}

	return target as T;
}

export async function getDeviceInfo() {
	const devices = await navigator.mediaDevices?.enumerateDevices();

	return {
		audioInputDevices: devices.filter((device) => device.kind === "audioinput"),
		videoInputDevices: devices.filter((device) => device.kind === "videoinput"),
		audioOutputDevices: devices.filter(
			(device) => device.kind === "audiooutput"
		),
		hasAudioInputDevices: devices.some(
			(device) => device.kind === "audioinput"
		),
		hasVideoInputDevices: devices.some(
			(device) => device.kind === "videoinput"
		),
	};
}

// This function will return 'true' when the specified permission has been denied by the user.
// If the API doesn't exist, or the query function returns an error, 'false' will be returned.
export async function isPermissionDenied(name: "camera" | "microphone") {
	const permissionName = name as PermissionName; // workaround for https://github.com/microsoft/TypeScript/issues/33923

	if (navigator.permissions) {
		try {
			const result = await navigator.permissions.query({
				name: permissionName,
			});
			return result.state === "denied";
		} catch {
			return false;
		}
	} else {
		return false;
	}
}

export const checkIsValidDate = (newValue: Date | null) => {
	if (Object.prototype.toString.call(newValue) === "[object Date]") {
		if (newValue !== null && !isNaN(newValue.getTime())) {
			return true;
		}
		return false;
	}
	return false;
};

export const convertDateTz = (date: Date, tzString: any) =>
	new Date(typeof date === "string" ? new Date(date) : date).toLocaleString(
		"en-US",
		{ timeZone: tzString }
	);

export const clearLocalstorage = () => {
	localStorage.removeItem("videoCoordinates");
	localStorage.removeItem("twilioSessionId");
	localStorage.removeItem("userSession");
	// localStorage.removeItem("conversationToken");
	localStorage.removeItem("participantVolumeDetails");
	localStorage.removeItem("isMixerOpen");
	localStorage.removeItem("recordingStatus");
	localStorage.removeItem("recordingInitiator");
	localStorage.removeItem("roomPrevState");
	localStorage.removeItem("roomCurrentState");
	sessionStorage.removeItem("compatibilityHomeDismiss");
	sessionStorage.removeItem("compatibilityLiveDismiss");
};

export const clearAllLocalstorage = () => {
	localStorage.clear();
};

export const signOut = (emailId: string) => {
	const token = localStorage.getItem("sessionId");
	let data = {
		email: emailId,
	};
	fetch(`${process.env.REACT_APP_BASE_URL}/api/sign-out`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(data),
	})
		.then((response) => {
			if (response.status >= 400) {
				throw new Error();
			} else {
				return response.json();
			}
		})
		.then((result) => {
			if (result) {
				localStorage.removeItem("sessionId");
				localStorage.removeItem("user");
				localStorage.removeItem("activeDevice");
				localStorage.removeItem("loginIdentifier");
				localStorage.removeItem("notification");
				localStorage.removeItem("wsConnection");
				localStorage.removeItem("conversationToken");
				sessionStorage.removeItem("compatibilityHomeDismiss");
				sessionStorage.removeItem("compatibilityLiveDismiss");
				WebsocketClientUSerStatus.handleUserSignOut(emailId);
			}
		});
};

export const stopSessionRecording = (twilioSessionId: any, user: any) => {
	const recordingStatus = localStorage.getItem("userSession") ? JSON.parse(localStorage.getItem("userSession") || "{}").recordingStatus : "";
	if (recordingStatus && recordingStatus === "Started") {
		const intitator = localStorage.getItem("recordingInitiator");
		if (intitator && intitator === user.email) {
			let message = {
				action: "audio_recording",
				timestamp: new Date().toISOString(),
				session_id: twilioSessionId,
				created_by: user.id,
				recordingStatus: Constants.RecordingCmd.STOP,
			};
			WebsocketClientUSerStatus.handleRecordingControl(message);
		}
	}
};

export const  stringToColor = (validString: string)  => {
  let hash = 0;
  for (let i = 0; i < validString.length; i++) {
    hash = validString.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = `#${((hash >> 24) & 0xff).toString(16).padStart(2, '0')}${((hash >> 16) & 0xff).toString(16).padStart(2, '0')}${((hash >> 8) & 0xff).toString(16).padStart(2, '0')}`;
  return color.slice(0, 7); // ensure it's a valid color code
}

