import { isEqual } from 'lodash';
import React, { useState, useEffect } from 'react';
import { Rnd } from 'react-rnd';

interface FloatingTileProps {
  children: React.ReactNode;
  isDrawerOpen: boolean
}

const FloatingTile: React.FC<FloatingTileProps> = ({ children, isDrawerOpen }) => {
  const [position, setPosition] = useState<{ x: number; y: number }>({
    x: window.innerWidth - 340,
    y: window.innerHeight - 420,
  });
  const [settled, setSettled] = useState<boolean>(false);
  const [prevPosition, setPrevPosition] = useState<{ x: number; y: number } | null>(null);
  // Define corners
  const getCorners = () => [
    { x: 20, y: 10 }, // Top-left
    { x: window.innerWidth - 340, y: 10 }, // Top-right
    { x: 20, y: window.innerHeight - 450 }, // Bottom-left // add extra 30px to show the below card name
    { x: window.innerWidth - 340, y: window.innerHeight - 420 }, // Bottom-right
  ];

  const corners = getCorners();
  const handleDragStop = (
    e: React.MouseEvent | React.TouchEvent,
    d: { x: number; y: number }
  ) => {
    setPosition({ x: d.x, y: d.y });
    setSettled(false); // Mark as not settled, so it will snap again after drag
  };

  const settleToCorner = () => {
    if (!settled) {
      const { x, y } = position;

      // Find the closest corner
      const closestCorner = corners.reduce((prev, curr) => {
        const prevDistance = Math.sqrt(
          Math.pow(prev.x - x, 2) + Math.pow(prev.y - y, 2)
        );
        const currDistance = Math.sqrt(
          Math.pow(curr.x - x, 2) + Math.pow(curr.y - y, 2)
        );
        return currDistance < prevDistance ? curr : prev;
      });

      // Update position to the closest corner
      setPosition(closestCorner);
      setSettled(true);
    }
  };

  // Recalculate the position and settle the div when the window is resized
  useEffect(() => {
    const handleResize = () => {
      if (isDrawerOpen) {
        // If drawer is open, snap to bottom-left
        setPosition({
          x: 20,
          y: window.innerHeight - 450,
        });
      } else {
        const newPosition = {
          x: Math.min(position.x, window.innerWidth - 340),
          y: Math.min(position.y, window.innerHeight - 420),
        };
        setPosition(newPosition);
        setSettled(false); // Unsettled to trigger re-calculation of the closest corner
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isDrawerOpen]); // position dep can be added with caution

  // Snap to top-left if the drawer is open
  useEffect(() => {
    if (isDrawerOpen) {
      if (prevPosition === null) {
        setPrevPosition(position);
        console.log("position", position)
      }
      const newPosition = {
        x: position.x > 50 ? window.innerWidth - 690 : position.x, // if x position  on left side, keep same else set new x position
        y: position.y || window.innerHeight - 420, // if any y position exist, keep same
      };
      if (!isEqual(position, newPosition)) {
        setPosition(newPosition);
      }
    } else {
      if (prevPosition) {
        console.log("position prevPosition", prevPosition)
        setPosition(prevPosition);
        setPrevPosition(null);
      }
      settleToCorner();
    }
  }, [isDrawerOpen, position, corners]); //  position, corners dep can be added with caution


  return (
    <Rnd
      position={position}
      onDragStop={handleDragStop}
      style={{
        zIndex: 9
      }}
      bounds="parent"
    >
      {children}
    </Rnd>
  );
};

export default FloatingTile;
