import { useEffect, useState } from "react";
import {
	Box,
	Typography,
	Grid,
	CircularProgress,
	Card,
	CardContent,
	Button,
	Tooltip,
} from "@mui/material";

import { addDays, addMinutes, isAfter, isBefore, set, format } from "date-fns";
import EditSessionDialog from "./EditSessionDialog";
import { SessionInterface, UserInterface } from "../../types/types";
import { useUser } from "../../context/UserContext";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../state";
import i18n from "../../l10n/strings.json";
import SessionCmp from "./SessionCmp";
import { LoadingButton } from "@mui/lab";
import ErrorAlertDisplay from "../Error/ErrorAlertDisplay";
import InfiniteScroll from "react-infinite-scroll-component";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
// import User from "../../services/user";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);


const Sessions = (props: any) => {
	const user = useUser();
	const navigate = useNavigate();
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");

	const [editDialogOpen, setEditDialogOpen] = useState(false);
	const [inProgress, setInProgress] = useState(false);
	const [selectedSession, setSelectedSession] =
		useState<SessionInterface | null>(null);
	const [sessions, setSessions] = useState<Array<SessionInterface> | []>([]);
	const [latestSession, setLatestSession] = useState<any>();
// console.log('sessions', {sessions, latestSession})
	const { getToken } = useAppState();
	const canUSerEditSession = user.id === selectedSession?.createdBy;
	const [initialLoading, setInitialLoading] = useState(true);
	const [loading, setLoading] = useState(true);
	const [reloadSessions, setReloadSessions] = useState(false);

	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);
	const [showAlert, setShowAlert] = useState(false);
	const [pageNumber, setPageNumber] = useState(0);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [hasMore, setHasMore] = useState(true);
	const [sDate, setSDate] = useState<any>(null);
	const [eDate, setEDate] = useState<any>(null);

	useEffect(() => {
		const sDate = set(new Date(props.reqStartdate), {
			hours: 0,
			minutes: 0,
			seconds: 0,
		});
		let eDate = null;
		if (props.filter === "agenda") {
			eDate = new Date(
				sDate?.getFullYear(),
				sDate?.getMonth(),
				sDate?.getDate(),
				23,
				59,
				59
			);
		} else if (props.filter === "week") {
			eDate = addDays(
				set(new Date(sDate), { hours: 23, minutes: 59, seconds: 59 }),
				6
			);
		} else if (props.filter === "month") {
			eDate = new Date(
				sDate?.getFullYear(),
				sDate.getMonth() + 1,
				0,
				23,
				59,
				59
			);
		}

		setSDate(sDate);
		setEDate(eDate);
		// setSessions([]);
	}, [
		props.reqStartdate,
		props.filter,
		editDialogOpen,
		props.refresh,
		reloadSessions,
	]);

	useEffect(() => {
		if (sDate && eDate) {
			// Call your session fetch logic only when sDate and eDate are not null
			resetFetchSessions();
		}
	}, [sDate, eDate]);

	const resetFetchSessions = () => {
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-sessions?&page=0&size=${itemPerPage}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userId: user.id,
				groupBy: props.filter,
				startDate: sDate,
				endDate: eDate,
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.length === 0) {
					setHasMore(false);
					setInitialLoading(false);
					setSessions([]);
				} else {
					setSessions(data);
					// setLatestSession(data[0])
					if (props.autoJoinSID) {
						autoJoinSession(String(props.autoJoinSID), data);
					}
					setHasMore(true)
					setPageNumber(1);
					setInitialLoading(false);
				}
				setLoading(false);
			}).catch((err) => {
				setHasMore(false);
			})
			.finally(() => {
				setInitialLoading(false);
			});
	}

	const fetchNextSessions = () => {
		setLoading(true);
		fetch(`${process.env.REACT_APP_BASE_URL}/api/get-sessions?&page=${pageNumber}&size=${itemPerPage}`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				userId: user.id,
				groupBy: props.filter,
				startDate: sDate,
				endDate: eDate,
			}),
		})
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data.length === 0) {
					setHasMore(false);
				} else {
					if (pageNumber === 0) {
						setSessions(data);
					} else {
						setSessions((prevRowData) => [
							...prevRowData,
							...data,
						]);
					}
					// setLatestSession(data[0])
					setLoading(false);
					if (props.autoJoinSID) {
						autoJoinSession(String(props.autoJoinSID), data);
					}

					setPageNumber(pageNumber + 1);
					setLoading(false);
				}
			}).catch((err) => {
				setHasMore(false);
			})
			.finally(() => {
				setLoading(false);
			});;
	}

	const openEditDialog = (session: SessionInterface) => {
		setSelectedSession(session);
		if (selectedSession !== undefined && !checkDisabled(session)) {
			setEditDialogOpen(true);
		}
	};

	const checkDisabled = (session: SessionInterface | null) => {
		if (session != null) {
			const endTime = addMinutes(new Date(session.dateTime), session.duration);
			if (isBefore(endTime, new Date())) {
				return true;
			} else {
				return false;
			}
		}
	};

	const startVideoSession = (session: SessionInterface) => {
		// api to BE to start session
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/session-details/${session.sessionUuid}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					props.setAlertOnSessionAutoJoin(
						true,
						false,
						i18n.settings.accountSetup.defaultErrorMsg
					);
				} else {
					return res.json();
				}
			})
			.then((session: SessionInterface) => {
				const showAlert = (status: boolean, message: string) => {
					setShowAlert(true);
					setAlertStatus(status);
					setAlertMsg(message);
				};
				if (session?.isCancelled) {
					showAlert(false, i18n.jamSession.cancelSession);
				} else if (session?.sessionCreatorsUserAccountStatus === "SUSPEND") {
					if (session?.createdBy === user?.id) {
						showAlert(false, i18n.jamSession.yourAccountIsSuspended);
					} else {
						// Find the suspended user from the session attendees
						const suspendedUser = session?.attendees?.find(attendee => attendee.id === session.createdBy);

						// If the suspended user is found, use their name in the alert
						if (suspendedUser) {
							const message = `${suspendedUser.name}'s account is suspended, so you cannot join the session.`;
							showAlert(false, message);
						} else {
							showAlert(false, i18n.jamSession.cancelSessionOfSuspendedCreator); // Fallback if no match is found
						}
					}

				} else if (session?.sessionCreatorsUserAccountStatus === "INACTIVE") {
					if (session?.createdBy === user?.id) {
						showAlert(false, i18n.jamSession.yourAccountIsInactive);
					} else {
						// Find the inactive user from the session attendees
						const inactiveUser = session?.attendees?.find(attendee => attendee.id === session.createdBy);

						// If the inactive user is found, use their name in the alert
						if (inactiveUser) {
							const message = `${inactiveUser.firstName} ${inactiveUser.lastName}'s account is inactive, so you cannot join the session.`;
							showAlert(false, message);
						} else {
							showAlert(false, i18n.jamSession.cancelSessionOfInactiveCreator); // Fallback if no match is found
						}
					}
				} else {
					localStorage.setItem("userSession", JSON.stringify(session));
					setInProgress(true);
					getToken(user.email, session)
						// .then(({ token }) => {
						// 	videoConnect(token);
						// 	chatConnect(token, session);
						// })
						.then(({ token }) => {
							setInProgress(false);
							let _participantDetails: Array<any> = [];
							session.attendees?.forEach((attendee: any) => {
								let obj = {
									email: attendee.email,
									audio: {
										master: {
											audio_status: "mute",
											audio_level: 0,
										},
										channel_1: {
											audio_status: "mute",
											audio_level: 50,
										},
										channel_2: {
											audio_status: "mute",
											audio_level: 50,
										},
										mic: {
											audio_status: "mute",
											audio_level: 50,
										},
									},
								};
								_participantDetails.push(obj);
							});
							localStorage.setItem(
								"participantVolumeDetails",
								JSON.stringify(_participantDetails)
							);
							localStorage.setItem("sessionAudioEffect", "none");
							navigate("/session", {
								state: {
									session,
									username: `${user.firstName} ${user.lastName}`,
									token
								},
							});
						});
				}
			});
	};

	// callback function to set alert message on edit session
	const handleEditSessionClose = (
		showAlert: boolean,
		alertStatus: boolean,
		alertMsg: string
	) => {
		setEditDialogOpen(false);
		props.setAlertOnEditSession(showAlert, alertStatus, alertMsg);
	};

	const autoJoinSession = (sessionId: String, sessionList: any[]) => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/session-details/${sessionId}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((res) => {
				if (res.status >= 400) {
					props.setAlertOnSessionAutoJoin(
						true,
						false,
						i18n.settings.accountSetup.defaultErrorMsg
					);
				} else {
					return res.json();
				}
			})
			.then((autoJoinSession: SessionInterface) => {
				const showAlert = (status: boolean, message: string) => {
					setShowAlert(true);
					setAlertStatus(status);
					setAlertMsg(message);
				};
				if (autoJoinSession?.isCancelled) {
					showAlert(false, i18n.jamSession.cancelSession);
				} else if (
					autoJoinSession?.sessionCreatorsUserAccountStatus === "SUSPEND"
				) {
					if (autoJoinSession?.createdBy === user?.id) {
						showAlert(false, i18n.jamSession.yourAccountIsSuspended);
					} else {
						// Find the suspended user from the session attendees
						const suspendedUser = autoJoinSession?.attendees?.find(attendee => attendee.id === autoJoinSession.createdBy);

						// If the suspended user is found, use their name in the alert
						if (suspendedUser) {
							const message = `${suspendedUser.firstName} ${suspendedUser.lastName}'s account is suspended, so you cannot join the session.`;
							showAlert(false, message);
						} else {
							showAlert(false, i18n.jamSession.cancelSessionOfSuspendedCreator); // Fallback if no match is found
						}
					}

				} else if (
					autoJoinSession?.sessionCreatorsUserAccountStatus === "INACTIVE"
				) {
					if (autoJoinSession?.createdBy === user?.id) {
						showAlert(false, i18n.jamSession.yourAccountIsInactive);
					} else {
						// Find the inactive user from the session attendees
						const inactiveUser = autoJoinSession?.attendees?.find(attendee => attendee.id === autoJoinSession.createdBy);

						// If the inactive user is found, use their name in the alert
						if (inactiveUser) {
							const message = `${inactiveUser.firstName} ${inactiveUser.lastName}'s account is inactive, so you cannot join the session.`;
							showAlert(false, message);
						} else {
							showAlert(false, i18n.jamSession.cancelSessionOfInactiveCreator); // Fallback if no match is found
						}
					}
				} else {
					const validSession = autoJoinSession?.attendees.find(
						(attendee: any) => attendee.email === user.email
					);
					if (validSession) {
						const endTime = addMinutes(
							new Date(autoJoinSession.dateTime),
							autoJoinSession.duration
						);
						// if (isAfter(endTime, new Date())) {
							startVideoSession(autoJoinSession);
						// } else {
						// 	props.setAlertOnSessionAutoJoin(
						// 		true,
						// 		false,
						// 		i18n.jamSession.autoJoinSessionExpiredMsg
						// 	);
						// }
					} else {
						props.setAlertOnSessionAutoJoin(
							true,
							false,
							i18n.jamSession.autoJoinInvalidSessionMsg
						);
					}
				}
			});
	};

	const getLatestSession = () => {
		const currentDate = new Date();
		const upcomingSession = sessions.find((session) => {
			const sessionDate = new Date(session.dateTime);
			return sessionDate > currentDate;
		});
		setLatestSession(upcomingSession);
	};
	useEffect(() => {
		getLatestSession();
	}, [sessions]);

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	return (
		<>
			<ErrorAlertDisplay
				alertMsg={alertMsg}
				alertStatus={alertStatus}
				showAlert={showAlert}
				handleAlertClose={handleAlertClose}
			/>

			{props.source === "home" ? (
				latestSession ? (
					<Grid container={true} xs={12} sx={{ height: "auto" }}>
						<Card
							sx={{
								background: "customColor.gray1A",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "12px",
								width: "100%",
							}}
						>
							<CardContent>
								<Grid container={true} sx={{ paddingBottom: 0 }}>
									<Grid item={true}>
										<Grid container={true} direction="column">
											<Grid item={true}>
												<Typography
													variant="subtitle1"
													color="customColor.black"
													sx={{
														fontSize: "18px",
														fontWeight: 600,
														fontFamily: "Lexend",
													}}
												>
													{`${format(
														new Date(latestSession.dateTime),
														"eeee, LLLL dd yyyy   hh:mm aaa"
													)} - ${format(
														addMinutes(
															new Date(latestSession.dateTime),
															latestSession.duration
														),
														"hh:mm aaa"
													)}`}
												</Typography>
											</Grid>
											<Grid item={true} sx={{ mt: 1 }}>
												<Typography
													variant="h5"
													color="customColor.black"
													sx={{
														fontSize: "18px",
														fontWeight: 500,
														fontFamily: "Lexend",
													}}
												>
													{latestSession.sessionName}
												</Typography>
											</Grid>
											<Grid item={true} sx={{ mt: 1 }}>
												<Typography
													variant="h6"
													color="customColor.label"
													sx={{
														fontSize: "16px",
														fontWeight: 500,
														fontFamily: "Lexend",
													}}
												>
													{latestSession.sessionDescription}
												</Typography>
											</Grid>
											<Grid item={true} sx={{ mt: 1 }}>
												<Typography
													variant="h6"
													color="customColor.label"
													sx={{
														mt: 1,
														fontSize: "14px",
														fontWeight: 500,
														fontFamily: "Lexend",
													}}
												>
													{latestSession.attendees &&
														latestSession.attendees.length}{" "}
													Participants
												</Typography>
											</Grid>
											<Grid item={true} sx={{ mt: 1 }}>
												<LoadingButton
													className={"button-field-custom-font-size"}
													fullWidth
													size="small"
													variant="outlined"
													disabled={
														checkDisabled(latestSession) ||
														props.activeDevice === null ||
														user.userAccountStatus === "SUSPEND"
													}
													onClick={() => {
														startVideoSession(latestSession);
													}}
													loading={inProgress}
													sx={{
														borderRadius: "17px",
														backgroundColor: "white",
														color: "#0273E6",
														borderColor: "#0273E6",
														fontSize: "16px",
														fontWeight: 500,
														fontFamily: "Lexend",
														"&:hover": {
															background: "#EEF6FF",
															borderColor: "#0273E6",
														},
													}}
												>
													<Typography variant="subtitle1">Join</Typography>
												</LoadingButton>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</CardContent>
						</Card>
					</Grid>
				) : (
					<Grid sx={{ p: "16px" }}>
						<Typography
							component="h1"
							className="typography-field-custom-font-size center-align"
						>
							No Upcoming Session
						</Typography>
					</Grid>
				)
			) : (
				<Grid
					container
					spacing={2}
					columns={{ xs: 12 }}
					id="scrollable-container" 
					style={{
						height: '80vh',
						overflow: "auto", 
						marginTop: '2vh'
					}}
				>
					<Grid item xs={12} >
						{initialLoading ? (
							<div
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									height: "100px",
								}}
							>
								<CircularProgress />
							</div>
						) : (
							<>
								{sessions.length === 0 ? (
									<Grid sx={{ p: "16px" }}>
										<Typography
											component="h1"
											className="typography-field-custom-font-size center-align"
										>
											{i18n.jamSession.noSessionScheduledMsg}
										</Typography>
									</Grid>
								) : (
									<InfiniteScroll
										dataLength={sessions.length}
										next={fetchNextSessions}
										hasMore={hasMore}
										loader={
											loading && (
												<Box className={classes.spinner}>
													<CircularProgress />
												</Box>
											)
										}
										scrollableTarget="scrollable-container" // Use the outer container for scrolling
									>
										<Grid
											container
											columns={{ xs: 12, sm: 12, md: 12, lg: 12 }}
											spacing={2}
										>
											{sessions.map((session: SessionInterface) => (
												<Grid item xs={12} key={session.id}>
													<SessionCmp
														session={session}
														openEditDialog={openEditDialog}
														startVideoSession={startVideoSession}
														inProgress={inProgress}
														displayEdit={props.displayEdit}
														source={props.source}
														reloadSessions={() =>
															setReloadSessions((prev: any) => !prev)
														}
													/>
												</Grid>
											))}
										</Grid>
									</InfiniteScroll>
								)}
							</>
						)}
					</Grid>
					{selectedSession !== null && editDialogOpen && canUSerEditSession && (
						<EditSessionDialog
							session={selectedSession}
							editDialogOpen={editDialogOpen}
							handleClose={handleEditSessionClose}
							formDisabled={false}
						/>
					)}
				</Grid>

			)}
		</>
	);
};
export default Sessions;
