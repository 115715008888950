import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import DailyRoom from "../Daily";
import { DailyAppRoomProvider, useDailyAppRoom } from '../useDailyGlobalRoom';
import { RecoilRoot } from 'recoil';
import { FullscreenModeProvider } from '../../../context/FullscreenModeContext';
import { BroadcastingStatusProvider } from '../../../context/BroadcastingStatusContext';
import WebsocketClientUSerStatus from '../../../services/WebsocketClientUSerStatus';

const DailyLivestream = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const {
    dailyLiveStreamStatus,
    dailyRoomName: sessionId,
    dailyRoomUrl,
    token: dailyToken
  } = location?.state?.session || {};
  // console.log('DailyLivestream View', { dailyRoomName, dailyRoomUrl, dailyToken })

  const {
    setIsStreamingView,
    setStreamingViewType,
    // dailyAppCallObject
  } = useDailyAppRoom();

  useEffect(() => {

    if(!location?.state){
      navigate(`/liveStreamDetails${location.search}`)
    }
    
    const hasLoadedBefore = sessionStorage.getItem('hasLoadedBefore');
    if (!hasLoadedBefore) {
      sessionStorage.setItem('hasLoadedBefore', 'true');
    } else {
      navigate(dailyLiveStreamStatus ? `/liveStreamDetails?id=${sessionId}` : `/liveStreamEnd?id=${sessionId}`);
    }

    return () => sessionStorage.removeItem("hasLoadedBefore");
  }, [location]);

  useEffect(() => {
    WebsocketClientUSerStatus.conntectToStreamTopic();
    setIsStreamingView(true);
    setStreamingViewType('ensemble')
    // console.log({participatns:dailyAppCallObject?.participants() }, "DailyLivestream ============>");
  }, [])

  return (
    <div>
      <DailyRoom dailyUrl={dailyRoomUrl && dailyToken ? `${dailyRoomUrl}?t=${dailyToken}` : ''} />
    </div>
  );
};


const DailyLiveStreamWithRecoil = () => {
  return (
    <RecoilRoot>
      <BroadcastingStatusProvider>
        <DailyAppRoomProvider>
          <FullscreenModeProvider>
            <DailyLivestream />
          </FullscreenModeProvider>
        </DailyAppRoomProvider>
      </BroadcastingStatusProvider>
    </RecoilRoot>
  )
}
export default DailyLiveStreamWithRecoil;
