import { useCallback, useState } from 'react';
import {
    useDaily
  } from '@daily-co/daily-react';

const useLocalRecording = () => {
  const callObject = useDaily();
  const [recordingInstanceId, setRecordingInstanceId] = useState<any>({});

  const startDailyRecording = useCallback(async () => {
    if (callObject) {
      try {
        const instanceId = crypto.randomUUID();
        setRecordingInstanceId({instanceId});

        // Start recording
        await callObject.startRecording({
          instanceId,
          width: 1280,
          height: 720,
          fps: 25,
          backgroundColor: '#FF1F2D3D',
          layout: {
            preset: 'default',
            max_cam_streams: 5,
          },
        });

        console.log('Local recording started');
      } catch (error) {
        console.error('Error starting local recording:', error);
      }
    } else {
      console.log('Call object is not initialized');
    }
  }, [callObject]);

  const stopDailyRecording = useCallback(async (instanceId: any) => {
    if (callObject) {
      try {
        // Stop recording
        await callObject.stopRecording(instanceId);
        console.log('Local recording stopped');
      } catch (error) {
        console.error('Error stopping local recording:', error);
      }
    } else {
      console.log('Call object is not initialized');
    }
  }, [callObject]);

  // Return the two functions
  return { startDailyRecording, stopDailyRecording, recordingInstanceId  };
};

export default useLocalRecording;
