import styled from 'styled-components';
import Logo from '../../../images/LuteFish_Logo_UI_withtagline.png'
interface WrapperProps {
  participantCount: number;
  isDrawerOpen?: boolean;
  isFullscreenMode: boolean;
}

interface CardProps {
  isFloating?: boolean,
  isScreenShare?: boolean
}
interface ShareScreenViewCardWrapperProps{
  participantCount: number 
}

export const Card = styled.div<CardProps>`
  position: relative;
  min-width: calc(50vw - 5rem);
  max-width: calc(100vw - 3rem);
  min-height: calc(50vh - 7rem);
  max-height: calc(100vh - 3rem);
  border-radius: 5px;
  display: flex;
  overflow: hidden;
  flex: 1;
  margin: 8px;
  background: #888;
  // background-image: url(${Logo});
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 260px;
  border: 2px solid #000;

  ${({ isFloating }) => isFloating && `
    min-width: 280px !important;
    min-height: 220px;
  `}

  video{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100% !important; 
    height: 100%;
    object-fit: cover !important;
  }

  ${({isScreenShare}) =>  isScreenShare && `
    video{
     object-fit: contain !important; 
    }
  `}
`;

export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  height: ${({isFullscreenMode}) => isFullscreenMode ? `calc(100vh - 100px)` : `calc(100vh - 170px)`};
  justify-content: center;

  ${Card} {
    transition: all 0.3s ease;
    
    ${({ participantCount }) =>
    participantCount > 2 && `
          max-width: calc(50vw - 3rem);
    `}

    ${({ participantCount }) =>
    participantCount > 3
    && `
          max-height: calc(50vh - 3rem);
    `}

    ${({ participantCount }) => participantCount > 4 && `
      min-width: calc(33vw - 3rem);
      max-width: calc(33vw - 0rem);
      min-height: calc(32vh - 3rem);
      max-height: calc(50vh - 3rem);
    `}

    ${({ participantCount }) => participantCount > 6 && `
      min-height: calc(30vh - 3rem);
      max-height: calc(33vh - 3rem);
    `}

    ${({ participantCount }) => participantCount > 9 && `
      min-width: calc(25vw - 3rem);
      max-width: calc(25vw - 0rem);
      min-height: calc(23vh - 3rem);
      max-height: calc(25vh - 0rem);
    `}

    ${({ participantCount }) => participantCount > 16 && `
      min-height: calc(12vh - 3rem);
      max-height: calc(20vh - 0rem);
    `}
  
    ${({ isDrawerOpen }) => isDrawerOpen && `
      min-width: calc(21vw - 3rem);
    `}
  }
`;

export const ShareScreenViewWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const ShareScreenViewCardWrapper = styled.div<ShareScreenViewCardWrapperProps>`
  display: flex;
  flex-wrap: wrap;
  width: 15rem;
  ${Card} {
    transition: all 0.3s ease;
    min-width: 180px;
    max-width: 100%;
    max-height: 120px;
    min-height: 120px;
    ${({ participantCount }) =>
      participantCount > 2 && `
        max-width: calc(50vw - 3rem);
      `}
  
      ${({ participantCount }) =>
      participantCount > 3
      && `
        max-height: calc(33vh - 3rem);
      `}
  
      ${({ participantCount }) => participantCount > 4 && `
        min-height: calc(30vh - 3.5rem);
        max-height: 200px;
      `}

      ${({ participantCount }) => participantCount > 6 && `
        min-height: calc(24vh - 3.5rem);
        max-height: 150x;
      `}
  
      ${({ participantCount }) => participantCount > 8 && `
        min-height: calc(20vh - 3.5rem);
        max-height: 140x;
      `}
  }
`;
