import React, { useRef, useEffect } from 'react';
import {
    Dialog,
    DialogContent,
    Grid,
    Button,
    Typography,
    DialogActions,
    Box,
} from "@mui/material";
import { useState } from "react";
import Cropper from "react-cropper";
import 'cropperjs/dist/cropper.css';
import { useAlertStatus } from "../context/AlertStatusContext";
import SettingsAlert from './SettingsAlert';
import { useUser } from "../context/UserContext";

const ImageCropper = ({ open, setOpen, onClose, imagePreviewUrl, setImagePreviewUrl, setResult, post, photoUpload, cover, editImageStatus, homeSidebar, comment }: any) => {
    const [saveLoading, setSaveLoading] = useState(false);
    const [isImageTooLarge, setIsImageTooLarge] = useState(false);
    const user = useUser();
    const { updateAlertStatus } = useAlertStatus();
    
    const handleClose = () => {
        setIsImageTooLarge(false);
        if(comment){
        setImagePreviewUrl('');
        setResult('');
        }
        setSaveLoading(false);
        onClose();
    };
    type Units = 'B' | 'KB' | 'MB' | 'GB' | 'TB' | 'PB' | 'EB' | 'ZB' | 'YB';

	const getReadableFileSizeString = (fileSize: number, unit: Units = 'B') => {
	  if (fileSize) {
		let i = -1;
		const byteUnits = [
		  " KB",
		  " MB",
		  " GB",
		  " TB",
		  " PB",
		  " EB",
		  " ZB",
		  " YB"
		];
	
		const unitsMap: Record<Units, number> = {
		  'B': 0,
		  'KB': 1,
		  'MB': 2,
		  'GB': 3,
		  'TB': 4,
		  'PB': 5,
		  'EB': 6,
		  'ZB': 7,
		  'YB': 8
		};
	
		const upperUnit = unit.toUpperCase() as Units;
		if (upperUnit in unitsMap) {

			i = unitsMap[upperUnit] - 1;
			fileSize = fileSize * Math.pow(1024, i );
		 
		  i--; 
		}
	
		do {
		  fileSize = fileSize / 1024;
		  i++;
		} while (fileSize > 1024 && i < byteUnits.length - 1);
	
		return Math.max(fileSize, 0.1).toFixed(1) + byteUnits[i];
	  } else {
		return '0 MB';
	  }
	};


const [storageConsumed, setStorageConsumed] = useState(0);
const [storageAvailable, setStorageAvailable] = useState(0);
const [storageConsumedPercLoad, setStorageConsumedPercLoad] = useState(0);

useEffect(() => {
   if(!post){
    if (user.storageCapacity != null) {

        let converterSize: any = getReadableFileSizeString(user.storageUsage, 'MB');
        setStorageConsumed(converterSize);               
        let totalSize: any = getReadableFileSizeString(user.storageCapacity as any, 'MB')
        setStorageAvailable(totalSize);
        let consumptionPercent = (user.storageUsage / (user.storageCapacity as any)) * 100;
        setStorageConsumedPercLoad(consumptionPercent);
       
    }
   }

}, [!post])
    const cropperRef = useRef<any>(null);
    
    const getCropData = () => {
        setSaveLoading(true);
        if (cropperRef.current && typeof cropperRef.current?.cropper.getCroppedCanvas === 'function') {
            const croppedCanvas = cropperRef.current?.cropper.getCroppedCanvas();
            if (croppedCanvas) {
                croppedCanvas.toBlob((blob: any) => {
                    const croppedImage = new File([blob], 'cropped-image.jpg', { type: 'image/jpeg' });
                    !homeSidebar && setImagePreviewUrl(croppedCanvas.toDataURL())
                    const maxSize = 4 * 1024 * 1024 ;
				var roundedFileSizeInMB = (croppedImage.size / (1024)) ;

                const targetfileAfterupload = user.storageUsage + roundedFileSizeInMB;	              
                    if(!post){
                        if( storageConsumedPercLoad > 99.9 ||  storageAvailable >  targetfileAfterupload ) {                            
                            updateAlertStatus(true, false, 'You have reached the maximum storage limit. Please select other file or buy storage.');
                            setSaveLoading(false); 
                        }  
                        else {
                            setSaveLoading(true);
                            setResult(croppedImage)
                            photoUpload(croppedImage)
                            setTimeout(() => {
                                onClose();
                                setSaveLoading(false);
                            }, 1000);
                        }

                    } else {
                            setIsImageTooLarge(false);
                            setSaveLoading(true);
                            setResult(croppedImage)
                            photoUpload(croppedImage)
                            setTimeout(() => {
                                onClose();
                                setSaveLoading(false);
                            }, 1000);
                    }
                }, 'image/jpeg');
            }
            else {
                setIsImageTooLarge(true);
                updateAlertStatus(true, false, "Max image size should be 4mb");
            }
        }
    };

    return (
        <>
            <Dialog open={open} sx={{
                border: "1px solid #888888",
                boxShadow: "customColor.dropShadow",
                borderRadius: "8px",
                zIndex: 9999
            }}
                fullWidth
                maxWidth="sm">
                <SettingsAlert />
                <DialogContent>
                    <Grid container
                        style={{ display: "flex", flexDirection: "column", alignContent: "center", height: '400px' }}>
                        <Grid item={true} sx={{ position: 'relative', backgroundColor: 'white', height: '78%' }}>

                            <Cropper
                                ref={cropperRef}
                                style={{ height: 400, width: "100%" }}
                                // zoomTo={0.5}
                                initialAspectRatio={undefined}
                                aspectRatio={undefined}
                                preview=".img-preview"
                                src={imagePreviewUrl}
                                viewMode={1}
                                minCropBoxHeight={10}
                                minCropBoxWidth={10}
                                background={false}
                                responsive={true}
                                autoCropArea={1}
                                checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                guides={true}
                                movable={true}               // Allow moving the crop box
                                cropBoxResizable={true} 
                            />

                        </Grid>
                    </Grid>
                </DialogContent>
                {post ?
                    <DialogActions>
                        <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: -1, flexDirection: 'row-reverse' }}>
                            <Typography sx={{ fontSize: "14px" }}> To crop, click and drag on the image. Adjust the zoom level for precise cropping.</Typography>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                onClick={getCropData}
                                disabled={isImageTooLarge} 
                            > Next 
                            </Button>
                            <Button
                                sx={{ borderRadius: '17px' }}
                                size="small"
                                variant="outlined"
                                onClick={handleClose}
                            >Cancel
                            </Button>
                        </Box>
                    </DialogActions>
                    :
                    <DialogActions>
                        <Grid sx={{ display: "flex", flexDirection: "column" }}>
                            <Box sx={{ display: 'flex', gap: 2, flexWrap: 'wrap', mt: -1, flexDirection: 'row-reverse' }}>
                                <Typography sx={{ fontSize: "14px" }}> To crop, click and drag on the image. Adjust the zoom level for precise cropping.</Typography>
                                <Button
                                    sx={{ borderRadius: '17px' }}
                                    size="small"
                                    variant="outlined"
                                    onClick={handleClose}
                                >Cancel
                                </Button>
                                <Button
                                    size="small"
                                    variant="contained"
                                    sx={{ borderRadius: '17px', background: '#FF702A', px: 2 }}
                                    onClick={getCropData}
                                    disabled={editImageStatus || saveLoading}
                                > {saveLoading ? 'Saving' : 'Save'}
                                </Button>
                            </Box>
                        </Grid>
                    </DialogActions>
                }
            </Dialog>
        </>
    );
};

export default ImageCropper;
