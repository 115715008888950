import React from 'react';

export function ProfileIcon(props: any) {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M22 12.5C22 6.99 17.51 2.5 12 2.5C6.49 2.5 2 6.99 2 12.5C2 15.4 3.25 18.01 5.23 19.84C5.23 19.85 5.23 19.85 5.22 19.86C5.32 19.96 5.44 20.04 5.54 20.13C5.6 20.18 5.65 20.23 5.71 20.27C5.89 20.42 6.09 20.56 6.28 20.7C6.35 20.75 6.41 20.79 6.48 20.84C6.67 20.97 6.87 21.09 7.08 21.2C7.15 21.24 7.23 21.29 7.3 21.33C7.5 21.44 7.71 21.54 7.93 21.63C8.01 21.67 8.09 21.71 8.17 21.74C8.39 21.83 8.61 21.91 8.83 21.98C8.91 22.01 8.99 22.04 9.07 22.06C9.31 22.13 9.55 22.19 9.79 22.25C9.86 22.27 9.93 22.29 10.01 22.3C10.29 22.36 10.57 22.4 10.86 22.43C10.9 22.43 10.94 22.44 10.98 22.45C11.32 22.48 11.66 22.5 12 22.5C12.34 22.5 12.68 22.48 13.01 22.45C13.05 22.45 13.09 22.44 13.13 22.43C13.42 22.4 13.7 22.36 13.98 22.3C14.05 22.29 14.12 22.26 14.2 22.25C14.44 22.19 14.69 22.14 14.92 22.06C15 22.03 15.08 22 15.16 21.98C15.38 21.9 15.61 21.83 15.82 21.74C15.9 21.71 15.98 21.67 16.06 21.63C16.27 21.54 16.48 21.44 16.69 21.33C16.77 21.29 16.84 21.24 16.91 21.2C17.11 21.08 17.31 20.97 17.51 20.84C17.58 20.8 17.64 20.75 17.71 20.7C17.91 20.56 18.1 20.42 18.28 20.27C18.34 20.22 18.39 20.17 18.45 20.13C18.56 20.04 18.67 19.95 18.77 19.86C18.77 19.85 18.77 19.85 18.76 19.84C20.75 18.01 22 15.4 22 12.5ZM16.94 17.47C14.23 15.65 9.79 15.65 7.06 17.47C6.62 17.76 6.26 18.1 5.96 18.47C4.44 16.93 3.5 14.82 3.5 12.5C3.5 7.81 7.31 4 12 4C16.69 4 20.5 7.81 20.5 12.5C20.5 14.82 19.56 16.93 18.04 18.47C17.75 18.1 17.38 17.76 16.94 17.47Z"
        fill="#3EB92A"
      />
      <path
        d="M12 7.42993C9.93 7.42993 8.25 9.10993 8.25 11.1799C8.25 13.2099 9.84 14.8599 11.95 14.9199C11.98 14.9199 12.02 14.9199 12.04 14.9199C12.06 14.9199 12.09 14.9199 12.11 14.9199C12.12 14.9199 12.13 14.9199 12.13 14.9199C14.15 14.8499 15.74 13.2099 15.75 11.1799C15.75 9.10993 14.07 7.42993 12 7.42993Z"
        fill="#3EB92A"
      />
    </svg>
  );
}
