interface ErrorInfo {
    title: string;
    message: string;
}

const errorDictionary: { [key: string] : ErrorInfo} = {
    "101": {
        title: "Connection to the peer lost unexpectedly !",
        message: "You have been disconnected from the session. Please check  your internetconnection and try again"
    },
    "102": {
        title: "Connection to the peer lost unexpectedly !",
        message: "...",
    },
    "103": {
        title: "Connection to Server lost !",
        message: "Please check your internet connection. If error persists then <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> for assistance"
    },
    "201": {
        title: "UH-oh, we've experience an audio error !",
        message: "Please try again and <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> if the issue persists"
    },
    "202": {
        title: "Uh-oh, your stream did not start properly !",
        message: "Disconnect power for 5 seconds and try again. If problem persists then <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> for assistance"
    },
    "301": {
        title: "Hmm, your stream didn’t connect correctly !",
        message: "Please try again and <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> if the issue persists"
    },
    "302": {
        title: "Hmm, something didn’t connect correctly !",
        message: "Please try again and <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> if the issue persists"
    },
    "401": {
        title: "Uh-oh, we’ve experienced a video error!",
        message: "Please try again and <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> if the issue persists"
    },
    "408": {
        title: "Uh-oh, we’ve experienced a video error!",
        message: "Please try again and <a href='https://app.lutefish.com/#/helpandsupport'>contact us</a> if the issue persists"
    },
    "501": {
        title : "Session Limit Error !",
        message: ""
    }
}

function getErrorInfo(errorCode: string): ErrorInfo | undefined{
    return errorDictionary[errorCode];
}

export default getErrorInfo;