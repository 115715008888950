import React, { useEffect, useState } from "react";
import liveStreamFish from "../../../images/livestreamfish.png";
import Logo from "../../../images/LuteFish_Type_RevWhite.png";
import { Chip, Grid, Box } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import Profile from "../../../images/profile-circle.svg";
import { TrayContainer, TrayWrapper } from "../Tray/TrayStyled";

interface LiveStreamEndedProps {
  title?: string;
}

const LiveStreamEnded: React.FC<LiveStreamEndedProps> = ({ title }) => {
	const [musicians, setMusicians] = useState<
		{ username: string; profilePhotoUrl: string | null }[]
	>([]);
	const [sessionName, setSessionName] = useState<string>("");
	const [liveStreamingLikeCount, setLiveStreamingLikeCount] =
		useState<number>(0);
	let [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const sessionUuid = searchParams.get("id");
	useEffect(() => {
		getSessionAttendees();
		getSessionDetails();
	}, []);

	const getSessionAttendees = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/jam-session/attendees-details/${sessionUuid}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("API Error");
				} else {
					return res.json();
				}
			})
			.then((data) => {
				setSessionName(data[0]?.sessionName || "Unknown Session");
				setLiveStreamingLikeCount(data[0]?.dailyLiveStreamingLikeCount || 0);
				setMusicians(
					data?.map((attendee: any) => ({
						username: attendee?.publicUserName || "Unknown",
						profilePhotoUrl: attendee?.profilePhotoUrl,
					}))
				);
			})
			.catch((err) => console.error(err));
	}

	const getSessionDetails = () => {
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/public/session-details/${sessionUuid}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
				},
			}
		)
			.then((res) => {
				if (res.status > 400) {
					throw new Error("api_error");
				} else {
					return res.json();
				}
			})
			.then((res: any) => {
				if (res?.dailyLiveStreamStatus) {
					navigate(`/liveStreamDetails?id=${sessionUuid}`)
				}
			});
	}

	return (
		<div
			style={{
				fontFamily: "Arial, sans-serif",
				textAlign: "center",
				color: "#fff",
				backgroundColor: "#000",
				minHeight: "100vh",
				display: "flex",
				flexDirection: "column",
				justifyContent: "space-between",
			}}
		>
			<TrayWrapper>
				<TrayContainer>
					<Grid item xs={3}>
						<Box
							sx={{
								display: { xs: "flex", md: "flex", xl: "flex" },
								height: "46px",
								width: "110px",
							}}
						>
							<img src={Logo} alt="logo" style={{ cursor: "pointer" }} />
						</Box>
					</Grid>
				<div style={{ fontSize: "16px", textAlign: "right", marginRight: '8px' }}>
					{liveStreamingLikeCount}{" "}
					{liveStreamingLikeCount === 1 ? "Like" : "Likes"}
				</div>
				</TrayContainer>
			</TrayWrapper>

			<main
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img
					style={{ width: "17rem", height: "20rem" }}
					src={liveStreamFish}
					alt="Mascot with guitar"
				/>
				{title ? <h1 style={{ fontSize: "2rem", margin: "10px 0", fontWeight: "100" }}>
					{title}
				</h1> :
					<>
						<h1 style={{ fontSize: "2rem", margin: "10px 0", fontWeight: "100" }}>
							Live Streaming has Ended!
						</h1>
						<p style={{ fontSize: "1rem", color: "#ffa500" }}>
							Thank you for watching
						</p>
					</>}
			</main>

			<footer
				style={{
					padding: "20px 0",
					
				}}
			>
				<div style={{ display: title ? 'none' : "auto" }}>
					<h2 style={{ fontSize: "1.5rem", fontWeight: "100" }}>
						Musicians in this livestream
					</h2>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							gap: "15px",
							flexWrap: "wrap",
						}}
					>
						{musicians.map((musician, index) => (
							<Chip
								key={index}
								avatar={
									<img
										src={
											musician?.profilePhotoUrl
												? `${process.env.REACT_APP_PICTURE_URL}${musician?.profilePhotoUrl}`
												: Profile
										}
										alt={`${musician?.username}`}
										style={{ width: "25px", height: "25px", borderRadius: "50%" }}
									/>
								}
								label={`${musician?.username}`}
								style={{
									backgroundColor: "#444",
									color: "#fff",
									fontWeight: "bold",
									margin: "5px",
									width: "9rem",
								}}
							/>
						))}
					</div>
				</div>
				<p style={{ fontSize: "14px", marginTop: "10px" }}>
					To find more livestreams from your favorite artists visit us at
					<a
						href="https://www.lutefish.com"
						target="_blank"
						rel="noopener noreferrer"
						style={{ color: "#ffa500", textDecoration: "none" }}
					>
						{" "}
						www.lutefish.com
					</a>
				</p>
			</footer>
		</div>
	);
};

export default LiveStreamEnded;
