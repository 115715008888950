import { Alert, AlertTitle, Box, Button, Card, CircularProgress, Grid, List, Menu, MenuItem, Snackbar, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { createStyles, makeStyles } from "@mui/styles";
import { Theme } from "@mui/material/styles";
import { useLocation, useNavigate } from "react-router";
import DownArrow from "../../images/arrowdownOrange.svg";
import RightArrow from "../../images/right arrow.svg";
import AllGigs from "./AllGigs";
import GigsFilter from "./GigsFilter";
import NewGig from "./NewGig";
import InfiniteScroll from "react-infinite-scroll-component";
import { useEventsFilter } from "../Events/useEventsFilter";
import { useUser } from "../../context/UserContext";
import HorizontalCard from "../commonComponents/HorizontalCardComp";
import RightArrowBlack from "../../images/arrow-right-black.svg";
import EventCardCom from "../Events/EventCardCom";
import MyActivitiesSidePanel from "../Social/myActivitiesSidePanel";
import ErrorIcon from "../../images/danger.svg";
import SuccessIcon from "../../images/tick-square.svg";
import useAdminRedirect from "../../hooks/useAdminRedirect/useAdminRedirect";
import useAdminAuthorized from "../../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		activeMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px",
			color: "customColor.orange",
		},
		eventMenu: {
			fontStyle: "normal",
			fontWeight: "500",
			lineHeight: "22px",
			color: "#fffff",
			borderColor: "#FF702A !importent",
		},
		downArraow: {
			color: "customColor.orange !importent",
		},
		shadowedBox: {
			borderRadius: "15px",
			boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
		},
		spinner: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			padding: "15px 0",
		},
	})
);
const Gigs = (props: any) => {
	useAdminRedirect();
	const classes = useStyles();
	const token = localStorage.getItem("sessionId");
	const user = useUser();
	// const [activeMenu, setActiveMenu] = useState("allGigs");
	const [activeMenuLable, setActiveMenuLabel] = useState("All Gigs");
	const [openPopup, setOpenPopup] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [postNewEventClicked, setPostNewEventClicked] = useState(false);
	const open = Boolean(anchorEl);
	const [alertMsg, setAlertMsg] = useState("");
	const [showAlert,setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	// const [viewMoreGigs, setViewMoreGigs] = useState(false);
	const [gigCreated, setGigCreated] = useState(false);
	const navigate = useNavigate();

	// const isNavigatedFromGlobalSearch: boolean = location?.state?.globalSearch;
	const [hasMore, setHasMore] = useState(true);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const {
		gigList,
		setGigList,
		handleGigFilterReset,
		gigsFilter,
		resetGigFilter,
		isFilterActive,
		pageNumber,
		setPageNumber,
		isNavigatedFromGlobalSearch,
		setIsNavigatedFromGlobalSearch,
		loading,
		setLoading,
		totalGigs,
		viewMoreGigs,
		setViewMoreGigs,
		gigActiveMenu,
		setGigActiveMenu
	} = useEventsFilter();

	const [windowSize, setWindowSize] = useState({
		width: window.innerWidth,
		height: window.innerHeight,
	});

	let initialItemPerPage = 0;

	// Set the initialItemPerPage based on the condition
	if(windowSize.width === 1024 && windowSize.height === 1366){
		initialItemPerPage = 6;
	} else {
		initialItemPerPage = 8;
	}

	useEffect(() => {
		let user = localStorage.getItem('user');
	 if (user) {
	   try {
		 const userDetails = JSON.parse(user);
		 if (!userDetails.firstName || !userDetails.lastName || !userDetails.location) {
		   navigate("/socialProfileNew", {
			 state: {
			   email: userDetails.email,
			   password: "",
			 },
		   });
		 }
	   } catch (e) {
		 console.error("Failed to parse user data:", e);
	   }
	 }
	}, [navigate]);

	const [itemPerPageGig, setItemPerPageGig] = useState(initialItemPerPage);
	useEffect(() => {
		const userDetails = JSON.parse(localStorage.getItem('user') || "");
		if ((!userDetails.firstName || !userDetails.lastName || !userDetails.location) && !isAdmin) {
			navigate("/socialProfileNew", {
                state: {
                    email: userDetails.email,
                    password: "",
                },
            });
		}
	}, []);

	const [isLoading, setIsLoading] = useState(false);
	const [usersData, setUsersData] = useState<any>({});

	const fetchUserInfo = () => {
		setIsLoading(true);
		console.log(user?.nickname + " user?.nickname");
		console.log(
			encodeURIComponent(user?.nickname) + " encodeURIComponent(user?.nickname)"
		);
		fetch(
			`${
				process.env.REACT_APP_BASE_URL
			}/api/view-user-profile/${encodeURIComponent(user?.nickname)}`,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			}
		)
			.then((response) => {
				if (response.status === 502) {
					navigate("/defaultPage");
				} else if (response.status > 400) {
					throw new Error();
				} else {
					return response.json();
				}
			})
			.then((result) => {
				console.log(result,'result')
				if (
					result.privacySettings.userProfile.multifactorAuthEnabled == true &&
					localStorage.getItem("isVerified") == "false"
				) {
					navigate("/codeVerify");
				}
				setIsLoading(false);
				if (result !== null) {
					setUsersData(result);
				}
			})
			.catch((err) => {
				setIsLoading(false);
			});
	};

	useEffect(() => {
		// api call to fetch user data
		setTimeout(() => {}, 3000);
		if (user?.nickname) {
			fetchUserInfo();
		}

		// getActiveDevice();
		setIsLoading(true);
	}, [user]);

	useEffect(() => {
		if (gigsFilter.title?.length === 0 && gigsFilter.startDateTime === null && gigsFilter.cityCountry?.length === 0 && gigsFilter.distanceFromCity === 50 && gigsFilter.instrumentArray?.length === 0 && !isNavigatedFromGlobalSearch) {
			fetchData();
		} else {
			handleGigFilterReset(isNavigatedFromGlobalSearch ? 'global-search': 'search', isNavigatedFromGlobalSearch ? 'GIG' : null);
			setViewMoreGigs(true);
		}

		return () => {
			setIsNavigatedFromGlobalSearch(false);
		}
	}, [gigsFilter, gigActiveMenu]);

	// useEffect(() => {
	// 	if (!isNavigatedFromGlobalSearch) {
	// 		resetGigFilter();
	// 	}
	// }, []);

	const gigsMenu = [
		{
			id: "allGigs",
			label: "All Gigs",
			navigateMenu: "",
		},
		{
			id: "myPostedGigs",
			label: "Gigs I posted",
			navigateMenu: "",
		},
		// {
		//     id:"myLikedGigs",
		//     label: "My Liked Gigs",
		//     navigateMenu: ""
		// }
	];
	const handleSuccess = () => {
		setOpenPopup(false);
		fetchData();
	};
	const handleOnClose = () => {
		setOpenPopup(false);
		setGigActiveMenu("allGigs");
	};

	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		index: any,
		menuLable: any
	) => {
		setGigActiveMenu(index);
		setActiveMenuLabel(menuLable);
		if (gigActiveMenu === "allGigs") {
			setViewMoreGigs(false);
		}
		setAnchorEl(null);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const fetchData = () => {
		setGigList([]);
		setLoading(true);
		setHasMore(true);
		setPageNumber(1);
		try {
			let myLikedGigsFlag = false;
			let myPostedGigsFlag = false;
			if (gigActiveMenu === "myPostedGigs") {
				myPostedGigsFlag = true;
			}
			if (gigActiveMenu === "myLikedGigs") {
				myLikedGigsFlag = true;
			}
			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/get-events-or-gigs?type=GIG&myLiked=${myLikedGigsFlag}&myPosted=${myPostedGigsFlag}&ops=listing&page=0&size=${itemPerPageGig}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						title: "",
						cityCountry: "",
						duration: 36500,
						distanceFromCity: 0,
						genresArray: [],
						instrumentArray: [],
					}),
				}
			)
				.then((res) => {

					if (res.status !== 200) {
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setGigList([]);
						setLoading(false);
					} else {
						setGigList(data);
						setPageNumber(1);
						setLoading(false);
					}
				})
				.catch((err) => {
					setHasMore(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const fetchDataForScroll = () => {
		setLoading(true);

		try {
			let myLikedGigsFlag = false;
			let myPostedGigsFlag = false;
			if (gigActiveMenu === "myPostedGigs") {
				myPostedGigsFlag = true;
			}
			if (gigActiveMenu === "myLikedGigs") {
				myLikedGigsFlag = true;
			}
			let searchString = isFilterActive ? `get-events-or-gigs?type=GIG&myLiked=&myPosted=&page=${pageNumber}&size=10&ops=${isNavigatedFromGlobalSearch ? 'global-search' : 'search'}` :
				`get-events-or-gigs?type=GIG&myLiked=${myLikedGigsFlag}&myPosted=${myPostedGigsFlag}&ops=listing&page=${pageNumber}&size=${itemPerPageGig}`;

			fetch(
				`${process.env.REACT_APP_BASE_URL}/api/${searchString}`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({
						title: gigsFilter.title,
						cityCountry: gigsFilter.cityCountry,
						startDateTime: gigsFilter.startDateTime,
						distanceFromCity: gigsFilter.cityCountry.length > 0 ? gigsFilter.distanceFromCity : 0,
						instrumentArray: gigsFilter.instrumentArray,
						genresArray: gigsFilter.genresArray ? gigsFilter.genresArray : [],
					}),
				}
			)
				.then((res) => {

					if (res.status !== 200) {
					} else {
						return res.json();
					}
				})
				.then((data) => {
					if (data.length === 0) {
						setHasMore(false);
					} else {
						if (pageNumber === 0) {
							setGigList(data);
							setLoading(false);
						} else {
							setGigList((prevData: any) => [...prevData, ...data]);
						}
					}

					setPageNumber((prevData: any) => (prevData + 1));
					setLoading(false);
				})
				.catch((err) => {
					setHasMore(false);
				});
		} catch (error) {
			setLoading(false);
		}
	};

	const handleViewMore = () => {
		fetchDataForScroll(); // Fetch more data when "View More" button is clicked
	};

	useEffect(() => {
		// if (!isNavigatedFromGlobalSearch) {
		// 	fetchData();
		// }
		for(let i=0; i<gigsMenu.length; i++){
			if(gigActiveMenu === gigsMenu[i].id){
				setActiveMenuLabel(gigsMenu[i].label)
			}
		}
	}, [gigActiveMenu]);


	return (
		<>
		<Grid
				className="center-align homeTab"
				style={{ padding: "10px", position: "relative" }}
				id="home-alert-title-bar"
			>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "80px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={() => setShowAlert(false)}
					>
						<Alert
							onClose={() => setShowAlert(false)}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",

									fontWeight: "500",
									color: "#000000",
									display: "flex",

									margin: "auto",
									padding: "4px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
			</Grid>
		<Grid className="mainPanel" >
			<Grid
				container={true}
				className="column mainPanel"
			>
				<Grid
					item={true}
					sx={{ zIndex: 99, width: "100%", backgroundColor: "white" }}
					className="mainPanel"
				>
					<Grid
						container={true}
						columns={{ xs: 12 }}
						className="mainPanel"
					>
						<Grid item={true} xs={3} mt={3}>
							<Typography
								className="home-title-custom-size center-align"
								sx={{
									fontStyle: "normal",
									fontWeight: "500",
									fontSize: "36px !important",
									lineHeight: "49px",
								}}
							>
								Gigs Listing
							</Typography>
						</Grid>

						<Grid
							container={true}
							xs={9}
							gap={5}
							mt={3}
							style={{
								display: "flex",
								flexDirection: "row",
								alignItems: "center",
							}}
						>
							<Grid container xs={12} display="flex" alignItems="center">
								<Grid item xs={3}>
									{isNavigatedFromGlobalSearch && totalGigs && (
										<Typography variant="body1" sx={{ fontSize: "18px", fontWeight: "500", color: "#7A7985", ml: 2 }}>
											{totalGigs} results found
										</Typography>
									)}
								</Grid>
								<Grid item xs={9} display="flex" justifyContent="end">
									<List
										component="nav"
										aria-label="Device settings"
										sx={{ bgcolor: "background.paper" }}
									>
										<Button
											id="eventsMenuButton"
											variant="outlined"
											sx={{
												// width: "187px",
												height: "40px !important",
												color: "customColor.orange !important",
												borderRadius: "24px !important",
												border: "2px solid #FF702A !important",
												"&:hover": {
													borderColor: "#FF702A !important",
												},
												"& .MuiButton-endIcon": {
													mr: 0,
												},
											}}
											// disabled={user.userAccountStatus === "SUSPEND"}
											className={`${classes.activeMenu} submit-button colorOrange`}
											onClick={handleClickListItem}
											endIcon={<img src={DownArrow} alt="Down Arrow" />}
										>
											<Typography>{activeMenuLable}</Typography>
										</Button>
										<Button
											variant="text"
											sx={{
												// width: "187px",
												height: "40px  !important",
												borderRadius: "24px  !important",
												backgroundColor: user.userAccountStatus === "SUSPEND" ? "rgba(0, 0, 0, 0.12)" : "#FF702A !important",
												color: "#fff  !important",
												"&:hover": {
													backgroundColor: "customColor.orange",
													color: "secondary  !important",
												},
												marginLeft: "10px  !important",
											}}
											disabled={user.userAccountStatus === "SUSPEND"}
											className={`${classes.eventMenu} submit-button`}
											onClick={() => setOpenPopup(true)}
											endIcon={<img src={RightArrow} alt="Down Arrow" />}
										>
											<Typography sx={{ fontSize: "1.5rem" }}>Post New Gig</Typography>
										</Button>
									</List>
								</Grid>
							</Grid>
							<Menu
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									"aria-labelledby": "eventsMenuButton",
									role: "listbox",
								}}
							>
								{gigsMenu.map((menu) => (
									<MenuItem
										key={menu.id}
										// disabled={index === 0}
										selected={menu.id === gigActiveMenu}
										onClick={(event) =>
											handleMenuItemClick(event, menu.id, menu.label)
										}
									>
										{menu.label}
									</MenuItem>
								))}
							</Menu>
						</Grid>
						<Grid item={true} className="rowa mainPanel" sx={{ zIndex: 1 }}>
							<Grid
								container={true}
								columns={{ xs: 12 }}
								style={{ display: "flex" }}
								rowSpacing={{ xs: 1 }}
								paddingTop={3}
							>
								{/* {gigActiveMenu === "allGigs" &&  */}
								<Grid item xs={3} md={3} xl={3} className="leftPanelGig"  sx={{pt:0}}>
									<GigsFilter fetchData={fetchData} />
									<Card sx={{ mt: 3 }}>
										<MyActivitiesSidePanel/>
									</Card>
								</Grid>
								{/* } */}
								<Grid
									item
									xs={9}
									md={9}
									xl={9}
									className="centerPanelwoRightGig"
								>
									<>
										<Grid xs={12} container display="flex"
										//className={classes.shadowedBox}
										    boxShadow = {gigActiveMenu === "allGigs" && !isFilterActive ? "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" : "none"}
											borderRadius = {gigActiveMenu === "allGigs" && !isFilterActive ? "15px" : "none"}
											ml = {gigActiveMenu === "allGigs" && !isFilterActive ? "4px" : "none"}
											mt = {gigActiveMenu === "allGigs" && !isFilterActive ? "8px" : "none"}
											padding="1% 0 1% 2.8%"
											//margin={0}
											>
											<>
												{viewMoreGigs ||
											       isFilterActive ||
												   gigActiveMenu === "myPostedGigs" ? (
													<>
														<InfiniteScroll
																dataLength={gigList && gigList.length}
															next={fetchDataForScroll}
															hasMore={hasMore}
															loader={
																loading && (
																	<Box className={classes.spinner}>
																		<CircularProgress />
																	</Box>
																)
															}
															endMessage={
																<p style={{ textAlign: "center" }}>
																	<b>Yay! You have seen it all</b>
																</p>
															}
															style={{ overflow: "none" }}
														>
															{
																gigActiveMenu === "myPostedGigs" || isFilterActive ? (
																	<>
																		<Grid sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
																			{gigList && gigList.length > 0 ? (
																				gigList.map((event: any) => (

																					<Grid item sx={{marginBottom:"24px"}}>
																						<HorizontalCard
																							eventData={event}
																							type={"gig"}
																						/>
																					</Grid>

																				))
																			) : (
																				!loading &&
																				<Grid container item display={"flex"} justifyContent="center" textAlign={"center"} width={"100%"}>
																					No gigs available
																				</Grid>
																			)}
																		</Grid>
																	</>
																) : (
																	<>
																		<Grid sx={{display: "flex", flexDirection: "row", flexWrap: "wrap"}}>
																			{gigList && gigList.length > 0 ? (
																				gigList.map((event: any) => (
																					<Grid item xs={3} sx={{marginBottom:"12px",display: "flex", flexDirection: "row"}}>
																						<EventCardCom
																							data={event}
																							navigationLink={"/gigs/gigDetails/"}
																							type={"gig"}
																						/>
																					</Grid>
																				))
																			) : (
																				!loading &&
																				<Grid container item display={"flex"} justifyContent="center" textAlign={"center"} width={"100%"}>
																					No gigs available
																				</Grid>
																			)}
																		</Grid>
																	</>
																)}
														</InfiniteScroll>
													</>
												) : (
													<>
														{loading ? (
															<Grid
																container
																xs={12}
																justifyContent="center"
																sx={{ p: "16px" }}
															>
																<CircularProgress />{" "}
																{/* The loading spinner */}
																<Grid container item justifyContent="center">
																	Searching ...
																</Grid>
															</Grid>
														) : (
															<>
																		{gigList && gigList.length > 0
																			? gigList.map((event: any) => (
																		<Grid
																			item
																			md={4}
																			lg={3}
																			sx={{
																				marginBottom: "12px", display: 'flex',
																				justifyContent: "left"
																			}}

																		>
																			<EventCardCom
																				data={event}
																				navigationLink={
																					"/gigs/gigDetails/"
																				}
																				type={"gig"}
																			/>
																		</Grid>
																	))
																	: <Grid item justifyContent={"center"} textAlign={"center"} width={"100%"}>
																		No gigs available
																	</Grid>}
															</>
														)}
													</>
												)}
											</>
											{/* <AllGigs selectedMenu={gigActiveMenu} data={gigList} /> */}
											{!viewMoreGigs && gigActiveMenu === "allGigs" && !loading && gigList.length > 7 && (
												<Typography
													variant="body1"
													sx={{
														fontFamily: "Lexend",
														fontSize: " 16px",
														fontWeight: 500,
														lineHeight: "normal",
														letterSpacing: "0em",
														textAlign: "center",
														cursor: "pointer",
														mt: 2,
														width:"100%",

													}}
													onClick={() => {
														handleViewMore();
														setViewMoreGigs(true);
													}}
												>
													View more
													<img
														style={{ marginBottom: "-8px" }}
														src={RightArrowBlack}
														alt="View more"
													/>
												</Typography>
											)}
										</Grid>
										</>
									{/* } */}
									{openPopup && (
										<NewGig
											open={openPopup}
											onSuccess={handleSuccess}
											onPostEventClose={handleOnClose}
											gigType={"newGig"}
											setAlertMsgMain={setAlertMsg}
											setShowAlertMain={setShowAlert}
											setAlertStatusMain={setAlertStatus}
										/>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
		</>
	);
};

export default Gigs;
