import { Box, IconButton, Slider } from "@mui/material";
import WebsocketClientUSerStatus from "../../../services/WebsocketClientUSerStatus";
import { useUser } from "../../../context/UserContext";
import { useEffect, useState } from "react";
import Speaker from "../../../images/speaker-on.svg";
import latencyHigh from "../../../images/latency-high.svg";
import latencyMed from "../../../images/latency-med.svg";
import latencyLow from "../../../images/latency-low.svg";
import { Stack } from "@mui/system";
import Unmute from "../../../images/microphone-2.svg";
import Mute from "../../../images/microphone-slash.svg";
import { VolumeBox } from "../DailyStyled";
import styled from "@emotion/styled";

interface ParticipantProps {
    participant: {
        email: string;
    };
}


const ParticipantVolumeSlider: React.FC<ParticipantProps> = ({ participant }) => {
    const user = useUser();
    let participantDetailList = JSON.parse(
        localStorage.getItem("participantVolumeDetails") ?? ""
    );

    const twilioSessionId = localStorage.getItem("twilioSessionId");
    const audioDetails = participantDetailList.find(
        (p: any) => p.email === participant.email
    );

    const [volume, setVolume] = useState<number | number[] | null>(0);
    const [prevVolume, setPrevVolume] = useState<number | number[] | null>(50);
    const [latencyIcon, setLatencyIcon] = useState<any>(latencyHigh);
    const [showVolumeBar, setShowVolumeBar] = useState(false);
    const [isAudioMute, setIsAudioMute] = useState(Boolean(audioDetails?.audio?.master?.audio_status === "mute") || false);

    useEffect(() => {
        setVolume(audioDetails?.audio.master.audio_level);
        WebsocketClientUSerStatus.on("audioControl", onMessageReceived);
        WebsocketClientUSerStatus.on("latency", onLatencyChange);
        return () => {
            WebsocketClientUSerStatus.off("audioControl", onMessageReceived);
            WebsocketClientUSerStatus.off("latency", onLatencyChange);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const mixerValue = (value: any, participant: any) => {
        // console.log( value);
        setVolume(value);
        if (value !== 0) {
            setPrevVolume(value);
        }
        let requestMessage = {
            action: "audio_control",
            timestamp: new Date().toISOString(),
            session_id: twilioSessionId,
            created_by: user.id,
            created_for: participant.email,
            audio: {
                updated_for: "master",
                master: {
                    audio_status: value === 0 ? "mute" : "unmute",
                    audio_level: prevVolume ?? value,
                },
                channel_1: {
                    audio_status: value === 0 ? "mute" : "unmute",
                    audio_level: audioDetails.audio.channel_1.audio_level,
                },
                channel_2: {
                    audio_status: value === 0 ? "mute" : "unmute",
                    audio_level: audioDetails.audio.channel_2.audio_level,
                },
                mic: {
                    audio_status: value === 0 ? "mute" : "unmute",
                    audio_level: audioDetails.audio.mic.audio_level,
                },
            },
        };
        WebsocketClientUSerStatus.handleAudioControl(requestMessage);
    };

    const handleSliderVolumeChange = (value: any) => {
        console.log(value);
        setVolume(value);
        if (value !== 0) {
            setPrevVolume(value);
        }
    };

    const onMessageReceived = (payload: any) => {
        if (payload.metadata.created_by === participant.email) {
            setShowVolumeBar(true);
            setTimeout(() => {
                setShowVolumeBar(false);
            }, 3000);
            if (payload.metadata.audio.master.audio_status === "mute") {
                setIsAudioMute(true);
                setVolume(0);
            } else {
                setIsAudioMute(false);
                //console.log( payload.metadata.audio.master.audio_level);
                setVolume(payload.metadata.audio.master.audio_level);
                setPrevVolume(payload.metadata.audio.master.audio_level);
            }
        }
        if (payload.metadata.audio.master.audio_status === "mute") {
            participantDetailList = [
                ...participantDetailList.map((participant: any) => {
                    if (
                        participant.email !== undefined &&
                        participant.email === payload.metadata.created_by
                    ) {
                        return {
                            ...participant,
                            audio: {
                                ...participant.audio,
                                master: {
                                    ...participant.audio.master,
                                    audio_status: "mute",
                                    audio_level: "0",
                                },
                                channel_1: {
                                    ...participant.audio.channel_1,
                                    audio_status: "mute",
                                    audio_level: payload.metadata.audio.channel_1.audio_level,
                                },
                                channel_2: {
                                    ...participant.audio.channel_2,
                                    audio_status: "mute",
                                    audio_level: payload.metadata.audio.channel_2.audio_level,
                                },
                                mic: {
                                    ...participant.audio.mic,
                                    audio_status: "mute",
                                    audio_level: payload.metadata.audio.mic.audio_level,
                                },
                            },
                        };
                    } else {
                        return participant;
                    }
                }),
            ];
        } else {
            participantDetailList = [
                ...participantDetailList.map((participant: any) => {
                    if (
                        participant.email !== undefined &&
                        participant.email === payload.metadata.created_by
                    ) {
                        return {
                            ...participant,
                            audio: {
                                ...participant.audio,
                                master: {
                                    ...participant.audio.master,
                                    audio_status: "unmute",
                                    audio_level: payload.metadata.audio.master.audio_level,
                                },
                                channel_1: {
                                    ...participant.audio.channel_1,
                                    audio_status: "unmute",
                                    audio_level: payload.metadata.audio.channel_1.audio_level,
                                },
                                channel_2: {
                                    ...participant.audio.channel_2,
                                    audio_status: "unmute",
                                    audio_level: payload.metadata.audio.channel_2.audio_level,
                                },
                                mic: {
                                    ...participant.audio.mic,
                                    audio_status: "unmute",
                                    audio_level: payload.metadata.audio.mic.audio_level,
                                },
                            },
                        };
                    } else {
                        return participant;
                    }
                }),
            ];
        }
        localStorage.setItem(
            "participantVolumeDetails",
            JSON.stringify(participantDetailList)
        );
    };

    const onLatencyChange = (payload: any) => {
        if (payload.metadata.created_by === participant.email) {
            if (payload.metadata.latency <= 45) {
                setLatencyIcon(latencyLow);
            } else if (
                payload.metadata.latency > 45 &&
                payload.metadata.latency <= 60
            ) {
                setLatencyIcon(latencyMed);
            } else {
                setLatencyIcon(latencyHigh);
            }
        }
    };

    const participantAudioControl = () => {
        if(isAudioMute){
            return false;
        }
        let audioStatus = "unmute";
        // let audioLevel = 50;
        if (!isAudioMute) {
            audioStatus = "mute";
            // audioLevel = 0;
        }
        let requestMessage = {
            action: "audio_control",
            timestamp: new Date().toISOString(),
            session_id: twilioSessionId,
            created_by: user.id,
            created_for: participant.email,
            audio_status: audioStatus,
            audio_level: prevVolume ?? 50,
            audio: {
                updated_for: "master",
                master: {
                    audio_status: audioStatus,
                    audio_level: audioStatus === "unmute" ? prevVolume ?? 50 : 0,
                },
                channel_1: {
                    audio_status: audioStatus,
                    audio_level: audioDetails.audio.channel_1.audio_level,
                },
                channel_2: {
                    audio_status: audioStatus,
                    audio_level: audioDetails.audio.channel_2.audio_level,
                },
                mic: {
                    audio_status: audioStatus,
                    audio_level: audioDetails.audio.mic.audio_level,
                },
            },
        };
        WebsocketClientUSerStatus.handleAudioControl(requestMessage);
    };

    return (
        <Stack sx={{ display: "flex", flexDirection: "column", alignItems: "flex-start", rowGap: 4 }}>
                <Box sx={{ position: "relative", height: "7.5rem", width: "2.125rem" }} className="visibility-check">
                    <VolumeBox>
                        <Slider
                            sx={{ color: "#1F7A10" }}
                            aria-label="Volume"
                            orientation="vertical"
                            onChangeCommitted={(event, value) =>
                                mixerValue(value, participant)
                            }
                            onChange={(event, value) => handleSliderVolumeChange(value)}
                            min={0}
                            max={100}
                            step={10}
                            value={volume ?? 0}
                        />
                        <img src={Speaker} alt="speaker-on" />
                    </VolumeBox>
                </Box>

            <div style={{ display: "flex", alignItems: "center" }}>
                <img src={latencyIcon} alt="latency-meter" />
                <IconButton onClick={participantAudioControl}>
                    {isAudioMute ? (
                        <img style={{ height: "25px", width: "25px" }} src={Mute} alt="mute" />
                    ) : (
                        <>
                            <img style={{ height: "25px", width: "25px" }} src={Unmute} alt="unmute" />
                        </>
                    )}
                </IconButton>
            </div>
        </Stack>
    );
}

export default ParticipantVolumeSlider;