import './Tile.css';
import { DailyVideo, useParticipantProperty, useVideoTrack } from '@daily-co/daily-react';
import { DailyTileTypes } from '../Types/dailyTypes';
import Username from './Username';
import ParticipantVolumeSlider from './ParticipantVolumeSlider';
import { useDailyAppRoom } from '../useDailyGlobalRoom';
import { getUserAvatar, getMixerSliderMuteIcon } from '../../MixerSlider/MixerSlider';
import { MixerSliderMuteIconWrapper, ParticipantVolumeSliderWrapper, UserAvatarWrapper, VideoWrapper } from '../DailyStyled';
import { useLocation } from 'react-router-dom';


export default function Tile({ id, isScreenShare, isLocal, isAlone, isInsideMixer = false, hideVolumeSlider = false }: DailyTileTypes) {

  const videoState = useVideoTrack(id);
  const { isStreamingView } = useDailyAppRoom();
  const userEmail = useParticipantProperty(id, 'user_name');
  const location = useLocation();
  const userSession = localStorage.getItem("userSession") ?? "";
  
  let participantDetailList = JSON.parse(
    localStorage.getItem("participantVolumeDetails") || "[]"
  );

  const audioDetails = participantDetailList.find(
    (p: any) => p.email === userEmail
  );

  const participantAudio =
    audioDetails?.audio?.master.audio_status === "mute" ? false : true;

	const participantDetails = location?.state?.session?.attendees?.find(
		(name: any) => name?.email === userEmail
	);
  const participant = {
    email: userEmail
  }

  let containerCssClasses = isScreenShare ? 'tile-screenshare' : 'tile-video';

  if (isLocal) {
    containerCssClasses += ' self-view';
    if (isAlone) {
      containerCssClasses += ' alone';
    }
  }

  /* If a participant's video is muted, hide their video and
  add a different background color to their tile. */
  if (videoState.isOff) {
    containerCssClasses += ' no-video';
  }

  return (
    <VideoWrapper className="video-container">
      {videoState.isOff ? (
        isScreenShare ? (
          <DailyVideo automirror sessionId={id} type="screenVideo" />
        ) : (
          <UserAvatarWrapper>
            {getUserAvatar(participantDetails)}
          </UserAvatarWrapper>
        )
      ) : (
        <DailyVideo automirror sessionId={id} type={isScreenShare ? "screenVideo" : "video"} />
      )}

      {/* this will be removed. keeping this for showing grid demo quickly */}
      {/* <video src="http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4" autoPlay width="100%" muted></video> */}
      {isInsideMixer && <MixerSliderMuteIconWrapper>{getMixerSliderMuteIcon(participantAudio, false)}</MixerSliderMuteIconWrapper>}
      <ParticipantVolumeSliderWrapper>
        {(!isInsideMixer && !isStreamingView && !isScreenShare && !hideVolumeSlider) && <ParticipantVolumeSlider participant={participant} />}
        <Username id={id} isLocal={isLocal} isStreamingView={isStreamingView} />
      </ParticipantVolumeSliderWrapper>
    </VideoWrapper>
  );
}
