import React, { createContext, useContext, useState, useEffect, useCallback, useMemo } from "react";
import { useDailyAppRoom } from "./useDailyGlobalRoom";

interface DailyUtilsContextType {
  totalData: any[];
  currentPage: number;
  currentPageData: any[];
  isLastPage: boolean;
  goToPage: (page: number) => void;
  totalPages: number;
  getUserByEmail: Function,
  tilesPerPage: number,
  setTilesPerPage: Function
}

interface DailyUtilsProviderProps {
  children: React.ReactNode;
  totalData: any[]; 
}

export const DailyUtilsContext = createContext<DailyUtilsContextType | null>(null);

export const DailyUtilsProvider: React.FC<DailyUtilsProviderProps> = ({ children, totalData }) => {

  const {streamingViewType, isMixerOpen, isScreenSharing} = useDailyAppRoom();
  const [currentPage, setCurrentPage] = useState(1);
  const [tilesPerPage, setTilesPerPage] = useState(9);
  
  const totalPages = useMemo(() => {
    return Math.ceil(totalData.length / tilesPerPage);
  }, [totalData, tilesPerPage]);

  const currentPageData = useMemo(() => {
    const startIndex = (currentPage - 1) * tilesPerPage;
    const endIndex = startIndex + tilesPerPage;
    return totalData.slice(startIndex, endIndex);
  }, [currentPage, totalData, tilesPerPage]);

  const isLastPage = currentPage >= totalPages;

  const goToPage = useCallback((page: number) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  },[totalPages]);

  // Toggle tilesPerPage in mixerSlider view
  useEffect(()=>{
    setTilesPerPage(isMixerOpen ? 6 : 9)
  },[isMixerOpen])

  // Handle different streamig view layout with auto-rotate and pagination
  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    const isSoloView = streamingViewType === "soloFocus" || streamingViewType === "soloRotate";
    const ensembleView = streamingViewType === "ensemble";
  
    const resetView = () => {
      setCurrentPage(1);
      setTilesPerPage(isSoloView ? 1 : ensembleView ? 20 : isScreenSharing ? 4 : 9);
    };
  
    const rotatePages = () => {
      setCurrentPage((prevPage) => (prevPage % totalPages) + 1);
    };
  
    resetView();
  
    if (isSoloView && streamingViewType === "soloRotate") {
      timer = setInterval(rotatePages, 5000); 
    }
  
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [streamingViewType, isScreenSharing, totalPages]);


  const getUserByEmail = (email:string, nameOnly = false) => {
    let uSession = localStorage.getItem("userSession");
    if (!uSession || uSession === "null") {
      uSession = localStorage.getItem("broadcastingInfo");
    }
    if (uSession) {
      try {
        const userSession = JSON.parse(uSession);
        
        // Get attendees from either userSession or broadcast session
        const attendees = userSession?.attendees || userSession?.broadCastingSession?.attendees;
  
        if (!attendees || !Array.isArray(attendees)) {
          console.warn('Attendees data is invalid');
          return email;
        }
  
        // Find the attendee by email
        const attendee = attendees.find((item: any) => item.email === email);
        
        // If attendee is found, return their name or the full object
        if (attendee) {
          const fullName = `${attendee.firstName || ""} ${attendee.lastName || ""}`.trim() || "User";
          return nameOnly ? fullName : attendee;
        }
  
        // If attendee not found, return the email
        return email;
        
      } catch (error) {
        console.error('Error parsing user session:', error);
        return email;
      }
    }
  }
  return (
    <DailyUtilsContext.Provider
      value={{
        totalData,
        totalPages,
        currentPage,
        currentPageData,
        isLastPage,
        goToPage,
        getUserByEmail,
        tilesPerPage,
        setTilesPerPage
      }}
    >
      {children}
    </DailyUtilsContext.Provider>
  );
};

export function useDailyUtils() {
  const context = useContext(DailyUtilsContext);
  if (!context) {
    throw new Error("useDailyUtils must be used within a DailyUtilsProvider");
  }
  return context;
}