import styled, {css} from 'styled-components';

interface PaginationWrapperProps {
  isSoloFocusView?: boolean,
  isSoloFocusRotateView?: boolean
}

const scrollbarStyles = css`
  /* Scrollbar styles */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
  
  &::-webkit-scrollbar {
    height: 6px;
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(155, 155, 155, 0.5);
    border-radius: 3px;
    border: 2px solid transparent;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: rgba(155, 155, 155, 0.7);
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
`;

export const PaginationWrapper = styled.div<PaginationWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  color: #fff;
  font-size: 14px;
  ${({ isSoloFocusView }) => isSoloFocusView && `
    position: fixed;
    bottom: 0;
    left: 12px;
    right: 0;
    z-index: 9999;
    overflow: visible; // Changed to visible for debug style
    max-width: 200%;
    flex-wrap: nowrap;
    transform: translateY(calc(100% - 5px));
    transition: transform 0.3s ease; 
    background-color: rgba(0, 0, 0, 0.7);
    padding-top: 24px;
    padding-bottom: 12px;
    cursor: pointer;
    animation: showHidePagination 5s ease-in-out forwards; // Slower animation while hiding

    @keyframes showHidePagination {
      0%, 80% { transform: translateY(0); }
      100% { transform: translateY(100%); } // Move out of view
    }

    &:hover {
      animation: showPagination 0.3s ease-in-out forwards; // Faster reverse animation on hover
    }

    @keyframes showPagination {
      0% { transform: translateY(100%); }
      100% { transform: translateY(0); } // Move back into view on hover
    }

    &::before {
      content: '';
      position: absolute;
      top: -30px;
      left: 0;
      right: 0;
      height: 30px;
      // background-color: rgba(255, 0, 0, 0.3); // Debug style
    }

    // Debug styles
    // &:hover::after {
    //   content: 'Hovered';
    //   position: absolute;
    //   top: -50px;
    //   left: 50%;
    //   transform: translateX(-50%);
    //   background-color: yellow;
    //   color: black;
    //   padding: 5px;
    // }
    
    ${scrollbarStyles}

    ${PaginationTitle} {
      position: absolute;
      top: 0;
      font-size: 12px;
    }
    
  `}
`;

export const PaginationTitle = styled.p`
  margin: 3px 10px;
`;

export const PaginationItem = styled.div<{ isActive: boolean, isSoloFocusView?: boolean, isSoloFocusRotateView?: boolean }>`
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 20px;
  border-radius: 50%;
  color: #fff;
  background: ${({ isActive, isSoloFocusRotateView }) => (isActive ? '#FF702A' : isSoloFocusRotateView ? '#424753' : 'transparent')};
  margin: 0 8px;
  cursor: pointer;
  transition: background 0.3s ease;
  ${({ isSoloFocusView, isActive }) => isSoloFocusView && `
    position: relative;
    min-width: 175px;
    height: 82px;
    margin: 0 4px;
    background: transparent;
    border-radius: 4px;
    overflow: hidden;
    border: 2px solid ${isActive ? '#F8F190' : '#fff'};
  `}
`;