import React, { createContext, useContext, useState, ReactNode } from 'react';

interface GlobalSearchContextType {
    inputValue: string;
    setInputValue: React.Dispatch<React.SetStateAction<string>>;
    resetGlobalSearch: () => void;
    displaySearchIcon: boolean;
    setDisplaySearchIcon: React.Dispatch<React.SetStateAction<boolean>>;
}

const GlobalSearchContext = createContext<GlobalSearchContextType | undefined>(undefined);

export const useGlobalSearch = () => {
    const context = useContext(GlobalSearchContext);
    if (!context) {
        throw new Error('useGlobalSearch must be used within a GlobalSearchProvider');
    }
    return context;
};

interface GlobalSearchProviderProps {
    children: ReactNode;
}

export const GlobalSearchProvider: React.FC<GlobalSearchProviderProps> = ({ children }) => {
    const [inputValue, setInputValue] = useState<string>('');
    const [displaySearchIcon, setDisplaySearchIcon] = useState<boolean>(true);


    const resetGlobalSearch = () => {
        setInputValue('');
        setDisplaySearchIcon((isEnabled) => !isEnabled);
    };

    return (
        <GlobalSearchContext.Provider
            value={{
                inputValue,
                setInputValue,
                resetGlobalSearch,
                displaySearchIcon,
                setDisplaySearchIcon,
            }}
        >
            {children}
        </GlobalSearchContext.Provider>
    );
};
