import { mqttClientEnvConfigs } from "./constants/mqtt";

export const env = process.env.REACT_APP_ENV || process.env.NODE_ENV || 'dev';
console.log('ENV', env)

/**
 *
 * @returns  boolean
 * Helper function to check if the app is running in development mode.
*/
export const isDevelopment = (): boolean => env === 'dev';

/**
 * 
 * @returns  boolean
 * Helper function to check if the app is running in test mode.
*/ 
export const isTest = (): boolean => env === 'test';

/**
 	*
 	* @returns  boolean
 	* Helper function to check if the app is running in flow mode.
*/
export const isFlow = (): boolean => env === 'flow';


/**
 * 
 * @returns  boolean
 * Helper function to check if the app is running in production mode.
*/
export const isPoduction = (): boolean =>  env === 'prod';

/**
 * 
 * @returns  boolean
 * Helper function to check if the app is running in futureTest mode.
*/ 
export const isFutureTest = (): boolean => env === 'futureTest';

/**
 	*
 	* @returns  boolean
 	* Helper function to check if the app is running in futureFlow mode.
*/
export const isFutureFlow = (): boolean => env === 'futureFlow';

/**
 *
 *  MQTT Client Configs env 
*/ 
export const activeMqttClientEnv =  (): number => mqttClientEnvConfigs[env] || 1;
