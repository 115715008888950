import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import {
	Link,
	useLocation,
	useNavigate,
	useSearchParams,
} from "react-router-dom";
import {
	Box,
	Button,
	IconButton,
	InputAdornment,
	OutlinedInput,
	TextField,
	Grid,
	Typography,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import User from "../services/user";
import LeftPanel from "./LeftPanel";
import VisibleOff from "../images/eye-slash.svg";
import VisibleOn from "../images/eye.svg";
import ErrorMark from "../images/danger.svg";
import SuccessMark from "../images/tick-circle.svg";
import i18n from "../l10n/strings.json";
import AlreadyLoggedIn from "../dialogBox/AlreadyLoggedIn";
import GoogleSignupButton from "./Auth/SocialLogin/GoogleSignupButton";
import FacebookSignupButton from "./Auth/SocialLogin/FacebookSignupButton";
import RightAarrow from "../images/right arrow.svg";
import socialUser from "../services/socialUser";
import { useSocialUser } from "../context/SocialSignupUser";
import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";
import { Api } from "../utils/ApiCalls/Api";
import { useGradient2Styles } from "../theme";
import { color } from "@mui/system";

// FOR TWILIO CONVERSATIONS
import { getToken as getConversationToken } from "../components/Messages/api";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { actionCreators } from "../store";
import DOMPurify from "dompurify";
import Loader from "./commonComponents/Loader";
import googleIcon from "../icons/google.svg";
import appleIcon from "../icons/apple.svg";
import facebookIcon from "../icons/facebook.svg";
import SuccessIcon from "../images/tick-square.svg";
import ErrorIcon from "../images/danger.svg";
import useAdminAuthorized from "../hooks/useIsAdminAuthorized/useIsAdminAuthorized";

type RouterState = {
	success: boolean;
	action: string;
};

type LoginPayload = {
	username: string;
	password: string;
	reCAPTCHAToken?: string | null;
};

const Login = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const previousLocation = useRef(location);
	let [searchParams] = useSearchParams();
	const socialUser = useSocialUser();
	const gradient = useGradient2Styles();

	const referrer = searchParams.get("referrer");
	const sid = searchParams.get("sid");
	const postId = searchParams.get("postId");
	const userProfileId = searchParams.get("userProfileId");
	const nickName = searchParams.get("nickName");
	const token = localStorage.getItem("sessionId");

	const locationState = location.state as RouterState;
	const showMessage = locationState?.success as boolean;
	const reCaptchaRef = createRef<ReCAPTCHA>();
	const [showAlert, setShowAlert] = useState(false);
	const [alertStatus, setAlertStatus] = useState(false);
	const [alertMsg, setAlertMsg] = useState("You have already set a password.");
	const _loginIdentifier = localStorage.getItem("loginIdentifier");
	// const isEdgeBrowser = window.navigator.userAgent.indexOf("Edg") !== -1;

	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [captcha, setCaptcha] = useState(false);
	const [captchaValue, setCaptchaValue] = useState<string | null>(null);
	const [error, setError] = useState(false);
	const [dirty, setDirty] = useState(false);
	const [accountIsBanned, setAccountIsBanned] = useState(false);
	const [error500, setError500] = useState(false);
	const [emailNotExists, setEmailNotExists] = useState(false);
	const [accountIsTerminated, setAccountIsTerminated] = useState(false);
	const [disableAccount, setDisableAccount] = useState(false);
	const [cardContainerHeight, setCardContainerheight] = useState<
		number | undefined
	>(0);
	const [alreadyLoggedIn, setAlreadyLoggedIn] = useState(false);
	const [loginType, setLoginType] = useState("");
	const [bannedError, setBannedError] = useState("");
	const isPasswordValid = password.trim() !== "";
	const isEmailValid = username.trim() !== "";
	const [terminatedError, setTerminatedError] = useState("");
	const [loading, setLoading] = useState(false);
	const isAdmin: boolean = useAdminAuthorized("isAllAdmins");

	const errorConditions = [
		disableAccount,
		error,
		error500,
		accountIsBanned,
		accountIsTerminated,
		emailNotExists,
	];
	const [isLoading, setIsLoading] = useState(false);

	// FOR TWILIO CONVERSATIONS
	const dispatch = useDispatch();
	const { login } = bindActionCreators(actionCreators, dispatch);

	useEffect(() => {
        // Check if state contains a specific condition to show the alert
        if (location.state && location.state.showAlert === true) {
            setShowAlert(true);
        }
    }, [location.state]);

	useEffect(() => {
		const element = document.getElementById("right-pannel-header");
		const rect = element?.getBoundingClientRect();
		setCardContainerheight(rect?.height);
	}, []);
	const expectedBaseURL = process.env.REACT_APP_BASE_URL_FRONTEND;

	useEffect(() => {
		setLoading(true);
		User.fetchUserInfo((res: any) => {
			if (res.status <= 400) {
				setLoading(false);
				if (referrer === "jamsession" && sid) {
					navigate(`/jamsession?sid=${sid}`);
				} else if (referrer === "singlePostView" && postId && nickName) {
					navigate(
						`/singlePostView?postId=${postId}&nickName=${nickName}`
					);
				} else if (referrer === "userProfile" && nickName) {
					const encodedNickName = encodeURIComponent(nickName);
					navigate(`/userProfile/${encodedNickName}`);
				}  else if (referrer === "helpandsupport") {
					navigate(`/helpandsupport`);
				} else if (referrer === "messages") {
					navigate(`/messages`);
				} else if(!isAdmin) {
					navigate("/home");
				}else if(isAdmin){
					navigate("/admin-dashboard")
				}
			} else {
				setLoading(false);
				if (!referrer) {
					navigate("/login");
				}
			}
		});
		setLoading(false);
	}, []);

	useEffect(() => {
		const currentBaseURL = `${window.location.href.split("#")[0]}#`;
		if (currentBaseURL !== expectedBaseURL) {
			window.location.replace(`${expectedBaseURL}/login`);
			navigate("/login");
		}
	}, []);

	const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (isPasswordValid && isEmailValid && e.key === "Enter") {
			// Trigger login when the user presses "Enter"
			handleSubmit();
		}
	};

	// Function to handle the change in the ReCAPTCHA
	const handleCaptchaChange = (token: string | null) => {
		setCaptchaValue(token);

		// Reset captcha immediately after completion
		reCaptchaRef.current?.reset();
	};

	//    const makeErrorStatus = () =>{
	// 	setError500(true);
	//    }

	//    const error_code : number = 500
	const handleSubmit = () => {
		setIsLoading(true);
		let data: LoginPayload = {
			username: username,
			password: password,
		};

		// Check if captcha is required and not completed
		if (captcha && captchaValue === null) {
			// Optionally, you can show a message to the user indicating the need to complete the captcha
			setIsLoading(false);
			return;
		}

		// If captcha is required and completed, include reCAPTCHAToken in the request
		if (captchaValue) {
			data = { ...data, reCAPTCHAToken: captchaValue };
		}

		// if (captcha && captchaValue) {
		// 	data = { ...data, reCAPTCHAToken: captchaValue };
		// }

		fetch(`${process.env.REACT_APP_BASE_URL}/api/authenticate`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		})
			.then((response) => {
				// if (response.status < 500) {
				// 	return response.json();
				// }
				if (response.status === 502) {
					navigate("/defaultPage");
				} else if (response.status < 500) {
					{
						return response.json();
					}
				}
			})
			.then((result) => {
				setDisableAccount(false);
				setAccountIsBanned(false);
				setAccountIsTerminated(false);
				setEmailNotExists(false);
				setError(false);
				setError500(false);

				switch (result.errorKey) {
					case "accountDeactivated":
						setDisableAccount(true);
						break;
					case "AccountIsBanned":
						setAccountIsBanned(true);
						setBannedError(result.title);
						break;
					case "AccountIsTerminated":
						setAccountIsTerminated(true);
						setTerminatedError(result.title);
						break;
					case "emailNotexists":
						setEmailNotExists(true);
						break;
					default:
						setError(false);
						break;
				}

				reCaptchaRef.current?.reset();
				if (result.loginAttempt === 2) {
					// setCaptchaValue(null);
					setCaptcha(true);
					//reCaptchaRef.current?.execute();
				}
				if (result.id_token != null && result.loginIdentifier) {
					WebsocketClientUSerStatus.createConnectionUSerStatus(username);
					WebsocketClientUSerStatus.conntectToStreamTopic();
					localStorage.setItem(
						"wsConnection",
						JSON.stringify({
							url: `/public/userStatus/${username}`,
							email: username,
							connected: true,
						})
					);
					// if (!result.isAlreadyLoggedIn) {
					if (result) {
						 if (result.multifactorAuthEnabled){
							localStorage.setItem("isVerified", "false");
						 }
						 else {
							localStorage.setItem("isVerified", "true");
						}
						const token = result.id_token;

						localStorage.setItem("loginIdentifier", result.loginIdentifier);
						localStorage.setItem("sessionId", token);
						if (!result.isProfileComplete && !isAdmin) {
							navigate("/socialProfileNew", {
								state: {
									type: "social",
									email: username,
									password: password,
								},
							});
						} else if (
							result.multifactorAuthEnabled &&
							result.isProfileComplete
						) {
							navigate("/codeVerify", {
								state: {
									email: username,
									referrer: referrer,
									sid: sid,
									postId: postId,
									userProfileId: userProfileId,
									sessionId: token,
								},
							});
						} else {
							localStorage.setItem("sessionId", token);
							User.fetchUserInfo(async (res: any) => {
								const authorities = res.authorities.map(
									(authority: { name: string }) => authority.name
								);

								if (isAdmin) {
									navigate("/admin-dashboard");
								} else {
									// GET TOKEN FOR TWILIO CONVERSATIONS
									if (username.length > 0) {
										const lowerCaseUsername = username.toLowerCase();
										await getConversationToken(lowerCaseUsername, password)
											.then((token: string) => {
												login(token);
												localStorage.setItem("conversationToken", token);
											})
											.catch(() => {
												localStorage.setItem("username", "");
												localStorage.setItem("password", "");
											});
									}
									if (referrer === "jamsession" && sid) {
										navigate(`/jamsession?sid=${sid}`);
									} else if (
										referrer === "singlePostView" &&
										postId &&
										nickName
									) {
										navigate(
											`/singlePostView?postId=${postId}&nickName=${nickName}`
										);
									} else if (
										referrer === "userProfile" &&
										nickName
									) {
										navigate(
											`/userProfile/${nickName}`
										);
									} else if (
										referrer === "helpandsupport"
									) {
										navigate(
											`/helpandsupport`
										);
									} else if (
										referrer === "messages"
									) {
										navigate(
											`/messages`
										);
									}
									 else {
										navigate("/home");
									}
								}
							});
						}
					}
					// else {
					// 	if (
					// 		_loginIdentifier &&
					// 		_loginIdentifier === result.loginIdentifier
					// 	) {
					// 		const token = result.id_token;
					// 		localStorage.setItem("sessionId", token);
					// 		localStorage.setItem("loginIdentifier", result.loginIdentifier);

					// 		if (result.multifactorAuthEnabled) {
					// 			navigate("/codeVerify", {
					// 				state: {
					// 					email: username,
					// 					referrer: referrer,
					// 					sid: sid,
					// 					postId: postId,
					// 					userProfileId: userProfileId,
					// 				},
					// 			});
					// 		} else {
					// 			User.fetchUserInfo((res: any) => {
					// 				const authorities = res.authorities.map(
					// 					(authority: { name: string }) => authority.name
					// 				);
					// 				if (authorities.includes("ROLE_ADMIN")) {
					// 					navigate("/admin-dashboard");
					// 				} else {
					// 					if (referrer === "jamsession" && sid) {
					// 						navigate(`/jamsession?sid=${sid}`);
					// 					} else if (
					// 						referrer === "singlePostView" &&
					// 						postId &&
					// 						userProfileId
					// 					) {
					// 						navigate(
					// 							`/singlePostView?postId=${postId}&userProfileId=${userProfileId}`
					// 						);
					// 					} else {
					// 						navigate("/home");
					// 					}
					// 				}
					// 			});
					// 		}
					// 	} else {
					// 		setAlreadyLoggedIn(true);
					// 	}
					// }
				} else if (result.loginAttempt >= 5 && result.accountBlocked === true) {
					navigate("/accountLocked");
				} else {
					setError(true);
					setError500(false);
					setIsLoading(false);
				}
			})
			.catch((error) => {
				if (error.status >= 400 && error.status < 500) {
					setError(true);
					setError500(false);
					setIsLoading(false);
				} else {
					setError500(true);
					setError(false);
					setIsLoading(false);
				}
			})
			.finally(() => {
				setTimeout(() => {
					setIsLoading(false);
				}, 1000);
			});
	};

	const handleClose = () => {
		setUsername("");
		setPassword("");
		// setAlreadyLoggedIn(false);
	};

	const updateAlreadyLoggedIn = (email: string) => {
		// setAlreadyLoggedIn(!alreadyLoggedIn);
		setLoginType("social");
		setUsername(email);
		setPassword("password");
	};

	const updateAccountDisable = (value: boolean) => {
		setDisableAccount(value);
	};

	const updateAccountBanned = (value: boolean) => {
		setAccountIsBanned(value);
	};

	const updateAccountTerminated = (value: boolean) => {
		setAccountIsTerminated(value);
	};

	const [areFieldsFilled, setAreFieldsFilled] = useState(false);
	useEffect(() => {
		// Function to check values and update state
		const checkValues = () => {
			// Check if both username and password have values
			const areFieldsFilled = username.trim() !== "" && password.trim() !== "";

			// based on which you can enable or disable the button
			setAreFieldsFilled(areFieldsFilled);
		};

		// Run the function on page load
		checkValues();
	}, [username, password]); // Empty dependency array ensures it runs only once on mount

	if (loading) {
		return <Loader />;
	}

	return (
		<Grid container style={{ minHeight: "100vh", overflow: "auto" }}>
			{showAlert && (
			<Snackbar
                sx={{
                    "&.MuiSnackbar-anchorOriginTopCenter": {
                        top: "80px",
                    },
                }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
                open={showAlert}
                autoHideDuration={6000}
                onClose={() => setShowAlert(false)}
            >
                <Alert
                    onClose={() => setShowAlert(false)}
					icon={
						alertStatus ? (
							<img src={SuccessIcon} alt="error-icon" />
						) : (
							<img
								src={ErrorIcon}
								alt="error-icon"
								style={{ marginRight: "10px" }}
							/>
						)
					}
                    sx={{
                        backgroundColor: "#FFFFFF",
                        boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
                        borderRadius: "6px",
                        fontSize: "1.125rem",
                        fontWeight: "500",
                        color: "black",
                    }}
                >
                    <AlertTitle
                        sx={{
                            fontSize: "1.125rem",
                            fontWeight: "500",
                            color: "#000000",
                            display: "flex",
                            margin: "auto",
                            padding: "4px",
                            justifyContent: "center !important",
                        }}
                    >
                        {alertMsg}
                    </AlertTitle>
                </Alert>
            </Snackbar>
			)}
			<Grid item xs={false} sm={false} md={6} lg={6}>
				<LeftPanel />
			</Grid>
			<Grid
				item
				xs={12}
				sm={12}
				md={6}
				lg={6}
				sx={{ display: "flex", flexDirection: "column", overflow: "auto" }}
				className="alignCenterTabLogin"
			>
				<Grid sx={{ display: "flex", flexDirection: "column", mb: -5, mt: 2 }}>
					<Box display="flex" justifyContent="flex-end">
						<Typography
							mr={2}
							mt={1}
							sx={{
								fontWeight: "500",
								fontSize: "1rem",
								lineHeight: "20px",
								color: "#5D5D65",
							}}
						>
							{i18n.signUp.notMemberMsg}
						</Typography>
						<Button
							component={Link}
							to="/signUpEmail"
							sx={{
								fontWeight: "900",
								fontSize: "1rem",
								marginRight: "5vw",
								backgroundColor: "#ffffff",
								borderRadius: "18px",
								color: "#0273E6",
								border: "1px solid #0273E6",
								height: "36px",
							}}
						>
							{i18n.signUp.title}
						</Button>
					</Box>
					<Box display="flex" justifyContent="center">
						{showMessage ? (
							<Box
								sx={{
									display: "inline-flex",
									color: "#3EB92A",
								}}
							>
								<Box sx={{ marginRight: "10px" }}>
									<img src={SuccessMark} alt="success-mark" height="20px" />
								</Box>
								<Typography>
									{locationState.action === "closeAccount"
										? i18n.login.accountClosedMsg
										: i18n.login.resetPassSuccessMsg}
								</Typography>
							</Box>
						) : (
							<Box></Box>
						)}
					</Box>
				</Grid>
				<Box display="flex" padding={4} mt={4}>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "center",
								width: "300px",
							}}
						>
							<Typography
								variant="h4"
								sx={{
									fontFamily: "Lexend",
									fontSize: "64px",
									fontWeight: "500",
									color: "#000000",
									lineHeight: "80px",
								}}
								align="center"
							>
								{i18n.login.title}
							</Typography>
							<Grid
								item={true}
								sx={{
									display: "flex",
									justifyContent: "flex-end",
									// maxWidth: "392px",
									// mb: {
									// 	xs: "-2.5rem",
									// 	md: "-2.8rem",
									// },
									// mr: {
									// 	xs: "3.2rem",
									// 	md: "2rem",
									// },
									// paddingBottom:0,
								}}
							>
								<Typography
									sx={{
										border: "1px solid #3EB92A",
										width: "46px",
										height: "17px",
										padding: "0.2rem 0.5rem 0.25rem 0.5rem",
										borderRadius: "0.5rem",
										gap: "0.6rem",
										bgcolor: "#3EB92A",
										fontSize: "1rem",
										lineHeight: "1rem",
										alignItems: "center",
										fontWeight: "700",
										color: "#FFFFFF",
									}}
								>
									BETA
								</Typography>
							</Grid>
						</Grid>
						{/* <Grid
							item
							xs={12}
							sx={{
								display: "flex",
								justifyContent: "center",
								marginLeft: "20px",
							}}
						>
							<Typography
								sx={{
									fontFamily: "Lexend",
									fontSize: "18px",
									fontWeight: 400,
									lineHeight: "22.5px",
									color: "#F57A7A",
									width: "440px",
									height: "46px",
									// marginLeft: "-2%",
								}}
							>
								{i18n.login.inviteData.inviteDisc}{" "}
								<a
									href="mailto:support@lutefish.com"
									style={{ color: "#0A6BFE" }}
								>
									support@lutefish.com
								</a>
							</Typography>
						</Grid> */}

						<Grid item xs={12}>
							<form onSubmit={handleSubmit}>
								<Box display="flex" justifyContent="center">
									{/* {!alreadyLoggedIn && ( */}
									<Box maxWidth="350px">
										{/* <Grid item={true} xs={12}>
											<Typography
												sx={{
													fontFamily: "Lexend",
													fontSize: "18px",
													fontWeight: 400,
													lineHeight: "22.5px",
													color: "#F57A7A",
													width: "440px",
													height: "46px",
													// marginLeft: "-2%",
												}}
												align="left"
											>
												{i18n.login.inviteData.inviteDisc}{" "}
												<a
													href="mailto:support@lutefish.com"
													style={{ color: "#0A6BFE" }}
												>
													support@lutefish.com
												</a>
											</Typography>
										</Grid> */}
										<Grid container spacing={3}>
											<Grid item xs={12} mb={-3}>
												<Box
													sx={{
														display: "inline-flex",
														mb: 1,
													}}
												>
													<Box mb={1} mr={1}>
														{errorConditions.some((condition) => condition) && (
															<img
																src={ErrorMark}
																alt="error-mark"
																height="20px"
																style={{
																	verticalAlign: "middle",
																}}
															/>
														)}
													</Box>

													<Typography
														sx={{
															textAlign: "start",
															color: "#FD4B24",

															fontWeight: "500",
															fontSize: "1.125rem !important",
														}}
													>
														{error500 === true ? (
															"Invalid email, password or both"
														) : disableAccount ? (
															i18n.login.accountMarkedDeletionMsg
														) : accountIsBanned ? (
															<Typography
																sx={{ color: "#FD4B24", fontSize: "18px" }}
																dangerouslySetInnerHTML={{
																	__html: DOMPurify.sanitize(bannedError),
																}}
															/>
														) : accountIsTerminated ? (
															<Typography
																sx={{ color: "#FD4B24", fontSize: "18px" }}
																dangerouslySetInnerHTML={{
																	__html: DOMPurify.sanitize(terminatedError),
																}}
															/>
														) : emailNotExists && error500 === false ? (
															i18n.login.invalidEmailPassMsg
														) : error && error500 === false ? (
															i18n.login.invalidEmailPassMsg
														) : (
															""
														)}
													</Typography>
												</Box>
											</Grid>
											<Grid item={true} xs={12}>
												<Typography
													sx={{
														fontFamily: "Lexend",
														fontWeight: "400",
														fontSize: "1rem",
														color: "#7A7985",
														lineHeight: "20px",
													}}
												>
													{i18n.login.emailFieldName}
												</Typography>
												{/* <TextField
														id="username"
														type="email"
														fullWidth
														sx={{  }}
														inputProps={{
															style: {
																border: "1px solid #D7D7D7",
																color: "#000000",
																fontWeight: "600",
																fontSize: "1rem",
																lineHeight: "16px",
																borderRadius: "6px",
																padding: "8px 14px",
																backgroundColor: `linear-gradient(0deg, #D7D7D7, #D7D7D7)`,
															},
														}}
														value={username}
														onChange={(e) => setUsername(e.target.value)}

														onKeyPress={handleKeyPress}

														//variant="outlined"
														error={error}
													/> */}

												<OutlinedInput
													id="username"
													type="email"
													sx={{
														background: "#F8F8F8",
														width: "100%",
														borderColor: "#EAEAEA",
														borderWidth: "1px",
														color: "#000000",
														fontWeight: "500",
														fontSize: "1rem",
														lineHeight: "20px",
														height: "40px",
													}}
													value={username}
													onChange={(e) => {
														setUsername(e.target.value);
													}}
													onKeyPress={handleKeyPress}
													error={error}
												/>
											</Grid>
											<Grid item={true} xs={12}>
												<Grid
													sx={{
														display: "flex",
														justifyContent: "space-between",
														// marginTop: "10px",
													}}
												>
													<Typography
														sx={{
															fontWeight: "400",
															fontSize: "1rem",
															color: "#7A7985",
															lineHeight: "20px",
														}}
													>
														{i18n.login.passwordFieldName}
													</Typography>
													<Typography right={0}>
														<a
															href="/#/forgotPassword"
															style={{
																fontStyle: "normal",
																color: "#0A6BFE",
																fontWeight: "500",
																fontSize: "14px",
																lineHeight: "14px",
															}}
														>
															{i18n.login.forgetPassword}
														</a>
													</Typography>
												</Grid>
												<Grid item={true} xs={12}>
													<OutlinedInput
														id="outlined-adornment-password"
														type={showPassword ? "text" : "password"}
														sx={{
															background: "#F8F8F8",
															width: "100%",
															borderColor: "#EAEAEA",
															borderWidth: "1px",
															color: "#000000",
															fontWeight: "500",
															fontSize: "1rem",
															lineHeight: "20px",
															paddingRight: "14px !important",
														}}
														onFocus={() => setDirty(true)}
														onChange={(e) => {
															setPassword(e.target.value);
														}}
														onKeyPress={handleKeyPress}
														onCopy={(e) => e.preventDefault()}
														onPaste={(e) => e.preventDefault()}
														onCut={(e) => e.preventDefault()}
														error={
															error ||
															(dirty &&
																password.length < 8 &&
																password.length > 16)
														}
														endAdornment={
															<InputAdornment position="end">
																<IconButton
																	aria-label="toggle password visibility"
																	onClick={() =>
																		setShowPassword((show) => !show)
																	}
																	onMouseDown={(
																		event: React.MouseEvent<HTMLButtonElement>
																	) => event.preventDefault()}
																	edge="end"
																>
																	{showPassword ? (
																		<img src={VisibleOn} alt="eye" />
																	) : (
																		<img src={VisibleOff} alt="Eye Slash" />
																	)}

																	{/* {showPassword ? (
																			<img src={VisibleOff} alt="Eye Slash" />
																		) : (
																			<img src={VisibleOn} alt="eye" />
																			)} */}
																</IconButton>
															</InputAdornment>
														}
													/>
												</Grid>
											</Grid>
											{captcha && (
												<Grid item={true} xs={12}>
													<ReCAPTCHA
														ref={reCaptchaRef}
														sitekey={
															process.env.REACT_APP_CAPTCHA_API_KEY ?? ""
														}
														onChange={(token) => setCaptchaValue(token)}
														//size="invisible"
													/>
												</Grid>
											)}
											{/* <Grid item={true} xs={12} mt={2}>
												{isLoading ? (
													<Button
														fullWidth
														size="large"
														variant="contained"
														sx={{
															background: "#FF702A",
															height: "48px",
															color: "#FFFFFF",
															fontSize: "1.125rem",
															fontWeight: "500",
															lineHeight: "22.5px",
															fontFamily: "Lexend",
															borderRadius: "24px",
														}}
														disabled={true}
														endIcon={
															<img src={RightAarrow} alt="Right Arrow" />
														}
													>
														Signing In
													</Button>
												) : (
													<Button
														sx={{
															background: isLoading
																? "#888888"
																: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",
															height: "48px",
															color: "#FFFFFF",
															fontSize: "1.125rem",
															fontWeight: "500",
															lineHeight: "22.5px",
															fontFamily: "Lexend",
															borderRadius: "24px",
														}}
														size="large"
														fullWidth
														onClick={handleSubmit}
														disabled={
															captcha && captchaValue === null || !isPasswordValid || !isEmailValid
														}
														endIcon={
															<img src={RightAarrow} alt="Right Arrow" />
														}
													>
														{i18n.login.title}
													</Button>
												)}
											</Grid> */}
											<Grid item={true} xs={12} mt={0.5}>
												<Button
													sx={{
														background:
															isLoading ||
															(captcha && captchaValue === null) ||
															!isPasswordValid ||
															!isEmailValid ||
															!areFieldsFilled
																? "#888888"
																: "linear-gradient(126.25deg, #EFB961 3.77%, #FA6460 88.31%)",

														height: "48px",
														color: "#FFFFFF",
														fontSize: "1.125rem",
														fontWeight: "500",
														lineHeight: "22.5px",
														borderRadius: "24px",
													}}
													size="large"
													fullWidth
													variant="contained"
													onClick={handleSubmit}
													disabled={
														(captcha && captchaValue === null) ||
														!isPasswordValid ||
														!isEmailValid ||
														isLoading ||
														!areFieldsFilled
													}
													endIcon={<img src={RightAarrow} alt="Right Arrow" />}
												>
													<Typography
														className="signinWidth"
														sx={{ color: "#FFFFFF", fontSize: "1.125rem" }}
													>
														{isLoading ? "Signing In" : i18n.login.title}
													</Typography>
												</Button>

												{/* <Grid>
										<Typography className="signup-or">OR</Typography>
									</Grid> */}

												{/* <Grid className="signupform" sx={{gap: "20px"}}>
										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={googleIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupGoogle}
										</Button>

										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={facebookIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupFacebook}
										</Button>

										<Button variant="outlined" className="signup-google-apple-btn">
											<img
												src={appleIcon}
												alt="google"
												height="24px"
												style={{ verticalAlign: "middle", marginRight: "10px" }}
											/>
											{i18n.signUp.signupApple}
										</Button>
									</Grid> */}
											</Grid>

											{/* Hiding for invite-only system */}
											{/* <Grid item={true} xs={12}>
												<Box
													display="flex"
													justifyContent="center"
													className="signup-or"
												>

												</Box>
											</Grid> */}
											{/* <Grid item={true} xs={12}>
												<GoogleSignupButton
													updateAccountDisable={updateAccountDisable}
													updateAccountBanned={updateAccountBanned}
													updateAccountTerminated={updateAccountTerminated}
													updateAlreadyLoggedIn={updateAlreadyLoggedIn}
												/>
											</Grid> */}
											{/* <Grid item={true} xs={12}> */}
												{/* <Box>
													<FacebookSignupButton
														updateAccountDisable={updateAccountDisable}
														updateAccountBanned={updateAccountBanned}
														updateAccountTerminated={updateAccountTerminated}
														updateAlreadyLoggedIn={updateAlreadyLoggedIn}
													/>
												</Box> */}
											{/* </Grid> */}
											{/* <Grid item={true} xs={12}>
													<Box> <FacebookSignupButton /></Box>
												</Grid> */}
										</Grid>
									</Box>
									{/* )} */}
									{/* {alreadyLoggedIn && (
										<AlreadyLoggedIn
											handleClose={handleClose}
											email={username}
											pass={password}
											loginType={loginType}
											postId={postId}
											userProfileId={userProfileId}
											referrer={referrer}
										/>
									)} */}
								</Box>
							</form>
						</Grid>
					</Grid>
				</Box>
			</Grid>
			{/* <AlreadyLoggedIn
				open={alreadyLoggedIn}
				handleClose={handleClose}
				email={username}
				pass={password}
			/> */}
		</Grid>
	);
};
export default Login;
